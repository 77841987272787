.vg-editor-dailydeal{
    .blockPlaceholder {opacity: 0;visibility: hidden;}
    img {max-width: 100%;vertical-align: middle;display: inline-block;}
    .pos-relative {position: relative;}
    .daily-column {align-items: flex-start;box-sizing: border-box;color: rgb(33, 37, 41);display: flex;font-size: 14px;font-style: normal;font-weight: 400;justify-content: space-between;line-height: 20px;text-align: left;
    @media (max-width: 767px){
        display: block;
    }
    }
    .right-side-daily {position: static;left: 0;top: 0;    display: flex;width: 335px;height: auto;padding-left: 15px;  flex-direction: column;justify-content: flex-start;}
    .left-column-daily {display: flex;width: 600px;padding-right: 15px;flex-direction: column;justify-content: flex-start;flex: 1;border-right: 1px solid rgba(0, 0, 0, 0.15);font-size: 17px;line-height: 24px;
    @media (max-width: 767px){
        padding-right: 0px;
        border-right: none;
        width: 100%;
    }
    }

    .card-imgabsolute {position: absolute;left: 0;top: 0;height: 100%;width: 100%;}
    .card-bgimg {background-size: cover;background-repeat: no-repeat;background-position: center center;width: 100%;}
    .deal-listing-discount {display: block;margin-top: 0;padding: 5px 10px;border-top-left-radius: 5px;position: absolute;right: 0;bottom: 0;background-color: rgba(51, 51, 51, 1);color: #fff;font-size: 16px;line-height: 20px;text-align: right;}
    .daily-deal-large-header {margin-top: 10px;margin-bottom: 2px;font-weight: 600;font-size: 32px;line-height: 36px; color: #333!important;}
    .daily-deal-bus-and-distance-div {display: flex;padding-top: 5px;padding-bottom: 5px;justify-content: flex-start;align-items: flex-start;font-size: 18px;font-weight: 400;}
    .bus-name-daily {margin-right: 15px;font-weight: 300;}
    .miles-div {display: flex;width: auto;justify-content: space-between;align-items: center;font-weight: 300;}
    .miles-img-font {margin-right: 5px;color: #333;font-size: 12px;  }
    .business-page-link {color: #333;font-weight: 400;text-decoration: none !important;}
    .dealsvgstars {display: flex; align-items: center; height: 17px;}
    .dealsvgstars .vgstars {margin-right: 10px;}
    .dealsvgstars .star {font-size: 16px;font-family: "Font Awesome 5 Pro";font-weight: 900;line-height: 1;color: #e7e7e7;padding: 0 1px;}
    .dealsvgstars .star.yellow {color: #f6d645;}
    .deals-valid{padding: 20px 0;}
    .deals-text-cont {
        p{word-wrap: break-word;word-break: break-all;}
    }
    .mobile-res-dailydeals{
        position: absolute;
        top: 15px;
        right: 15px;
        width: 100%;
        max-width: 325px;
        @media (max-width: 768px){
            position: unset;
            margin-top: 15px;
            max-width: 100%;
        }
    }

    .groupSelectWrp{
        .select2-container--default .select2-selection--single .select2-selection__placeholder{font-family: var(--wbHeadBodyFamily);}
    }

    /* aside */
    .deal-package-card{
        & + .deal-package-card{margin-top: 15px;}
        background: #f5f5f5; border: 1px solid rgba(0, 0, 0, 0.05); border-radius: 4px; padding: 12px;
        h3, .h3{padding: 0; margin: 0; word-wrap: break-word; word-break:break-word; font-size: 16px; line-height: 20px; font-weight: 600; }
        .deal-price{ padding: 5px 0;font-family: var(--wbHeadBodyFamily);
            font-size: 16px; color: rgb(204, 71, 68);
            .striked{text-decoration: line-through; margin-right: 8px; color: rgb(178, 178, 178);}
        }
        .desc{ padding-top: 8px;font-family: var(--wbHeadBodyFamily);
            p{padding: 0; margin: 0; font-size: 16px; line-height: 20px;}
            .desc-note{font-size: 14px;color: rgb(178, 178, 178); padding-top: 8px;}
        }
        .deal-card-action{padding-top: 8px; text-align: right;
            .w-button{display: inline-block;font-family: var(--wbHeadBodyFamily);}
            .v-button{padding: 10px 16px; line-height: 20px; font-size: 16px;font-family: var(--wbHeadBodyFamily);}
        }
    }
    .daily-deals-promotiondesc {
        padding-bottom: 16px;
        word-break: break-word;
    }
    .daily-deals-map-div{display: flex; flex-direction: column; margin-top: 15px;
        .map-marker-div{padding-top: 45%; display: block; background-position: center center; background-size: cover; background-repeat: no-repeat;}
        .location-div{display: flex; padding-top: 8px;
            i{padding-top: 6px; font-size: 13px;}
            .map-text{flex: 1; padding-left: 8px;
                .mapbname{font-weight: 600;font-family: var(--wbHeadFontFamily);}
            }
        }
    }
    .bknwbtn{padding-top: 15px;
        .v-button{display: block; width: 100%; padding-top: 9px; padding-bottom: 9px;}
    }

    .dealshare{padding-top: 20px;
        .w-button{display: inline-block; color: #333;
            @media (max-width: 767px){
                padding: 11px;
            }
        .share-btn-dd{
            @media (max-width: 767px){
                display: none;
            }
        }
        }
        .dropdown-menu{
            svg{width: 15px; margin-right: 8px;vertical-align: middle;}
        } 
        share-btn-dd{
            @media (max-width: 767px)
            {display: none;}
        }
        @media (max-width: 767px){
            position: absolute;
            top: 3px;
            right: 28px;
        }       
    }

    .groupSelectWrp{position: relative;}
    .form-row + .form-row{margin-top: 10px;}

    .deals-valid, .daily-deals.title{font-family: var(--wbHeadFontFamily);}

    .dealshare{
        .dropdown-menu{max-width: 126px; min-width: 126px;}
    }

}

.popgiftcards{
    .gift-card{padding: 4px;cursor: pointer;
        .inner-gift-card {
            transition: box-shadow .2s,transform .2s;
            border-radius: 0px;
            overflow: hidden;
            padding-bottom: 60%;
        }
        &:hover{
            .inner-gift-card {
                transform: translateY(-2px);
                box-shadow: 0 5px 10px -2px rgba(0,0,0,.5);
            }
        }
    }
}

// date picker 

//--vg-primary-bg
//--vg-primary-text-color
.xdsoft_datetimepicker{
    $p: var(--vg-primary-bg);
    .text-primary, .xdsoft_label i{
        color: #333!important ;
    }
    .xdsoft_calendar{
        th{color: #333!important; font-weight: 600!important;}
        td{
            &.xdsoft_current{
                div{color:var(--vg-primary-text-color);background: $p;}
            }
            &.xdsoft_today{
                div{color:#333;border-color: $p;}
            }
        }
    }
    .xdsoft_label > .xdsoft_select > div > .xdsoft_option{
        &.xdsoft_current{background-color:var(--vg-primary-bg);}
        &:hover{
            //background-color:darken($p, 10%);

            background: color-mix(in srgb,var(--vg-primary-bg),#fff 80%)!important;
        }
    }
    @media (max-width:480px) {
        &.xdsoft_noselect {
            min-width: 260px !important;
        }
    }
}
.vg-editor-dailydeal{

    .select2-container--default .select2-selection--single .select2-selection__placeholder {
        color: #aba8a8;
    }
}



@media (max-width:767px) {
.deal-card-action .v-button {
    width: 100% !important;
}

}
@media (min-width:576px) {
    #VgReviewByEmployee{
        --bs-modal-width:90%;
    }
}
@media (min-width:992px) {
    #VgReviewByEmployee{
        --bs-modal-width:1000px;
    }
}
@media (min-width:1200px) {
    #VgReviewByEmployee{
        //--bs-modal-width:1140px;
        --bs-modal-width:1000px;
    }
}
.location-input-wrap:after {
    display: inline-block;
    font-family: "Font Awesome 5 Pro" !important;
    position: absolute;
    top: 10px;
    left: 8px;
    font-size: 15px;
    color: #333;
    @media (max-width: 767px){
        top: 16px;
    }
}