.clearfix:before, .clearfix:after, .row:before, .row:after, .form-horizontal .form-group:before, .form-horizontal .form-group:after, .panel-body:before, .panel-body:after, .modal-footer:before, .modal-footer:after { display: table; content: " "; }
.clearfix:after, .row:after, .form-horizontal .form-group:after, .panel-body:after, .modal-footer:after { clear: both; }
/* Billing */
.general-addresspopup { position: fixed; background-color: rgba(0,0,0,0.5); left: 0; top: 0; right: 0; bottom: 0; display: none; z-index: 100004; }
.general-addresspopup-container { display: table; width: 100%; height: 100%; }
.general-addresspopup-body { width: 100%; height: 100%; display: table-cell; vertical-align: middle; }
.address-common-contorl * { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
.select2borderbox * { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
.settingnew-design .w-textaria,
.settingnew-design .inputbox-type1,
.settingnew-design .TelerikDatePicker .riTextBox[type="text"] { border-color: rgba(0, 0, 0, 0.2) !important; }
.settingnew-design .w-textaria { display: block; width: 100%; padding: 8px 12px; font-size: 14px; line-height: 1.42857143; color: #333333; vertical-align: middle; }
.settingnew-design .inputbox-type1 { height: 40px !important; line-height: inherit; border-radius: 2px !important; float: none; font-size: 14px; font-weight: 400; padding-left: 7px; padding-right: 7px; }
.settingnew-design .TelerikDatePicker .riTextBox[type="text"] { height: 40px !important; line-height: inherit; border-radius: 2px !important; border-color: rgba(0, 0, 0, 0.2); float: none; font-size: 14px; }
.general-addresspopup .modal-body { padding: 0 !important; }
.addressselect full-address-width { width: 60% !important; }
.addressselect .inputbox-type1 { width: 100%; border: 0 none; padding: 0; height: 40px !important; border: solid #959494 1px !important; }
.settingnew-design .addressselect .select2-container-multi .select2-choices .select2-search-field input { border: 0px !important; }
.settingnew-design .inputbox-type1:focus,
.settingnew-design .TelerikDatePicker .riTextBox:focus[type="text"] { border-color: #2a90d6 !important; }
.settingnew-design .inputbox-type1.errorInputCal { border-color: #eb6663 !important; }
.select-country-mar, .select-country-mar.input-focus-relative {
    margin-left: 66px !important;
    padding-left:0px!important;
}
.blockheight38 { height: 38px; }
.billing-old .addresswithcountry .countrydropdown { border-color: #cccccc !important; border-radius: 2px 0 0 2px !important; }
.billing-old .addresswithcountry .countryselectoption { top: 37px; border-color: #cccccc !important; border-radius: 2px 0 0 2px !important; }
.addresspopup-billing * { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
.addresspopup-billing { width: 100%; max-width: 600px; margin: 0 auto; box-shadow: rgba(0, 0, 0, 0.14902) 0px 0px 0px 1px, rgba(0, 0, 0, 0.419608) 0px 0px 17px 0px; }
.addresspopup-billing .popupCloseImg { font-family: 'Fontawesome pro 300' !important; color: #9a9a9a; font-size: 20px; font-weight: 300; text-decoration: none; height: 24px; width: 41px; text-align: center; right: 5px; top:17px; }
.addresspopup-billing .popupCloseImg:hover { text-decoration: none; color: #333; }
.addresspopup-billing .loginWrap h4 { background: #fff; border-bottom: 0 none !important; font-weight: 500;  color: #333; font-size: 20px; display: block; clear: both;  -webkit-border-radius: 5px 5px 0px 0px; -moz-border-radius: 5px 5px 0px 0px; border-radius: 5px 5px 0px 0px; padding-left:20px; padding-top:20px; padding-bottom:4px; line-height:24px; }
.popupBtnContainer { min-height: 62px; clear: both; float: none; -webkit-border-radius: 0 0 5px 5px; -moz-border-radius: 0 0 5px 5px; border-radius: 0 0 5px 5px; width: 100%; }
.addresspopup-billing .popupBtnContainer { background: #f8f8f8; border-top: none; min-height: 60px; }
.addresspopup-billing .loginContainer { padding: 15px 20px; padding-top:0px; font-size: 15px; color: #333; line-height: 1.42857; }
.addresspopup-billing .loginContentwrap-addbus .inputbox-type1 { width: 100% !important; padding: 0 12px; }
.loginContentwrap-addbus ul li { padding-top:0px; position: relative; }
.addresspopup-billing .loginContentwrap-addbus ul li label { padding-bottom: 5px; font-weight: 500; color: #333; }
.addresspopup-billing .loginContentwrap-addbus ul li label:after { content: ":"; }
.loginContentwrap-addbus .popupBtnDiv ul li { padding: 0; }
.addresspopup-billing #divsmallloading { position: absolute; right: 8px; top: 51px; left: inherit; }
.address-common-contorl .custom-field-label { font-size: 13px; display: inline-block; padding-bottom: 2px; }
.addressselect.div-70 { width: 68.4%; }
.addresspopup-billing .dropdowndiv { height:40px; overflow:visible; width:100%;  border:0px;}
    .addresspopup-billing .dropdowndiv select {
        background-color: #fff;
        color: #333333; margin: 0
    }
.addresspopup-billing .dropdowndiv select option {
    background-color: #fff; font-size:100%;
}

.location-city-row li {
    width: 33.33%;
    float: left;
}

.location-city-row li:first-of-type {width:33.34%; padding-right:15px;}
.location-city-row li:nth-child(2) {padding-right:15px;}
.addresspopup-billing .dropdowndiv select {
    height: 40px;
    border: 1px solid #cdcdcd;
    border-radius: 3px;
    width: 100%;
    font-size: 100%; 
}
.addresspopup-billing .dropdowndiv select:focus { border-color:#2a90d6 !important}
.addresspopup-billing .dropdowndiv:after { line-height:40px;}
.addresspopup-billing .countryselectoption li { padding-bottom:0px;}

@media (max-width:767px) {

.location-city-row li { width:100%; }
.location-city-row li:first-of-type {width:100%; padding-right:0px;}
.location-city-row li:nth-child(2) {padding-right:0px; padding-bottom:16px;}
}

.address-common-contorl .inputbox-type1 { border-color: #cdcdcd !important; }
.address-common-contorl .inputbox-type1.select2-dropdown-open { border-color: #2c90d6 !important; }
.address-common-contorl { position: relative; margin-left: 10px; margin-right: 10px; }
.address-common-contorl .appsuit { float: left; margin-left: 15px; width: 125px; }
.address-common-contorl .appsuit .inputbox-type1 { width: 100%; }
.address-common-contorl .appsuit .inputbox-type1:focus { border-color: #959494 !important; }
.select2DD .select2-no-results { font-size: 14px !important; }
.select2DD .select2-searching { font-size: 14px !important; }
.loginContentwrap-addbus .popupBtnDiv { margin-right: 20px; margin-top: 10px; }
 .settingnew-design .inputbox-type1.errorInput, .masseditpopup-control .inputbox-type1.errorInput { border: solid #eb6663 1px !important; /*background: #fff url(https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/required-img-new1@2x.png) no-repeat right center !important;*/ background-size: 36px auto !important; }
/* Billing end */

/* Select2 Input Start */

.select2-results { max-height: 265px !important; }
.select2DD .select2-results { margin: 0; }
.select2DD .select2-results li { padding: 10px 0 !important; border-bottom: 1px solid #ccc !important; }
.select2DD .select2-results li img { margin-left: 5px !important; }
.select2DD .select2-results li .fullocation { display: inline-block !important; font-size: 14px !important; font-weight: 500 !important; vertical-align: middle !important; padding-left: 13px !important; line-height: 19px !important; width: 90%; }
.select2DD .select2-results li .fullocation span { color: #8d8d8d !important; font-size: 14px; font-weight: normal; }
.select2DD .select2-searching { margin-left: 11px !important; }
.select2DD .select2-no-results { margin-left: 11px !important; }
.select2DD .select2-results { height: auto !important; max-height: 243px !important; }
.select2DD .select2-highlighted,
.select2DD.select2-drop { background: white !important; color: #545554 !important; }
.select2DD .select2-result-label > div { line-height: 26px !important; }
.select2DD .select2-results .select2-highlighted { background: none !important; color: #d66f6b !important; }
.select2DD .select2-results .select2-result-label { padding: 2px 7px 0 !important; }
.select2DD { border: 1px solid #cccccc !important; border-radius: 2px; margin-top: -1px !important; }
.addressselect .select2-focused { background: white !important; }
select:-moz-focusring { color: transparent; text-shadow: 0 0 0 #333333; }
/* Select2 Input End */

.has-error .addresswithcountry,
.addresswithcountry { position: relative; z-index: 3; }
.has-error .addresswithcountry .countrydropdown,
.addresswithcountry .countrydropdown { /*background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/down-arrow-blacknw1.png') !important;*/ background-position: 59px center !important; background-size: 25px auto !important; background-repeat: no-repeat; border: 1px solid #ccc !important; border-radius: 2px 0 0 2px; float: left; position: absolute; left: 0; top: 0; width: 68px; margin: 0; cursor: pointer; line-height: 40px; }
.has-error .addresswithcountry .countrydropdown:after, .addresswithcountry .countrydropdown:after { content: '\f0d7'; font-family: 'Fontawesome pro solid 900',sans-serif; font-size: 16px; color: #333; right: 0px; top: 0px; width: 18px; position: absolute; pointer-events: none; bottom: 0px; line-height: inherit; text-align: left; }

.has-error .selectedcountry, .selectedcountry {
    background-repeat: no-repeat;
    background-position: 8px center;
    background-size: 32px 24px;
    height: 100%;
    width: 100%;
}

.has-error .allflag-show .selectedcountry,
.allflag-show .selectedcountry {
    background-repeat: no-repeat;
    background-position: 8px center;
    background-size: 32px 24px;
    height: 100%;
    width: 100%;
}



    .has-error .selectedcountry.aus-f, .selectedcountry.aus-f {
        background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/aus-flag-img.png');
    }

    .has-error .selectedcountry.canada-f, .selectedcountry.canada-f {
        background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/canada-flag-img.png');
    }

    .has-error .selectedcountry.uk-f, .selectedcountry.uk-f {
        background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/uk-flag-img.png');
    }

    .has-error .selectedcountry.us-f, .selectedcountry.us-f {
        background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/us-flag-img.png');
    }

.has-error .allflag-show .selectedcountry,
.allflag-show .selectedcountry { background-repeat: no-repeat; background-position: 8px center; background-size: 32px 24px; height: 100%; width: 100%; }
.has-error .allflag-show .selectedcountry.aus-f,
.allflag-show .selectedcountry.aus-f { background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/aus-flag-img.png'); }
.has-error .allflag-show .selectedcountry.canada-f,
.allflag-show .selectedcountry.canada-f { background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/canada-flag-img.png'); }
.has-error .allflag-show .selectedcountry.uk-f,
.allflag-show .selectedcountry.uk-f { background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/uk-flag-img.png'); }
.has-error .allflag-show .selectedcountry.us-f,
.allflag-show .selectedcountry.us-f { background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/us-flag-img.png'); }
.has-error .allflag-show .selectedcountry.ie-f,
.allflag-show .selectedcountry.ie-f { background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/worldflag/Country_IE_Ireland.svg'); }
.has-error .addresswithcountry .countryselectoption,
.addresswithcountry .countryselectoption { z-index:3; background-color: white; position: absolute; left: 0; top: 39px; list-style: none; margin: 0; padding: 8px 0; border: 1px solid #cccccc;  width: 225px;
            border-radius: 5px;
            border-top-left-radius: 0;
    border-top-right-radius: 0; }
.has-error .addresswithcountry .countryselectoption li,
.addresswithcountry .countryselectoption li { padding-top:0px; background-repeat: no-repeat; background-size: 32px 24px; background-position: 8px center; font-size: 15px; line-height: 40px; padding-left: 52px; cursor: pointer; color:#333; }
.has-error .addresswithcountry .countryselectoption li:hover,
.addresswithcountry .countryselectoption li:hover {background-color: #F8F8F8;}
.has-error .addresswithcountry .countryselectoption li.active,
.addresswithcountry .countryselectoption li.active { background-color: #E7E7E7; }
.has-error .addresswithcountry .countryselectoption li.aus-f,
.addresswithcountry .countryselectoption li.aus-f { background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/aus-flag-img.png'); }
.has-error .addresswithcountry .countryselectoption li.canada-f,
.addresswithcountry .countryselectoption li.canada-f { background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/canada-flag-img.png'); }
.has-error .addresswithcountry .countryselectoption li.uk-f,
.addresswithcountry .countryselectoption li.uk-f { background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/uk-flag-img.png'); }
.has-error .addresswithcountry .countryselectoption li.us-f,
.addresswithcountry .countryselectoption li.us-f { background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/us-flag-img.png'); }
.has-error .addresswithcountry .countryselectoption li.ie-f,
.addresswithcountry .countryselectoption li.ie-f { background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/worldflag/Country_IE_Ireland.svg'); }
.has-error .addresswithcountry .select2-container.select2-container-multi.custom-w-input,
.addresswithcountry .select2-container.select2-container-multi.custom-w-input { margin-left: 78px; float: left; width: 88.8%; width: calc(100% - 80px); }

.addressselect .select2-container-multi .select2-choices .select2-search-field input { text-align: left; font-size: inherit; height: inherit; line-height: 1.42857; padding: 0 12px; color: #333333; width: 100% !important; outline: 0; border: 1px solid #cccccc; -webkit-box-shadow: none; box-shadow: none; background: transparent !important; border-radius: 2px; -webkit-appearance: none; }
.select2-container-multi .select2-choices .select2-search-field input:focus { border-color: #3898ec; }
.addressselect .select2-container-multi .select2-choices .select2-search-field input:-ms-input-placeholder { color: #999999; }
.addressselect .select2-container-multi .select2-choices,
.addressselect .select2-container-multi .select2-choices .select2-search-field { height: 100% !important; }
.select2-container.select2-container-multi.custom-w-input { border: 0 none; padding: 0; }
.addressselect .select2-container-multi .select2-choices,
.addressselect .select2-container-multi .select2-choices .select2-search-field { height: 100% !important; }
.addressselect.has-error .select2-container-multi .select2-choices .select2-search-field input { border-color: #eb6663 !important; /*background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/required-img-new1@2x.png') !important;*/ background-position: right center !important; background-repeat: no-repeat !important; background-size: 35px auto !important; }
.w-custom-dropdown { background-color: white; background-image: none; background-repeat: no-repeat; position: relative; float: none; display: block;   width: 100%; height: 40px; padding: 0; margin-bottom: 10px; font-family: inherit; font-size: 14px; font-weight: 400; line-height: 1.42857143; color: #333333; vertical-align: middle; outline: 0 none; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

.w-custom-dropdown.margin-bottom0 { margin-bottom: 0; }
.w-custom-dropdown.small { width: 80px; margin-bottom: 20px; }
.w-custom-dropdown:after { clear: both; }
.address-phone-plugin .w-custom-dropdown:after,
.address-phone-plugin .w-custom-dropdown:before { content: " "; display: table; }
.w-custom-dropdown.small-dropdown { max-width: 200px; }
    .w-custom-dropdown select { /*background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/down-arrow-blacknw1.png');*/ 
        background-size: 25px auto;
        background-repeat: no-repeat;
        background-position: right center;
        cursor: pointer;
        border: 1px solid #cccccc;
        border-radius: 2px !important;
        outline: 0 none;
        width: 100%;
        font-size: 14px;
        font-family: inherit;
        font-weight: 400;
        margin: 0;
        padding: 0 30px 0 12px;
        color: #333333;
        line-height: normal;
        height: 40px;
        -moz-appearance: none;
        -webkit-appearance: none;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

.w-custom-dropdown select::-ms-expand { display: none; }
.w-custom-dropdown select:focus { border-color: #3898ec; }
.border-darkshade, .custom-w-input.border-darkshade { border-color: rgba(0, 0, 0, 0.2) !important; padding-left: 8px !important; }
.border-darkshade:focus, .custom-w-input.border-darkshade:focus { border-color: #3898ec !important; }




@media only screen and (max-width: 1023px) {
    .address-common-contorl .appsuit { margin-left: 7px; }
}

@media (max-height:550px) {
    .general-addresspopup-container, .general-addresspopup-body { display: block; }
    .general-addresspopup-container .addresspopup-billing { padding: 10px 0; -webkit-box-sizing: border-box; box-sizing: border-box; }
    .general-addresspopup-container .addresspopup-billing, .general-addresspopup-container .loginWrap { height: 100%; }
    .general-addresspopup-container .modal-body { height: -webkit-calc(100% - 107px); height: -moz-calc(100% - 107px); height: -ms-calc(100% - 107px); height: calc(100% - 107px); overflow: auto; -webkit-overflow-scrolling: touch; }
}

.addressDropdownBox .select-country-mar {
    padding-left: 0;
    z-index: 5;
    position: relative;
}
.shipping-address-div .address-field {
    height: 48px;
}
.shipping-address-div .add-input-focus-relative {
    margin-left: 0px !important;
}
.shipping-address-div .select-country-mar {
    margin-left: 0px !important;
}
.shipping-address-div .address-field.select2-container .select2-choices .select2-search-field input { border-radius:3px!important; font-size:14px!important; height:48px!important;
}

.addressDropdownBox .select-country-mar .select-country-mar {
    margin: 0;
    padding: 0
}
    .addresswithcountry.bus-Address .select2-container.select2-container-multi.custom-w-input {
        margin: 0
    }

.addressDropdownBox .allflag-show .selectedcountry:before, .vagaro-dropdown.addressDropdownBox .allflag-show .selectedcountry:before {
    display: none !important
}

.addressDropdownBox .addresswithcountry .countrydropdown {
    width: 68px !important;
}

.vagaro-dropdown.addressDropdownBox .address-country .select2-container.select2-container-multi.input-text {
    margin: 0 !important;
    padding: 0;
    width: 100%;
}

.addressDropdownBox .address-country .select-country-mar .input-text.w-input.selectfontsize input {
    border-radius: 0px 3px 3px 0px;
    border: 1px solid #cccccc !important;
    margin: 0 !important;
    width: 100%;
    padding: 0 10px !important;
    font-size: 100%;
}

.addressDropdownBox .select-country-mar .w-input input:focus {
    border-color: #3898ec;
}
.verifyAddressPopup .loginContainer ul#countryselectoptionpopup li { margin-bottom:0
}
.verifyAddressPopup .loginContentwrap-addbus {border-radius: 5px;background: #fff;width: 100%;}

.verifyAddressPopup .addresspopup-billing header {
    position: relative
}

    .verifyAddressPopup .addresspopup-billing header h4 {
        margin-top: 0
    }

.verifyAddressPopup .addresspopup-billing .popupCloseImg {
    position: absolute
}

.verifyAddressPopup .loginContainer .location-city-row {
    margin: 0 -10px;
    width: auto;
    display: block;
}

    .verifyAddressPopup .loginContainer ul li{ margin-bottom:18px; padding-bottom:0;}

    .verifyAddressPopup .loginContainer .location-city-row li {
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 18px;
    }

.verifyAddressPopup .settingnew-design input {
    border: 1px solid #cdcdcd;
    border-radius: 3px;
}

.verifyAddressPopup .settingnew-design select {
    padding: 0 8px; 
} 
.verifyAddressPopup .addresspopup-billing ul {
    display: block;
    margin: 0;
    padding: 0;
    list-style-type: none
}

.verifyAddressPopup .popupBtnContainer.modal-footer {
    text-align: right;
    padding: 10px 20px;
    background: #f8f8f8 !important;
}


    .verifyAddressPopup .popupBtnContainer.modal-footer .btn {
        margin-left: 15px;
        font-size: 16px;
        font-weight: 400;
        border: 1px solid transparent;
        border-radius: 3px;
        color: white;
        min-width: 100px;
        padding: 9px 10px;
        text-align: center;
        -webkit-appearance: none;
        outline: 0 none;
        line-height: 20px;
        cursor: pointer;
        min-height: 40px;
        background: #fff;
    }

    .verifyAddressPopup .popupBtnContainer.modal-footer .btn-default {
        border-color: #cdcdcd;
        color: #777;
    }

        .verifyAddressPopup .popupBtnContainer.modal-footer .btn-default:hover {
            border-color: #333 !important;
            color: #333 !important;
        }

    .verifyAddressPopup .popupBtnContainer.modal-footer .btn-primary:hover {
        background-color: #5c9557!important;
    }

    .verifyAddressPopup .popupBtnContainer.modal-footer .btn-primary {
        border: none;
        -webkit-transition: background-color 0.2s ease;
        transition: background-color 0.2s ease;
        background-color: #7cc576 !important;
    }

@media (max-width:767px) {
    
    .verifyAddressPopup {
        overflow: auto;
        height: 100%;
    }

.verifyAddressPopup .loginContentwrap-addbus {
    border-radius: 0
}
        .verifyAddressPopup .loginContainer ul li{ margin-bottom:10px;}
        .verifyAddressPopup .loginContainer .location-city-row {
            margin: 0
        }

        .verifyAddressPopup .loginContainer .location-city-row li {
            padding: 0;
            margin-bottom: 10px;
        }
}

.w-tab-content.overflowVisible, .zen-tab-content.overflowVisible, .zen-tab-content overflowVisible {
    overflow: visible
}
.zen-input-googleheight .zen-input { height:40px; }

.addressDropdownBox .addresswithcountry .select2-container.select2-container-multi.custom-w-input{ margin:0; width:100%}
    .addressDropdownBox .addresswithcountry .select2-container.select2-container-multi.custom-w-input input {
        height:40px
    }

.addressselect.addressDropdownBox .select2-container-multi .select2-choices .select2-search-field input {
    padding: 0 12px !important
}

.form-flexy-div .addresswithcountry.pro-pur-control .select2-container-multi .select2-choices .select2-search-field input {
    border: 1px solid #cdcdcd;
}



.mobileresponsive-popup .settingnew-design .loginContentwrap-addbus .popupBtnDiv {
    margin-top: 0;
    margin-right: 0
}

.extraSpaceremove > .select-country-mar {
    margin: 0;
    padding: 0
}

    .extraSpaceremove > .select-country-mar > .addresswithcountry {
        margin: 0;
        padding: 0;
    }

.vagaro-dropdown select option, .vagaro-dropdown.dropdowndiv select option {
    font-size: var(--web-button-font-size);
    background-color: #fff;
}

/*Add By hariom */




/*default-scrollbar start
=========================================================*/
body:not(.ipadandroid) .all-country-flag .select2-results::-webkit-scrollbar, body:not(.ipadandroid) .select2-drop.all-country-flag .select2-results::-webkit-scrollbar {
width: 7px;background-color: #d9d9d9;border-radius: 10px;height: 7px;}
body:not(.ipadandroid) .all-country-flag .select2-results::-webkit-scrollbar-thumb, body:not(.ipadandroid) .select2-drop.all-country-flag .select2-results::-webkit-scrollbar-thumb {background-color: #7d7e7e;
border-radius: 10px;}

.select2-with-searchbox.all-country-flag.select2-drop-active, .all-country-flag.select2-drop-active {background: #fff; min-width: auto !important;width: 350px !important;border: 0px;border-radius: 3px;border-top-left-radius: 0;border-top-right-radius: 0;
box-shadow: 0px 8px 40px 0px #00000033;border: 0px !important;}
.all-country-flag.select2-drop-above {border-bottom-left-radius: 0px !important;border-bottom-right-radius: 0px !important;border-top-left-radius: 3px !important;
border-top-right-radius: 3px !important;}
.all-country-flag ul {padding: 0px;margin: 0px;}
.all-country-flag ul li , .all-country-flag-react .countrys-Code {padding: 0px;color: #333;cursor: pointer;font-size: 15px !important; background-repeat: no-repeat;background-size: 20px auto!important;background-position: 8px 10px; line-height:20px;}
.all-country-flag ul li .select2-results .select2-result-label { padding:0px; margin:0px;}
.all-country-flag ul.select2-result-sub > li .select2-result-label {margin: 0px; padding: 9px 11px 8px 36px !important;}
.all-country-flag ul li:hover {background-repeat: no-repeat;background-size: 20px auto;background-position: 8px 10px;}
.all-country-flag .select2-result-sub li.select2-highlighted {background-repeat: no-repeat;background-position: 8px 10px;
background-color: #e7e7e7;color: #333;}
.all-country-flag .select2-result-sub li:hover {background-color: #f8f8f8;color: #333;}
.all-country-flag li.select2-result-with-children > .select2-result-label { font-size:15px!important; font-weight:600!important;}
.all-country-flag .select2-result-label {padding: 4px 8px 4px!important;}
.all-country-flag .select2-results li:first-child.select2-result-with-children {padding:0!important;border:0px!important;}
.all-country-flag .select2-results li.select2-result-with-children:last-of-type:not(:first-of-type) { border:0px!important; padding-top:0px!important; padding-bottom:0px!important;}
.allflag-show .select2-container {left: -1px !important;}
.allflag-show .selectedcountry {width: 66px;height: 40px;position: absolute;left: 0px;top: 0;z-index: 0;}
.allflag-show .select2-container .form-control { z-index:2;}
.allflag-show .select2-choice {width: 66px;height: 39px !important; display:inline-block;}
.allflag-show .selectedcountry {background-repeat: no-repeat;background-size: 35px;background-position: 6px 6px;line-height:38px!important;}
.all-country-flag .select2-results {max-height: 285px !important;}
.all-country-flag .select2-result-sub .select2-result-label {display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;}
.allflag-show .select2-chosen {width: 66px;height: 39px;text-indent: 1000px !important;white-space: nowrap !important;text-overflow: unset !important;}
.all-country-flag.select2-drop.select2-drop-above { margin-top:-2px!important;}
.input-focus-relative, .add-input-focus-relative {position: relative;}
.input-focus-relative.select-country-mar, add-input-focus-relative.select-country-mar {padding-left: 0px !important;}
.input-focus-relative input {border-bottom-left-radius: 0px !important;border-top-left-radius: 0px !important;margin-left: 0px !important;
font-size: 16px !important;font-weight: 400 !important;height: 40px !important;padding-top: 0px !important;padding-bottom: 0px !important;padding-right:10px!important;}
.add-input-focus-relative input {border-bottom-left-radius: 0px !important;border-top-left-radius: 0px !important;margin-left: 0px !important;
font-size: 16px !important;font-weight: 400 !important;height: 40px !important;padding-top: 0px !important;padding-bottom: 0px !important;padding-right: 10px !important;padding-left: 12px;}
.add-input-focus-relative input.select2-offscreen {width:1px!important; height:1px!important;}
.input-focus-relative input, .input-focus-relative .v-commoninput, .input-focus-relative .contact-input, .input-focus-relative input.input-text {border-bottom-left-radius: 0px !important;
border-top-left-radius: 0px !important; margin-left: 0px !important;}
.input-focus-relative input:focus, .add-input-focus-relative input:focus {border-color: #2a90d6 !important;}
.input-focus-relative input[type=text], .input-focus-relative input[type=text].vagaro-input, .input-focus-relative input[type=text].vagaro-input.cntry-us {
padding-left: 50px !important;}
.all-country-flag .select2-search {display: block !important;padding: 8px;border-bottom: solid 1px;position: relative;border-bottom: solid #E7E7E7 1px;}
.all-country-flag .select2-search::after {position: absolute;content: '\f002';left: 19px;top: 19px;font-family: 'Fontawesome pro 400';color: #9A9A9A;
 line-height: 17px;font-size: 14px; height:17px; font-weight: 400;}
.all-country-flag.select2-drop.select2-drop-above .select2-search input { margin-top:0px;}
.all-country-flag .select2-search input {width: 100%;height: 40px !important;border: solid #CDCDCD 1px !important;
border-radius: 3px !important;padding: 8px 8px 8px 29px !important;-webkit-box-sizing: border-box !important;box-sizing: border-box !important;
font-family: proxima-nova,sans-serif;-webkit-appearance: none;font-size: 15px;color: #333;}
.all-country-flag .select2-search input:focus {border-color: #2a90d6!important;}

/*for country code 1 digit*/
.input-focus-relative.us-f input[type=text], .input-focus-relative.us-f input[type=text].vagaro-input, .input-focus-relative.canada-f input[type=text], .input-focus-relative.canada-f input[type=text].vagaro-input,
.input-focus-relative.bv-f input[type=text], .input-focus-relative.bv-f input[type=text].vagaro-input, .input-focus-relative.hm-f input[type=text], .input-focus-relative.hm-f input[type=text].vagaro-input,
.input-focus-relative.ru-f input[type=text], .input-focus-relative.ru-f input[type=text].vagaro-input,.input-focus-relative.um-f input[type=text],
.input-focus-relative.um-f input[type=text].vagaro-input,
.input-focus-relative.umu-f input[type=text], .input-focus-relative.umu-f input[type=text].vagaro-input {
    padding-left: 30px !important;
}
    

/*for country code 2 digit*/
.input-focus-relative.uk-f input[type=text], .input-focus-relative.aus-f input[type=text], .input-focus-relative.ar-f input[type=text],
.input-focus-relative.be-f input[type=text], .input-focus-relative.be-f input[type=text], .input-focus-relative.at-f input[type=text],
.input-focus-relative.in-f input[type=text], .input-focus-relative.br-f input[type=text], .input-focus-relative.cl-f input[type=text],
.input-focus-relative.co-f input[type=text], .input-focus-relative.dk-f input[type=text], .input-focus-relative.eg-f input[type=text],
.input-focus-relative.fr-f input[type=text], .input-focus-relative.de-f input[type=text], .input-focus-relative.gr-f input[type=text],
.input-focus-relative.hu-f input[type=text], .input-focus-relative.id-f input[type=text], .input-focus-relative.id-f input[type=text],
.input-focus-relative.it-f input[type=text], .input-focus-relative.jp-f input[type=text], .input-focus-relative.mm-f input[type=text],
.input-focus-relative.my-f input[type=text], .input-focus-relative.mx-f input[type=text], 
.input-focus-relative.no-f input[type=text], .input-focus-relative.nz-f input[type=text], .input-focus-relative.pe-f input[type=text],
.input-focus-relative.ph-f input[type=text], .input-focus-relative.pk-f input[type=text], .input-focus-relative.pl-f input[type=text],
.input-focus-relative.pn-f input[type=text], .input-focus-relative.ro-f input[type=text], .input-focus-relative.se-f input[type=text],
.input-focus-relative.sg-f input[type=text], .input-focus-relative.lk-f input[type=text], .input-focus-relative.es-f input[type=text],
.input-focus-relative.za-f input[type=text], .input-focus-relative.kr-f input[type=text], .input-focus-relative.ch-f input[type=text],
.input-focus-relative.th-f input[type=text], .input-focus-relative.tr-f input[type=text], .input-focus-relative.ve-f input[type=text],
.input-focus-relative.vn-f input[type=text], .input-focus-relative.af-f input[type=text], .input-focus-relative.cuc-f input[type=text],
.input-focus-relative.gbsct-f input[type=text], .input-focus-relative.gbwls-f input[type=text], .input-focus-relative.iri-f input[type=text] {
    padding-left: 40px !important;}

/*for country code 2 digit*/
    .input-focus-relative.uk-f input[type=text].vagaro-input, .input-focus-relative.aus-f input[type=text].vagaro-input, .input-focus-relative.ar-f input[type=text].vagaro-input,
    .input-focus-relative.be-f input[type=text].vagaro-input, .input-focus-relative.be-f input[type=text].vagaro-input, .input-focus-relative.at-f input[type=text].vagaro-input,
    .input-focus-relative.in-f input[type=text].vagaro-input, .input-focus-relative.br-f input[type=text].vagaro-input, .input-focus-relative.cl-f input[type=text].vagaro-input,
    .input-focus-relative.co-f input[type=text].vagaro-input, .input-focus-relative.dk-f input[type=text].vagaro-input, .input-focus-relative.eg-f input[type=text].vagaro-input,
    .input-focus-relative.fr-f input[type=text].vagaro-input, .input-focus-relative.de-f input[type=text].vagaro-input, .input-focus-relative.gr-f input[type=text].vagaro-input,
    .input-focus-relative.hu-f input[type=text].vagaro-input, .input-focus-relative.id-f input[type=text].vagaro-input, .input-focus-relative.id-f input[type=text].vagaro-input,
    .input-focus-relative.it-f input[type=text].vagaro-input, .input-focus-relative.jp-f input[type=text].vagaro-input, .input-focus-relative.mm-f input[type=text].vagaro-input,
    .input-focus-relative.my-f input[type=text].vagaro-input, .input-focus-relative.mx-f input[type=text].vagaro-input,
    .input-focus-relative.no-f input[type=text].vagaro-input, .input-focus-relative.nz-f input[type=text].vagaro-input, .input-focus-relative.pe-f input[type=text].vagaro-input,
    .input-focus-relative.ph-f input[type=text].vagaro-input, .input-focus-relative.pk-f input[type=text].vagaro-input, .input-focus-relative.pl-f input[type=text].vagaro-input,
    .input-focus-relative.pn-f input[type=text].vagaro-input, .input-focus-relative.ro-f input[type=text].vagaro-input, .input-focus-relative.se-f input[type=text].vagaro-input,
    .input-focus-relative.sg-f input[type=text].vagaro-input, .input-focus-relative.lk-f input[type=text].vagaro-input, .input-focus-relative.es-f input[type=text].vagaro-input,
    .input-focus-relative.za-f input[type=text].vagaro-input, .input-focus-relative.kr-f input[type=text].vagaro-input, .input-focus-relative.ch-f input[type=text].vagaro-input,
    .input-focus-relative.th-f input[type=text].vagaro-input, .input-focus-relative.tr-f input[type=text].vagaro-input, .input-focus-relative.ve-f input[type=text].vagaro-input,
    .input-focus-relative.vn-f input[type=text].vagaro-input, .input-focus-relative.af-f input[type=text].vagaro-input, .input-focus-relative.cuc-f input[type=text].vagaro-input,
    .input-focus-relative.gbsct-f input[type=text].vagaro-input, .input-focus-relative.gbwls-f input[type=text].vagaro-input, .input-focus-relative.iri-f input[type=text].vagaro-input {
        padding-left: 40px !important;}


/*for country code 4 digit*/

.input-focus-relative.ai-f input[type=text], .input-focus-relative.ag-f input[type=text], .input-focus-relative.bs-f input[type=text],
.input-focus-relative.bb-f input[type=text], .input-focus-relative.bm-f input[type=text], .input-focus-relative.bm-f input[type=text],
.input-focus-relative.vg-f input[type=text], .input-focus-relative.bq-f input[type=text], .input-focus-relative.ky-f input[type=text],
.input-focus-relative.dm-f input[type=text], .input-focus-relative.do-f input[type=text], .input-focus-relative.gd-f input[type=text],
.input-focus-relative.gu-f input[type=text], .input-focus-relative.jm-f input[type=text], .input-focus-relative.ms-f input[type=text],
.input-focus-relative.nf-f input[type=text], .input-focus-relative.mp-f input[type=text], .input-focus-relative.pr-f input[type=text],
.input-focus-relative.sj-f input[type=text], .input-focus-relative.kn-f input[type=text], .input-focus-relative.lc-f input[type=text],
.input-focus-relative.ta-f input[type=text], .input-focus-relative.tc-f input[type=text], .input-focus-relative.tt-f input[type=text],
.input-focus-relative.vi-f input[type=text], .input-focus-relative.bqb-f input[type=text], .input-focus-relative.bqsa-f input[type=text],
.input-focus-relative.bqse-f input[type=text], .input-focus-relative.gbn-f input[type=text] {
    padding-left: 57px !important;
}

    .input-focus-relative.ai-f input[type=text].vagaro-input, .input-focus-relative.ag-f input[type=text].vagaro-input, .input-focus-relative.bs-f input[type=text].vagaro-input,
    .input-focus-relative.bb-f input[type=text].vagaro-input, .input-focus-relative.bm-f input[type=text].vagaro-input, .input-focus-relative.bm-f input[type=text].vagaro-input,
    .input-focus-relative.vg-f input[type=text].vagaro-input, .input-focus-relative.bq-f input[type=text].vagaro-input, .input-focus-relative.ky-f input[type=text].vagaro-input,
    .input-focus-relative.dm-f input[type=text].vagaro-input, .input-focus-relative.do-f input[type=text].vagaro-input, .input-focus-relative.gd-f input[type=text].vagaro-input,
    .input-focus-relative.gu-f input[type=text].vagaro-input, .input-focus-relative.jm-f input[type=text].vagaro-input, .input-focus-relative.ms-f input[type=text].vagaro-input,
    .input-focus-relative.nf-f input[type=text].vagaro-input, .input-focus-relative.mp-f input[type=text].vagaro-input, .input-focus-relative.pr-f input[type=text].vagaro-input,
    .input-focus-relative.sj-f input[type=text].vagaro-input, .input-focus-relative.kn-f input[type=text].vagaro-input, .input-focus-relative.lc-f input[type=text].vagaro-input,
    .input-focus-relative.ta-f input[type=text].vagaro-input, .input-focus-relative.tc-f input[type=text].vagaro-input, .input-focus-relative.tt-f input[type=text].vagaro-input,
    .input-focus-relative.vi-f input[type=text].vagaro-input, .input-focus-relative.bqb-f input[type=text].vagaro-input, .input-focus-relative.bqsa-f input[type=text].vagaro-input,
    .input-focus-relative.bqse-f input[type=text].vagaro-input, .input-focus-relative.gbn-f input[type=text].vagaro-input, .input-focus-relative.nl-f input[type=text].vagaro-input {
     padding-left: 57px !important;}

/*for country code 5 digit*/
.input-focus-relative.cw-f input[type=text], .input-focus-relative.cw-f input[type=text].vagaro-input {padding-left: 67px !important;}

/*for country code 6 digit*/
.input-focus-relative.im-f input[type=text], .input-focus-relative.im-f input[type=text].vagaro-input,
.input-focus-relative.je-f input[type=text], .input-focus-relative.je-f input[type=text].vagaro-input,
.input-focus-relative.gg-f input[type=text], .input-focus-relative.gg-f input[type=text].vagaro-input {padding-left: 74px !important;}

/*for country code 7 digit*/
.input-focus-relative.cx-f input[type=text], .input-focus-relative.cx-f input[type=text].vagaro-input, .input-focus-relative.cc-f input[type=text], .input-focus-relative.cc-f input[type=text].vagaro-input {
padding-left: 80px !important;}



/* New Code for input type = tel Start from here*/


.input-focus-relative input[type=tel], .input-focus-relative input[type=tel].vagaro-input, .input-focus-relative input[type=tel].vagaro-input.cntry-us {
    padding-left: 50px !important;
}


/*for country code 1 digit*/
.input-focus-relative.us-f input[type=tel], .input-focus-relative.us-f input[type=tel].vagaro-input, .input-focus-relative.canada-f input[type=tel], .input-focus-relative.canada-f input[type=tel].vagaro-input,
.input-focus-relative.bv-f input[type=tel], .input-focus-relative.bv-f input[type=tel].vagaro-input, .input-focus-relative.hm-f input[type=tel], .input-focus-relative.hm-f input[type=tel].vagaro-input,
.input-focus-relative.ru-f input[type=tel], .input-focus-relative.ru-f input[type=tel].vagaro-input, .input-focus-relative.um-f input[type=tel],
.input-focus-relative.um-f input[type=tel].vagaro-input,
.input-focus-relative.umu-f input[type=tel], .input-focus-relative.umu-f input[type=tel].vagaro-input {
    padding-left: 30px !important;
}


/*for country code 2 digit*/
.input-focus-relative.uk-f input[type=tel], .input-focus-relative.aus-f input[type=tel], .input-focus-relative.ar-f input[type=tel],
.input-focus-relative.be-f input[type=tel], .input-focus-relative.be-f input[type=tel], .input-focus-relative.at-f input[type=tel],
.input-focus-relative.in-f input[type=tel], .input-focus-relative.br-f input[type=tel], .input-focus-relative.cl-f input[type=tel],
.input-focus-relative.co-f input[type=tel], .input-focus-relative.dk-f input[type=tel], .input-focus-relative.eg-f input[type=tel],
.input-focus-relative.fr-f input[type=tel], .input-focus-relative.de-f input[type=tel], .input-focus-relative.gr-f input[type=tel],
.input-focus-relative.hu-f input[type=tel], .input-focus-relative.id-f input[type=tel], .input-focus-relative.id-f input[type=tel],
.input-focus-relative.it-f input[type=tel], .input-focus-relative.jp-f input[type=tel], .input-focus-relative.mm-f input[type=tel],
.input-focus-relative.my-f input[type=tel], .input-focus-relative.mx-f input[type=tel],
.input-focus-relative.no-f input[type=tel], .input-focus-relative.nz-f input[type=tel], .input-focus-relative.pe-f input[type=tel],
.input-focus-relative.ph-f input[type=tel], .input-focus-relative.pk-f input[type=tel], .input-focus-relative.pl-f input[type=tel],
.input-focus-relative.pn-f input[type=tel], .input-focus-relative.ro-f input[type=tel], .input-focus-relative.se-f input[type=tel],
.input-focus-relative.sg-f input[type=tel], .input-focus-relative.lk-f input[type=tel], .input-focus-relative.es-f input[type=tel],
.input-focus-relative.za-f input[type=tel], .input-focus-relative.kr-f input[type=tel], .input-focus-relative.ch-f input[type=tel],
.input-focus-relative.th-f input[type=tel], .input-focus-relative.tr-f input[type=tel], .input-focus-relative.ve-f input[type=tel],
.input-focus-relative.vn-f input[type=tel], .input-focus-relative.af-f input[type=tel], .input-focus-relative.cuc-f input[type=tel],
.input-focus-relative.gbsct-f input[type=tel], .input-focus-relative.gbwls-f input[type=tel], .input-focus-relative.iri-f input[type=tel] {
    padding-left: 40px !important;
}

    /*for country code 2 digit*/
    .input-focus-relative.uk-f input[type=tel].vagaro-input, .input-focus-relative.aus-f input[type=tel].vagaro-input, .input-focus-relative.ar-f input[type=tel].vagaro-input,
    .input-focus-relative.be-f input[type=tel].vagaro-input, .input-focus-relative.be-f input[type=tel].vagaro-input, .input-focus-relative.at-f input[type=tel].vagaro-input,
    .input-focus-relative.in-f input[type=tel].vagaro-input, .input-focus-relative.br-f input[type=tel].vagaro-input, .input-focus-relative.cl-f input[type=tel].vagaro-input,
    .input-focus-relative.co-f input[type=tel].vagaro-input, .input-focus-relative.dk-f input[type=tel].vagaro-input, .input-focus-relative.eg-f input[type=tel].vagaro-input,
    .input-focus-relative.fr-f input[type=tel].vagaro-input, .input-focus-relative.de-f input[type=tel].vagaro-input, .input-focus-relative.gr-f input[type=tel].vagaro-input,
    .input-focus-relative.hu-f input[type=tel].vagaro-input, .input-focus-relative.id-f input[type=tel].vagaro-input, .input-focus-relative.id-f input[type=tel].vagaro-input,
    .input-focus-relative.it-f input[type=tel].vagaro-input, .input-focus-relative.jp-f input[type=tel].vagaro-input, .input-focus-relative.mm-f input[type=tel].vagaro-input,
    .input-focus-relative.my-f input[type=tel].vagaro-input, .input-focus-relative.mx-f input[type=tel].vagaro-input,
    .input-focus-relative.no-f input[type=tel].vagaro-input, .input-focus-relative.nz-f input[type=tel].vagaro-input, .input-focus-relative.pe-f input[type=tel].vagaro-input,
    .input-focus-relative.ph-f input[type=tel].vagaro-input, .input-focus-relative.pk-f input[type=tel].vagaro-input, .input-focus-relative.pl-f input[type=tel].vagaro-input,
    .input-focus-relative.pn-f input[type=tel].vagaro-input, .input-focus-relative.ro-f input[type=tel].vagaro-input, .input-focus-relative.se-f input[type=tel].vagaro-input,
    .input-focus-relative.sg-f input[type=tel].vagaro-input, .input-focus-relative.lk-f input[type=tel].vagaro-input, .input-focus-relative.es-f input[type=tel].vagaro-input,
    .input-focus-relative.za-f input[type=tel].vagaro-input, .input-focus-relative.kr-f input[type=tel].vagaro-input, .input-focus-relative.ch-f input[type=tel].vagaro-input,
    .input-focus-relative.th-f input[type=tel].vagaro-input, .input-focus-relative.tr-f input[type=tel].vagaro-input, .input-focus-relative.ve-f input[type=tel].vagaro-input,
    .input-focus-relative.vn-f input[type=tel].vagaro-input, .input-focus-relative.af-f input[type=tel].vagaro-input, .input-focus-relative.cuc-f input[type=tel].vagaro-input,
    .input-focus-relative.gbsct-f input[type=tel].vagaro-input, .input-focus-relative.gbwls-f input[type=tel].vagaro-input, .input-focus-relative.iri-f input[type=tel].vagaro-input {
        padding-left: 40px !important;
    }


/*for country code 4 digit*/

.input-focus-relative.ai-f input[type=tel], .input-focus-relative.ag-f input[type=tel], .input-focus-relative.bs-f input[type=tel],
.input-focus-relative.bb-f input[type=tel], .input-focus-relative.bm-f input[type=tel], .input-focus-relative.bm-f input[type=tel],
.input-focus-relative.vg-f input[type=tel], .input-focus-relative.bq-f input[type=tel], .input-focus-relative.ky-f input[type=tel],
.input-focus-relative.dm-f input[type=tel], .input-focus-relative.do-f input[type=tel], .input-focus-relative.gd-f input[type=tel],
.input-focus-relative.gu-f input[type=tel], .input-focus-relative.jm-f input[type=tel], .input-focus-relative.ms-f input[type=tel],
.input-focus-relative.nf-f input[type=tel], .input-focus-relative.mp-f input[type=tel], .input-focus-relative.pr-f input[type=tel],
.input-focus-relative.sj-f input[type=tel], .input-focus-relative.kn-f input[type=tel], .input-focus-relative.lc-f input[type=tel],
.input-focus-relative.ta-f input[type=tel], .input-focus-relative.tc-f input[type=tel], .input-focus-relative.tt-f input[type=tel],
.input-focus-relative.vi-f input[type=tel], .input-focus-relative.bqb-f input[type=tel], .input-focus-relative.bqsa-f input[type=tel],
.input-focus-relative.bqse-f input[type=tel], .input-focus-relative.gbn-f input[type=tel] {
    padding-left: 57px !important;
}

    .input-focus-relative.ai-f input[type=tel].vagaro-input, .input-focus-relative.ag-f input[type=tel].vagaro-input, .input-focus-relative.bs-f input[type=tel].vagaro-input,
    .input-focus-relative.bb-f input[type=tel].vagaro-input, .input-focus-relative.bm-f input[type=tel].vagaro-input, .input-focus-relative.bm-f input[type=tel].vagaro-input,
    .input-focus-relative.vg-f input[type=tel].vagaro-input, .input-focus-relative.bq-f input[type=tel].vagaro-input, .input-focus-relative.ky-f input[type=tel].vagaro-input,
    .input-focus-relative.dm-f input[type=tel].vagaro-input, .input-focus-relative.do-f input[type=tel].vagaro-input, .input-focus-relative.gd-f input[type=tel].vagaro-input,
    .input-focus-relative.gu-f input[type=tel].vagaro-input, .input-focus-relative.jm-f input[type=tel].vagaro-input, .input-focus-relative.ms-f input[type=tel].vagaro-input,
    .input-focus-relative.nf-f input[type=tel].vagaro-input, .input-focus-relative.mp-f input[type=tel].vagaro-input, .input-focus-relative.pr-f input[type=tel].vagaro-input,
    .input-focus-relative.sj-f input[type=tel].vagaro-input, .input-focus-relative.kn-f input[type=tel].vagaro-input, .input-focus-relative.lc-f input[type=tel].vagaro-input,
    .input-focus-relative.ta-f input[type=tel].vagaro-input, .input-focus-relative.tc-f input[type=tel].vagaro-input, .input-focus-relative.tt-f input[type=tel].vagaro-input,
    .input-focus-relative.vi-f input[type=tel].vagaro-input, .input-focus-relative.bqb-f input[type=tel].vagaro-input, .input-focus-relative.bqsa-f input[type=tel].vagaro-input,
    .input-focus-relative.bqse-f input[type=tel].vagaro-input, .input-focus-relative.gbn-f input[type=tel].vagaro-input, .input-focus-relative.nl-f input[type=tel].vagaro-input {
        padding-left: 57px !important;
    }

/*for country code 5 digit*/
.input-focus-relative.cw-f input[type=tel], .input-focus-relative.cw-f input[type=tel].vagaro-input {
    padding-left: 67px !important;
}

/*for country code 6 digit*/
.input-focus-relative.im-f input[type=tel], .input-focus-relative.im-f input[type=tel].vagaro-input,
.input-focus-relative.je-f input[type=tel], .input-focus-relative.je-f input[type=tel].vagaro-input,
.input-focus-relative.gg-f input[type=tel], .input-focus-relative.gg-f input[type=tel].vagaro-input {
    padding-left: 74px !important;
}

/*for country code 7 digit*/
.input-focus-relative.cx-f input[type=tel], .input-focus-relative.cx-f input[type=tel].vagaro-input, .input-focus-relative.cc-f input[type=tel], .input-focus-relative.cc-f input[type=tel].vagaro-input {
    padding-left: 80px !important;
}

/*New Code End from here*/




.allflag-show .selectedcountry:before { display:none!important;}
.allflag-show .form-control { border:0px!important; background:transparent!important; padding:0px!important;}
.allflag-show .select2-container.form-control { height:39px; z-index:99; display:block;}
.allflag-show .select2-container.form-control.select2-container-active {top: -1px;border: solid #2a90d6 1px !important;border-radius: 2px 0 0 2px;position: absolute;z-index: 999;
height: calc(100% + 2px);width: 67px;}
.country-code {position: absolute;left: 75px;font-weight: normal;font-size: 16px;display: flex;align-items: center;color: #333;height: 100%;z-index: 2;line-height:40px;
width: auto;text-align: center;z-index: 9; min-width:30px;}
.input-spacing-field { padding-left:30px;}
.all-country-flag.select2-drop.select2-drop-active {z-index: 100000123 !important;}
.all-country-flag .select2-no-results { padding:8px!important;}
.select2-container-disabled.ctrlDisable .select2-choices { border:0px!important;}
.allflag-show .select2-container-disabled, .allflag-show .select2-container-disabled .select2-choice {border: 0px !important;background-color: transparent !important;}
.allflag-show .select2-container-disabled .select2-arrow {background-color: transparent !important;}
.allflag-show .selectedcountry.select2-container-disabled {background-color: #f4f4f4 !important;}
.all-country-flag li.select2-result-with-children > .select2-result-label {padding: 9px 8px 9px 8px !important;line-height:18px;}
.all-country-flag li.select2-result-with-children > .select2-result-label {padding: 9px 8px 9px 8px !important;line-height: 18px;}


/*Media Start*/
@media screen and (max-width:450px) {
    .select2-results { max-height: 285px!important; overflow: auto;}
    /* .all-country-flag-react .select2-choice { height: 40px;} */
    /* .all-country-flag-dropdown  { position: fixed !important;left: 0px !important;top: 0px !important;bottom: 0px !important; width: 100%!important; border-radius: 0px!important; border: 0px!important; max-width: 100%; left: 0; top:0; height: 100%; z-index: 99999999!important;}  */
    /* .all-country-flag-dropdown .select2-dropdow { position: fixed !important;left: 0px !important;top: 0px !important;bottom: 0px !important; width: 100%!important; border-radius: 0px!important; border: 0px!important; max-width: 100%; left: 0; top:0; height: 100%;}  */
    .all-country-flag-react  .select2-results>.select2-results__options { max-height: 100%!important; height: 100%!important;}
    /* .all-country-flag-dropdown{
        position:absolute; top:0; bottom:auto;
    } */
    /* .all-country-flag-dropdown .select2-dropdown {
        background-color: #fff;
        border: 1px solid #aaa;
        border-radius: 4px;
        box-sizing: border-box;
        display: block;
        position: fixed;
        width: 100%;
        z-index: 999999999999!important;
        top: 0;
        bottom: 0;
        left:0px!Important;
        width: 100%!important;
        margin-top: 0!important;
        border: 0!important;
    } */
    /* .select2.select2-container { 
        box-sizing: border-box;
        display: inline-block;
        margin: 0;
        position: relative!important;
        vertical-align: middle;
        width: 100%;
        z-index: 999;
    } */
/* .all-country-flag .atoz {display: block !important;position: absolute;z-index: 9;
 right: 8px;top:200px;width:20px;}
.all-country-flag .atoz ul { padding:0px; margin:0px; list-style:none;}
.all-country-flag .atoz ul li { padding:0px; margin:0px; border:0px;}
.all-country-flag .atoz ul li a {padding: 0px 0 1px 0;text-decoration: none!important;color: #EB6663;display: block;font-size: 12px!important;line-height: 16px!important;text-align: center;
font-weight: 600;}

/* .all-country-flag.select2-drop  {position: fixed !important;left: 0px !important;top: 0px !important;bottom: 0px !important;
right: 0px !important;border-radius: 0px !important;box-shadow: none !important;} */
/*.all-country-flag.select2-with-searchbox.select2-drop {width: 100% !important;}
.all-country-flag .select2-results {max-height: calc(100% - 115px) !important;}
.all-country-flag .select2-search {padding: 16px 12px 16px 12px !important;margin-top: 40px;}
.all-country-flag .select2-search::before {position: absolute;left: 16px;top: -22px;content: "Select a Country";font-size: 18px;color: #333;font-weight: 500;}
.all-country-flag .select2-search input, .all-country-flag-react .select2-search__field {border-radius: 10px !important;background: #E7E7E7!important;border: solid #E7E7E7 1px !important;padding-left: 34px !important;}
.all-country-flag .select2-search input:focus, .all-country-flag-react .select2-search__field:focus {border: solid #E7E7E7 1px !important;}
.all-country-flag .select2-search::after {font-size: 18px !important;color: #9A9A9A;left: 21px;top: 27px;}
.all-country-flag-react .select2-search::after {font-size: 18px !important;color: #9A9A9A;left: 21px;top: 36px!important;}
.all-country-flag ul li {font-size: 16px !important;font-weight: 400 !important;border-bottom: solid 1px #E7E7E7;background-position: 16px 13px;}
.all-country-flag ul li:hover, .all-country-flag .select2-result-sub li.select2-highlighted {background-position: 16px 13px;}
.all-country-flag ul.select2-result-sub > li .select2-result-label {margin: 0px;padding: 13px 32px 13px 44px !important;}
.all-country-flag .select2-results li:first-child.select2-result-with-children {padding: 0px;}
.all-country-flag li.select2-result-with-children > .select2-result-label {font-size: 12px !important;color: #777 !important;
text-transform: uppercase;padding: 12px 16px 11px 16px !important;background: #F8F8F8;line-height: 20px;border-bottom: solid 1px #E7E7E7;}
.all-country-flag li.select2-result-with-children > .select2-result-label {font-size: 12px !important;color: #777 !important;text-transform: uppercase;
padding: 12px 16px 11px 16px !important;background: #F8F8F8;line-height: 20px;border-bottom: solid 1px #E7E7E7;}
.all-country-flag .select2-results li.select2-result-with-children li.select2-highlighted:first-child {background-color: #e7e7e7;} */
}



.all-country-flag .select2-result-sub li.us-f, .allflag-show .selectedcountry.us-f , .all-country-flag-react .countrys-Code.us-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/united-states-flag.svg');}
.all-country-flag .select2-result-sub li.uk-f, .allflag-show .selectedcountry.uk-f , .all-country-flag-react .countrys-Code.uk-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/united-kingdom-flag.svg');}
.all-country-flag .select2-result-sub li.canada-f, .allflag-show .selectedcountry.canada-f , .all-country-flag-react .countrys-Code.canada-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/canada-flag.svg');}
.all-country-flag .select2-result-sub li.aus-f, .allflag-show .selectedcountry.aus-f , .all-country-flag-react .countrys-Code.aus-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/australia-flag.svg');}
.all-country-flag .select2-result-sub li.in-f, .allflag-show .selectedcountry.in-f , .all-country-flag-react .countrys-Code.in-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/india-flag.svg');}
.all-country-flag .select2-result-sub li.af-f, .allflag-show .selectedcountry.af-f , .all-country-flag-react .countrys-Code.af-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/afghanistan-flag.svg');}
.all-country-flag .select2-result-sub li.al-f, .allflag-show .selectedcountry.al-f , .all-country-flag-react .countrys-Code.al-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/albania-flag.svg');}
.all-country-flag .select2-result-sub li.ax-f, .allflag-show .selectedcountry.ax-f , .all-country-flag-react .countrys-Code.ax-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/aland-islands-flag.svg');}
.all-country-flag .select2-result-sub li.dz-f, .allflag-show .selectedcountry.dz-f , .all-country-flag-react .countrys-Code.dz-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/algeria-flag.svg');}
.all-country-flag .select2-result-sub li.as-f, .allflag-show .selectedcountry.as-f , .all-country-flag-react .countrys-Code.as-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/american-samoa-flag.svg');}
.all-country-flag .select2-result-sub li.ad-f, .allflag-show .selectedcountry.ad-f , .all-country-flag-react .countrys-Code.ad-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/andorra-flag.svg');}
.all-country-flag .select2-result-sub li.ao-f, .allflag-show .selectedcountry.ao-f , .all-country-flag-react .countrys-Code.ao-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/angola-flag.svg');}
.all-country-flag .select2-result-sub li.ai-f, .allflag-show .selectedcountry.ai-f , .all-country-flag-react .countrys-Code.ai-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/anguilla-flag.svg');}
.all-country-flag .select2-result-sub li.aq-f, .allflag-show .selectedcountry.aq-f , .all-country-flag-react .countrys-Code.aq-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/antarctica-flag.svg');}
.all-country-flag .select2-result-sub li.ag-f, .allflag-show .selectedcountry.ag-f , .all-country-flag-react .countrys-Code.ag-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/antigua-barbuda-flag.svg');}
.all-country-flag .select2-result-sub li.ar-f, .allflag-show .selectedcountry.ar-f , .all-country-flag-react .countrys-Code.ar-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/argentina-flag.svg');}
.all-country-flag .select2-result-sub li.am-f, .allflag-show .selectedcountry.am-f , .all-country-flag-react .countrys-Code.am-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/armenia-flag.svg');}
.all-country-flag .select2-result-sub li.aw-f, .allflag-show .selectedcountry.aw-f , .all-country-flag-react .countrys-Code.aw-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/aruba-flag.svg');}
.all-country-flag .select2-result-sub li.ac-f, .allflag-show .selectedcountry.ac-f , .all-country-flag-react .countrys-Code.ac-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/ascension-island-flag.svg');}
.all-country-flag .select2-result-sub li.at-f, .allflag-show .selectedcountry.at-f , .all-country-flag-react .countrys-Code.at-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/austria-flag.svg');}
.all-country-flag .select2-result-sub li.az-f, .allflag-show .selectedcountry.az-f , .all-country-flag-react .countrys-Code.az-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/azerbaijan-flag.svg');}
.all-country-flag .select2-result-sub li.bs-f, .allflag-show .selectedcountry.bs-f , .all-country-flag-react .countrys-Code.bs-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/bahamas-flag.svg');}
.all-country-flag .select2-result-sub li.bh-f, .allflag-show .selectedcountry.bh-f , .all-country-flag-react .countrys-Code.bh-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/bahrain-flag.svg');}
.all-country-flag .select2-result-sub li.bd-f, .allflag-show .selectedcountry.bd-f , .all-country-flag-react .countrys-Code.bd-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/bangladesh-flag.svg');}
.all-country-flag .select2-result-sub li.bb-f, .allflag-show .selectedcountry.bb-f , .all-country-flag-react .countrys-Code.bb-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/barbados-flag.svg');}
.all-country-flag .select2-result-sub li.by-f, .allflag-show .selectedcountry.by-f , .all-country-flag-react .countrys-Code.by-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/belarus-flag.svg');}
.all-country-flag .select2-result-sub li.be-f, .allflag-show .selectedcountry.be-f , .all-country-flag-react .countrys-Code.be-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/belgium-flag.svg');}
.all-country-flag .select2-result-sub li.bz-f, .allflag-show .selectedcountry.bz-f , .all-country-flag-react .countrys-Code.bz-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/belize-flag.svg');}
.all-country-flag .select2-result-sub li.bj-f, .allflag-show .selectedcountry.bj-f , .all-country-flag-react .countrys-Code.bj-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/benin-flag.svg');}
.all-country-flag .select2-result-sub li.bm-f, .allflag-show .selectedcountry.bm-f , .all-country-flag-react .countrys-Code.bm-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/bermuda-flag.svg');}
.all-country-flag .select2-result-sub li.bt-f, .allflag-show .selectedcountry.bt-f , .all-country-flag-react .countrys-Code.bt-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/bhutan-flag.svg');}
.all-country-flag .select2-result-sub li.bo-f, .allflag-show .selectedcountry.bo-f , .all-country-flag-react .countrys-Code.bo-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/bolivia-flag.svg');}
.all-country-flag .select2-result-sub li.ba-f, .allflag-show .selectedcountry.ba-f , .all-country-flag-react .countrys-Code.ba-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/bosnia-herzegovina-new-flag.svg');}
.all-country-flag .select2-result-sub li.bw-f, .allflag-show .selectedcountry.bw-f , .all-country-flag-react .countrys-Code.bw-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/botswana-flag.svg');}
.all-country-flag .select2-result-sub li.bv-f, .allflag-show .selectedcountry.bv-f , .all-country-flag-react .countrys-Code.bv-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/bouvet-Island-flag.svg');}
.all-country-flag .select2-result-sub li.br-f, .allflag-show .selectedcountry.br-f , .all-country-flag-react .countrys-Code.br-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/brazil-flag.svg');}
.all-country-flag .select2-result-sub li.io-f, .allflag-show .selectedcountry.io-f , .all-country-flag-react .countrys-Code.io-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/british-indian-ocean-territory-flag.svg');}
.all-country-flag .select2-result-sub li.bn-f, .allflag-show .selectedcountry.bn-f , .all-country-flag-react .countrys-Code.bn-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/brunei-darussalam-flag.svg');}
.all-country-flag .select2-result-sub li.bg-f, .allflag-show .selectedcountry.bg-f , .all-country-flag-react .countrys-Code.bg-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/bulgaria-flag.svg');}
.all-country-flag .select2-result-sub li.bf-f, .allflag-show .selectedcountry.bf-f , .all-country-flag-react .countrys-Code.bf-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/burkina-faso-flag.svg');}
.all-country-flag .select2-result-sub li.bi-f, .allflag-show .selectedcountry.bi-f , .all-country-flag-react .countrys-Code.bi-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/burundi-flag.svg');}
.all-country-flag .select2-result-sub li.kh-f, .allflag-show .selectedcountry.kh-f , .all-country-flag-react .countrys-Code.kh-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/cambodia-flag.svg');}
.all-country-flag .select2-result-sub li.cm-f, .allflag-show .selectedcountry.cm-f , .all-country-flag-react .countrys-Code.cm-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/cameroon-flag.svg');}
.all-country-flag .select2-result-sub li.cv-f, .allflag-show .selectedcountry.cv-f , .all-country-flag-react .countrys-Code.cv-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/cabo-verde-flag.svg');}
.all-country-flag .select2-result-sub li.ky-f, .allflag-show .selectedcountry.ky-f , .all-country-flag-react .countrys-Code.ky-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/cayman-islands-flag.svg');}
.all-country-flag .select2-result-sub li.cf-f, .allflag-show .selectedcountry.cf-f , .all-country-flag-react .countrys-Code.cf-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/central-african-republic-flag.svg');}
.all-country-flag .select2-result-sub li.td-f, .allflag-show .selectedcountry.td-f , .all-country-flag-react .countrys-Code.td-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/chad-flag.svg');}
.all-country-flag .select2-result-sub li.cl-f, .allflag-show .selectedcountry.cl-f , .all-country-flag-react .countrys-Code.cl-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/chile-flag.svg');}
.all-country-flag .select2-result-sub li.cx-f, .allflag-show .selectedcountry.cx-f , .all-country-flag-react .countrys-Code.cx-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/christmas-island-flag.svg');}
.all-country-flag .select2-result-sub li.cc-f, .allflag-show .selectedcountry.cc-f , .all-country-flag-react .countrys-Code.cc-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/cocos-keeling-islands-flag.svg');}
.all-country-flag .select2-result-sub li.co-f, .allflag-show .selectedcountry.co-f , .all-country-flag-react .countrys-Code.co-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/colombia-flag.svg');}
.all-country-flag .select2-result-sub li.km-f, .allflag-show .selectedcountry.km-f , .all-country-flag-react .countrys-Code.km-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/comoros-flag.svg');}
.all-country-flag .select2-result-sub li.ck-f, .allflag-show .selectedcountry.ck-f , .all-country-flag-react .countrys-Code.ck-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/cook-islands-flag.svg');}
.all-country-flag .select2-result-sub li.cr-f, .allflag-show .selectedcountry.cr-f , .all-country-flag-react .countrys-Code.cr-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/costa-rica-flag.svg');}
.all-country-flag .select2-result-sub li.ci-f, .allflag-show .selectedcountry.ci-f , .all-country-flag-react .countrys-Code.ci-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/côte-dIvoire-Ivory-coast-flag.svg');}
.all-country-flag .select2-result-sub li.hr-f, .allflag-show .selectedcountry.hr-f , .all-country-flag-react .countrys-Code.hr-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/croatia-hrvatska-flag.svg');}
.all-country-flag .select2-result-sub li.cw-f, .allflag-show .selectedcountry.cw-f , .all-country-flag-react .countrys-Code.cw-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/curaçao-flag.svg');}
.all-country-flag .select2-result-sub li.cy-f, .allflag-show .selectedcountry.cy-f , .all-country-flag-react .countrys-Code.cy-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/cyprus-flag.svg');}
.all-country-flag .select2-result-sub li.cz-f, .allflag-show .selectedcountry.cz-f , .all-country-flag-react .countrys-Code.cz-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/czech-republic-flag.svg');}
.all-country-flag .select2-result-sub li.dk-f, .allflag-show .selectedcountry.dk-f , .all-country-flag-react .countrys-Code.dk-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/denmark-flag.svg');}
.all-country-flag .select2-result-sub li.dj-f, .allflag-show .selectedcountry.dj-f , .all-country-flag-react .countrys-Code.dj-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/djibouti-falg.svg');}
.all-country-flag .select2-result-sub li.dm-f, .allflag-show .selectedcountry.dm-f , .all-country-flag-react .countrys-Code.dm-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/dominica-falg.svg');}
.all-country-flag .select2-result-sub li.do-f, .allflag-show .selectedcountry.do-f , .all-country-flag-react .countrys-Code.do-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/dominican-republic-flag.svg');}
.all-country-flag .select2-result-sub li.ec-f, .allflag-show .selectedcountry.ec-f , .all-country-flag-react .countrys-Code.ec-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/ecuador-flag.svg');}
.all-country-flag .select2-result-sub li.eg-f, .allflag-show .selectedcountry.eg-f , .all-country-flag-react .countrys-Code.eg-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/ecuador-flag.svg');}
.all-country-flag .select2-result-sub li.sv-f, .allflag-show .selectedcountry.sv-f , .all-country-flag-react .countrys-Code.sv-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/ei-salvador-flag.svg'); }
.all-country-flag .select2-result-sub li.gq-f, .allflag-show .selectedcountry.gq-f , .all-country-flag-react .countrys-Code.gq-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/equatorial-guinea-flag.svg');}
.all-country-flag .select2-result-sub li.er-f, .allflag-show .selectedcountry.er-f , .all-country-flag-react .countrys-Code.er-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/eritrea-new-flag.svg'); }
.all-country-flag .select2-result-sub li.ee-f, .allflag-show .selectedcountry.ee-f , .all-country-flag-react .countrys-Code.ee-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/estonia-flag.svg');}
.all-country-flag .select2-result-sub li.et-f, .allflag-show .selectedcountry.et-f , .all-country-flag-react .countrys-Code.et-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/ethiopia-flag.svg');}
.all-country-flag .select2-result-sub li.fk-f, .allflag-show .selectedcountry.fk-f , .all-country-flag-react .countrys-Code.fk-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/falkland-islands-flag.svg');}
.all-country-flag .select2-result-sub li.fo-f, .allflag-show .selectedcountry.fo-f , .all-country-flag-react .countrys-Code.fo-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/faroe-islands-flag.svg');}
.all-country-flag .select2-result-sub li.fj-f, .allflag-show .selectedcountry.fj-f , .all-country-flag-react .countrys-Code.fj-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/fiji-flag.svg');}
.all-country-flag .select2-result-sub li.fi-f, .allflag-show .selectedcountry.fi-f , .all-country-flag-react .countrys-Code.fi-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/finland-flag.svg');}
.all-country-flag .select2-result-sub li.fr-f, .allflag-show .selectedcountry.fr-f , .all-country-flag-react .countrys-Code.fr-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/france-flag.svg');}
.all-country-flag .select2-result-sub li.gf-f, .allflag-show .selectedcountry.gf-f , .all-country-flag-react .countrys-Code.gf-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/french-guiana-flag.svg');} 
.all-country-flag .select2-result-sub li.pf-f, .allflag-show .selectedcountry.pf-f , .all-country-flag-react .countrys-Code.pf-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/french-polynesia-flag.svg');}
.all-country-flag .select2-result-sub li.tf-f, .allflag-show .selectedcountry.tf-f , .all-country-flag-react .countrys-Code.tf-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/french-southern-antarctic-lands-flag.svg'); }
.all-country-flag .select2-result-sub li.gm-f, .allflag-show .selectedcountry.gm-f , .all-country-flag-react .countrys-Code.gm-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/gambia-flag.svg'); }
.all-country-flag .select2-result-sub li.ge-f, .allflag-show .selectedcountry.ge-f , .all-country-flag-react .countrys-Code.ge-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/georgia-flag.svg'); }
.all-country-flag .select2-result-sub li.de-f, .allflag-show .selectedcountry.de-f , .all-country-flag-react .countrys-Code.de-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/germany-flag.svg'); }
.all-country-flag .select2-result-sub li.gh-f, .allflag-show .selectedcountry.gh-f , .all-country-flag-react .countrys-Code.gh-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/ghana-flag.svg');}
.all-country-flag .select2-result-sub li.gr-f, .allflag-show .selectedcountry.gr-f , .all-country-flag-react .countrys-Code.gr-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/greece-flag.svg');}
.all-country-flag .select2-result-sub li.gd-f, .allflag-show .selectedcountry.gd-f , .all-country-flag-react .countrys-Code.gd-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/grenada-flag.svg');}
.all-country-flag .select2-result-sub li.gi-f, .allflag-show .selectedcountry.gi-f , .all-country-flag-react .countrys-Code.gi-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/gibraltar-flag.svg');}
.all-country-flag .select2-result-sub li.gl-f, .allflag-show .selectedcountry.gl-f , .all-country-flag-react .countrys-Code.gl-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/greenland-flag.svg');}
.all-country-flag .select2-result-sub li.gn-f, .allflag-show .selectedcountry.gn-f , .all-country-flag-react .countrys-Code.gn-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/guinea-flag.svg');}
.all-country-flag .select2-result-sub li.gp-f, .allflag-show .selectedcountry.gp-f , .all-country-flag-react .countrys-Code.gp-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/guadeloupe-flag.svg');}
.all-country-flag .select2-result-sub li.gt-f, .allflag-show .selectedcountry.gt-f , .all-country-flag-react .countrys-Code.gt-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/guatemala-flag.svg');}
.all-country-flag .select2-result-sub li.gu-f, .allflag-show .selectedcountry.gu-f , .all-country-flag-react .countrys-Code.gu-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/guam-flag.svg'); }
.all-country-flag .select2-result-sub li.gw-f, .allflag-show .selectedcountry.gw-f , .all-country-flag-react .countrys-Code.gw-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/guinea-bissau-flag.svg'); }
.all-country-flag .select2-result-sub li.gy-f, .allflag-show .selectedcountry.gy-f , .all-country-flag-react .countrys-Code.gy-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/guyana-flag.svg');}
.all-country-flag .select2-result-sub li.ht-f, .allflag-show .selectedcountry.ht-f , .all-country-flag-react .countrys-Code.ht-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/haiti-flag.svg');}
.all-country-flag .select2-result-sub li.hk-f, .allflag-show .selectedcountry.hk-f , .all-country-flag-react .countrys-Code.hk-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/hong-kong-flag.svg');}
.all-country-flag .select2-result-sub li.hm-f, .allflag-show .selectedcountry.hm-f , .all-country-flag-react .countrys-Code.hm-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/heard-mc-donald-islands-new-flag.svg');}
.all-country-flag .select2-result-sub li.hn-f, .allflag-show .selectedcountry.hn-f , .all-country-flag-react .countrys-Code.hn-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/honduras-flag.svg');}
.all-country-flag .select2-result-sub li.hu-f, .allflag-show .selectedcountry.hu-f , .all-country-flag-react .countrys-Code.hu-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/hungary-flag.svg');}
.all-country-flag .select2-result-sub li.id-f, .allflag-show .selectedcountry.id-f , .all-country-flag-react .countrys-Code.id-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/indonesia-flag.svg');}
.all-country-flag .select2-result-sub li.ie-f, .allflag-show .selectedcountry.ie-f , .all-country-flag-react .countrys-Code.ie-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/ireland-flag.svg');}
.all-country-flag .select2-result-sub li.il-f, .allflag-show .selectedcountry.il-f , .all-country-flag-react .countrys-Code.il-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/isreal-flag.svg');}
.all-country-flag .select2-result-sub li.im-f, .allflag-show .selectedcountry.im-f , .all-country-flag-react .countrys-Code.im-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/isle-of-man-flag.svg');}
.all-country-flag .select2-result-sub li.iq-f, .allflag-show .selectedcountry.iq-f , .all-country-flag-react .countrys-Code.iq-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/iraq-flag.svg');}
.all-country-flag .select2-result-sub li.is-f, .allflag-show .selectedcountry.is-f , .all-country-flag-react .countrys-Code.is-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/iceland-flag.svg');}
.all-country-flag .select2-result-sub li.it-f, .allflag-show .selectedcountry.it-f , .all-country-flag-react .countrys-Code.it-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/italy-flag.svg');}
.all-country-flag .select2-result-sub li.je-f, .allflag-show .selectedcountry.je-f , .all-country-flag-react .countrys-Code.je-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/jersey-flag.svg');}
.all-country-flag .select2-result-sub li.jm-f, .allflag-show .selectedcountry.jm-f , .all-country-flag-react .countrys-Code.jm-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/jamaica-flag.svg');}
.all-country-flag .select2-result-sub li.jo-f, .allflag-show .selectedcountry.jo-f , .all-country-flag-react .countrys-Code.jo-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/jordan-flag.svg');}
.all-country-flag .select2-result-sub li.jp-f, .allflag-show .selectedcountry.jp-f , .all-country-flag-react .countrys-Code.jp-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/japan-flag.svg');}
.all-country-flag .select2-result-sub li.ke-f, .allflag-show .selectedcountry.ke-f , .all-country-flag-react .countrys-Code.ke-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/kenia-flag.svg');}
.all-country-flag .select2-result-sub li.kg-f, .allflag-show .selectedcountry.kg-f , .all-country-flag-react .countrys-Code.kg-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/kyrgyzstan-flag.svg');}
.all-country-flag .select2-result-sub li.ki-f, .allflag-show .selectedcountry.ki-f , .all-country-flag-react .countrys-Code.ki-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/kiribati-flag.svg');}
.all-country-flag .select2-result-sub li.kw-f, .allflag-show .selectedcountry.kw-f , .all-country-flag-react .countrys-Code.kw-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/kuwait-flag.svg');}
.all-country-flag .select2-result-sub li.kz-f, .allflag-show .selectedcountry.kz-f , .all-country-flag-react .countrys-Code.kz-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/kazakhstan-flag.svg');}
.all-country-flag .select2-result-sub li.xk-f, .allflag-show .selectedcountry.xk-f , .all-country-flag-react .countrys-Code.xk-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/kosovo-flag.svg');}
.all-country-flag .select2-result-sub li.la-f, .allflag-show .selectedcountry.la-f , .all-country-flag-react .countrys-Code.la-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/laos-flag.svg');}
.all-country-flag .select2-result-sub li.lb-f, .allflag-show .selectedcountry.lb-f , .all-country-flag-react .countrys-Code.lb-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/lebanon-flag.svg');}
.all-country-flag .select2-result-sub li.li-f, .allflag-show .selectedcountry.li-f , .all-country-flag-react .countrys-Code.li-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/liechtenstein-flag.svg');}
.all-country-flag .select2-result-sub li.lr-f, .allflag-show .selectedcountry.lr-f , .all-country-flag-react .countrys-Code.lr-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/liberia-flag.svg');}
.all-country-flag .select2-result-sub li.ls-f, .allflag-show .selectedcountry.ls-f , .all-country-flag-react .countrys-Code.ls-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/lesotho-flag.svg');}
.all-country-flag .select2-result-sub li.lt-f, .allflag-show .selectedcountry.lt-f , .all-country-flag-react .countrys-Code.lt-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/lithuania-flag.svg');}
.all-country-flag .select2-result-sub li.lu-f, .allflag-show .selectedcountry.lu-f , .all-country-flag-react .countrys-Code.lu-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/luxembourg-flag.svg');}
.all-country-flag .select2-result-sub li.lv-f, .allflag-show .selectedcountry.lv-f , .all-country-flag-react .countrys-Code.lv-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/latvia-flag.svg');}
.all-country-flag .select2-result-sub li.ly-f, .allflag-show .selectedcountry.ly-f , .all-country-flag-react .countrys-Code.ly-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/libya-flag.svg');}
.all-country-flag .select2-result-sub li.ma-f, .allflag-show .selectedcountry.ma-f , .all-country-flag-react .countrys-Code.ma-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/macao-flag.svg');}
.all-country-flag .select2-result-sub li.mc-f, .allflag-show .selectedcountry.mc-f , .all-country-flag-react .countrys-Code.mc-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/monaco-flag.svg');}
.all-country-flag .select2-result-sub li.md-f, .allflag-show .selectedcountry.md-f , .all-country-flag-react .countrys-Code.md-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/moldova-flag.svg');}
.all-country-flag .select2-result-sub li.me-f, .allflag-show .selectedcountry.me-f , .all-country-flag-react .countrys-Code.me-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/montenegro-flag.svg');}
.all-country-flag .select2-result-sub li.mg-f, .allflag-show .selectedcountry.mg-f , .all-country-flag-react .countrys-Code.mg-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/madagascar-flag.svg');}
.all-country-flag .select2-result-sub li.mh-f, .allflag-show .selectedcountry.mh-f , .all-country-flag-react .countrys-Code.mh-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/marshall-islands-flag.svg');}
.all-country-flag .select2-result-sub li.ml-f, .allflag-show .selectedcountry.ml-f , .all-country-flag-react .countrys-Code.ml-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/mali-flag.svg');}
.all-country-flag .select2-result-sub li.yt-f, .allflag-show .selectedcountry.yt-f , .all-country-flag-react .countrys-Code.yt-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/mayotte-flag.svg');}
.all-country-flag .select2-result-sub li.mm-f, .allflag-show .selectedcountry.mm-f , .all-country-flag-react .countrys-Code.mm-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/myanmar-flag.svg');}
.all-country-flag .select2-result-sub li.mn-f, .allflag-show .selectedcountry.mn-f , .all-country-flag-react .countrys-Code.mn-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/mongolia-flag.svg');}
.all-country-flag .select2-result-sub li.my-f, .allflag-show .selectedcountry.my-f , .all-country-flag-react .countrys-Code.my-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/malaysia-flag.svg');}
.all-country-flag .select2-result-sub li.mq-f, .allflag-show .selectedcountry.mq-f , .all-country-flag-react .countrys-Code.mq-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/Martinique-flag.svg');}
.all-country-flag .select2-result-sub li.mr-f, .allflag-show .selectedcountry.mr-f , .all-country-flag-react .countrys-Code.mr-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/mauritania-flag.svg');}
.all-country-flag .select2-result-sub li.ms-f, .allflag-show .selectedcountry.ms-f , .all-country-flag-react .countrys-Code.ms-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/montserrat-flag.svg');}
.all-country-flag .select2-result-sub li.mt-f, .allflag-show .selectedcountry.mt-f , .all-country-flag-react .countrys-Code.mt-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/malta-flag.svg');}
.all-country-flag .select2-result-sub li.mu-f, .allflag-show .selectedcountry.mu-f , .all-country-flag-react .countrys-Code.mu-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/mauritius-flag.svg');}
.all-country-flag .select2-result-sub li.mv-f, .allflag-show .selectedcountry.mv-f , .all-country-flag-react .countrys-Code.mv-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/maldives-flag.svg');}
.all-country-flag .select2-result-sub li.mw-f, .allflag-show .selectedcountry.mw-f , .all-country-flag-react .countrys-Code.mw-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/malawi-flag.svg');}
.all-country-flag .select2-result-sub li.mx-f, .allflag-show .selectedcountry.mx-f , .all-country-flag-react .countrys-Code.mx-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/mexico-flag.svg');}
.all-country-flag .select2-result-sub li.mz-f, .allflag-show .selectedcountry.mz-f , .all-country-flag-react .countrys-Code.mz-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/mozambique-flag.svg');}
.all-country-flag .select2-result-sub li.na-f, .allflag-show .selectedcountry.na-f , .all-country-flag-react .countrys-Code.na-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/namibia-flag.svg');}
.all-country-flag .select2-result-sub li.ne-f, .allflag-show .selectedcountry.ne-f , .all-country-flag-react .countrys-Code.ne-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/niger-flag.svg');}
.all-country-flag .select2-result-sub li.nf-f, .allflag-show .selectedcountry.nf-f , .all-country-flag-react .countrys-Code.nf-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/norfolk-island-flag.svg');}
.all-country-flag .select2-result-sub li.mp-f, .allflag-show .selectedcountry.mp-f , .all-country-flag-react .countrys-Code.mp-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/northern-mariana-islands-flag.svg');}
.all-country-flag .select2-result-sub li.ng-f, .allflag-show .selectedcountry.ng-f , .all-country-flag-react .countrys-Code.ng-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/nigeria-flag.svg');}
.all-country-flag .select2-result-sub li.ni-f, .allflag-show .selectedcountry.ni-f , .all-country-flag-react .countrys-Code.ni-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/nicaragua-flag.svg');}
.all-country-flag .select2-result-sub li.nl-f, .allflag-show .selectedcountry.nl-f , .all-country-flag-react .countrys-Code.nl-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/netherlands-flag.svg');}
.all-country-flag .select2-result-sub li.no-f, .allflag-show .selectedcountry.no-f , .all-country-flag-react .countrys-Code.no-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/norway-flag.svg');}
.all-country-flag .select2-result-sub li.np-f, .allflag-show .selectedcountry.np-f , .all-country-flag-react .countrys-Code.np-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/nepal-flag.svg');}
.all-country-flag .select2-result-sub li.nr-f, .allflag-show .selectedcountry.nr-f , .all-country-flag-react .countrys-Code.nr-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/nauru-flag.svg');}
.all-country-flag .select2-result-sub li.nu-f, .allflag-show .selectedcountry.nu-f , .all-country-flag-react .countrys-Code.nu-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/niue-flag.svg');}
.all-country-flag .select2-result-sub li.nz-f, .allflag-show .selectedcountry.nz-f , .all-country-flag-react .countrys-Code.nz-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/new-zealand-flag.svg');}
.all-country-flag .select2-result-sub li.om-f, .allflag-show .selectedcountry.om-f , .all-country-flag-react .countrys-Code.om-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/oman-flag.svg');}
.all-country-flag .select2-result-sub li.pa-f, .allflag-show .selectedcountry.pa-f , .all-country-flag-react .countrys-Code.pa-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/panama-flag.svg');}
.all-country-flag .select2-result-sub li.pe-f, .allflag-show .selectedcountry.pe-f , .all-country-flag-react .countrys-Code.pe-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/peru-flag.svg');}
.all-country-flag .select2-result-sub li.pg-f, .allflag-show .selectedcountry.pg-f , .all-country-flag-react .countrys-Code.pg-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/papua-new-guinea.svg');}
.all-country-flag .select2-result-sub li.ph-f, .allflag-show .selectedcountry.ph-f , .all-country-flag-react .countrys-Code.ph-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/philippines-flag.svg');}
.all-country-flag .select2-result-sub li.pk-f, .allflag-show .selectedcountry.pk-f , .all-country-flag-react .countrys-Code.pk-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/pakistan-flag.svg');}
.all-country-flag .select2-result-sub li.pl-f, .allflag-show .selectedcountry.pl-f , .all-country-flag-react .countrys-Code.pl-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/poland-flag.svg');}
.all-country-flag .select2-result-sub li.pn-f, .allflag-show .selectedcountry.pn-f , .all-country-flag-react .countrys-Code.pn-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/pitcairn-islands-flag.svg');}
.all-country-flag .select2-result-sub li.pr-f, .allflag-show .selectedcountry.pr-f , .all-country-flag-react .countrys-Code.pr-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/puerto-rico-flag.svg');}
.all-country-flag .select2-result-sub li.ps-f, .allflag-show .selectedcountry.ps-f , .all-country-flag-react .countrys-Code.ps-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/palestine-flag.svg');}
.all-country-flag .select2-result-sub li.pt-f, .allflag-show .selectedcountry.pt-f , .all-country-flag-react .countrys-Code.pt-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/portugal-flag.svg');}
.all-country-flag .select2-result-sub li.pw-f, .allflag-show .selectedcountry.pw-f , .all-country-flag-react .countrys-Code.pw-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/palau-flag.svg');}
.all-country-flag .select2-result-sub li.py-f, .allflag-show .selectedcountry.py-f , .all-country-flag-react .countrys-Code.py-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/paraguay-flag.svg');}
.all-country-flag .select2-result-sub li.qa-f, .allflag-show .selectedcountry.qa-f , .all-country-flag-react .countrys-Code.qa-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/qatar-flag.svg');}
.all-country-flag .select2-result-sub li.re-f, .allflag-show .selectedcountry.re-f , .all-country-flag-react .countrys-Code.re-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/reunion-flag.svg');}
.all-country-flag .select2-result-sub li.ro-f, .allflag-show .selectedcountry.ro-f , .all-country-flag-react .countrys-Code.ro-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/romania-flag.svg');}
.all-country-flag .select2-result-sub li.ru-f, .allflag-show .selectedcountry.ru-f , .all-country-flag-react .countrys-Code.ru-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/russian-federation-flag.svg');}
.all-country-flag .select2-result-sub li.rw-f, .allflag-show .selectedcountry.rw-f , .all-country-flag-react .countrys-Code.rw-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/rwanda-flag.svg');}
.all-country-flag .select2-result-sub li.sm-f, .allflag-show .selectedcountry.sm-f , .all-country-flag-react .countrys-Code.sm-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/san-marino-flag.svg');}
.all-country-flag .select2-result-sub li.ws-f, .allflag-show .selectedcountry.ws-f , .all-country-flag-react .countrys-Code.ws-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/samoa-flag.svg');}
.all-country-flag .select2-result-sub li.sa-f, .allflag-show .selectedcountry.sa-f , .all-country-flag-react .countrys-Code.sa-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/saudi-arabia-flag.svg');}
.all-country-flag .select2-result-sub li.gs-f, .allflag-show .selectedcountry.gs-f , .all-country-flag-react .countrys-Code.gs-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/s-georgia-s-sandwich-islands-flag.svg');}
.all-country-flag .select2-result-sub li.sb-f, .allflag-show .selectedcountry.sb-f , .all-country-flag-react .countrys-Code.sb-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/solomon-islands-flag.svg');}
.all-country-flag .select2-result-sub li.sn-f, .allflag-show .selectedcountry.sn-f , .all-country-flag-react .countrys-Code.sn-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/senegal-flag.svg');}
.all-country-flag .select2-result-sub li.rs-f, .allflag-show .selectedcountry.rs-f , .all-country-flag-react .countrys-Code.rs-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/serbia-flag.svg');}
.all-country-flag .select2-result-sub li.sc-f, .allflag-show .selectedcountry.sc-f , .all-country-flag-react .countrys-Code.sc-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/seychelles-flag.svg');}
.all-country-flag .select2-result-sub li.se-f, .allflag-show .selectedcountry.se-f , .all-country-flag-react .countrys-Code.se-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/sweden-flag.svg');}
.all-country-flag .select2-result-sub li.sg-f, .allflag-show .selectedcountry.sg-f , .all-country-flag-react .countrys-Code.sg-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/singapore-flag.svg');}
.all-country-flag .select2-result-sub li.lk-f, .allflag-show .selectedcountry.lk-f , .all-country-flag-react .countrys-Code.lk-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/sri-lanka-flag.svg');}
.all-country-flag .select2-result-sub li.si-f, .allflag-show .selectedcountry.si-f , .all-country-flag-react .countrys-Code.si-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/slovenia-flag.svg');}
.all-country-flag .select2-result-sub li.sj-f, .allflag-show .selectedcountry.sj-f , .all-country-flag-react .countrys-Code.sj-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/svalbard-jan-mayen.svg');}
.all-country-flag .select2-result-sub li.sk-f, .allflag-show .selectedcountry.sk-f , .all-country-flag-react .countrys-Code.sk-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/slovakia-flag.svg');}
.all-country-flag .select2-result-sub li.sl-f, .allflag-show .selectedcountry.sl-f , .all-country-flag-react .countrys-Code.sl-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/sierra-leone-flag.svg');}
.all-country-flag .select2-result-sub li.es-f, .allflag-show .selectedcountry.es-f , .all-country-flag-react .countrys-Code.es-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/spain-flag.svg');}
.all-country-flag .select2-result-sub li.za-f, .allflag-show .selectedcountry.za-f , .all-country-flag-react .countrys-Code.za-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/south-africa-flag.svg');}
.all-country-flag .select2-result-sub li.kr-f, .allflag-show .selectedcountry.kr-f , .all-country-flag-react .countrys-Code.kr-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/korea-south-flag.svg');}
.all-country-flag .select2-result-sub li.so-f, .allflag-show .selectedcountry.so-f , .all-country-flag-react .countrys-Code.so-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/somalia-flag.svg');}
.all-country-flag .select2-result-sub li.sr-f, .allflag-show .selectedcountry.sr-f , .all-country-flag-react .countrys-Code.sr-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/suriname-img.svg');}
.all-country-flag .select2-result-sub li.ss-f, .allflag-show .selectedcountry.ss-f , .all-country-flag-react .countrys-Code.ss-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/south-sudan-flag.svg');}
.all-country-flag .select2-result-sub li.st-f, .allflag-show .selectedcountry.st-f , .all-country-flag-react .countrys-Code.st-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/sao-tome-principe-img.svg');}
.all-country-flag .select2-result-sub li.sx-f, .allflag-show .selectedcountry.sx-f , .all-country-flag-react .countrys-Code.sx-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/sint-maarten-flag.svg');}
.all-country-flag .select2-result-sub li.ch-f, .allflag-show .selectedcountry.ch-f , .all-country-flag-react .countrys-Code.ch-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/switzerland-flag.svg');}
.all-country-flag .select2-result-sub li.sz-f, .allflag-show .selectedcountry.sz-f , .all-country-flag-react .countrys-Code.sz-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/swaziland-flag.svg');}
.all-country-flag .select2-result-sub li.ta-f, .allflag-show .selectedcountry.ta-f , .all-country-flag-react .countrys-Code.ta-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/tristan-cunha-flag.svg');}
.all-country-flag .select2-result-sub li.tc-f, .allflag-show .selectedcountry.tc-f , .all-country-flag-react .countrys-Code.tc-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/turks-caicos-islands-flag.svg');}
.all-country-flag .select2-result-sub li.tg-f, .allflag-show .selectedcountry.tg-f , .all-country-flag-react .countrys-Code.tg-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/togo-flag.svg');}
.all-country-flag .select2-result-sub li.th-f, .allflag-show .selectedcountry.th-f , .all-country-flag-react .countrys-Code.th-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/thailand-flag.svg');}
.all-country-flag .select2-result-sub li.tj-f, .allflag-show .selectedcountry.tj-f , .all-country-flag-react .countrys-Code.tj-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/tajikistan-flag.svg');}
.all-country-flag .select2-result-sub li.tk-f, .allflag-show .selectedcountry.tk-f , .all-country-flag-react .countrys-Code.tk-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/tokelau-flag.svg');}
.all-country-flag .select2-result-sub li.tl-f, .allflag-show .selectedcountry.tl-f , .all-country-flag-react .countrys-Code.tl-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/timor-leste-flag.svg');}
.all-country-flag .select2-result-sub li.tm-f, .allflag-show .selectedcountry.tm-f , .all-country-flag-react .countrys-Code.tm-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/turkmenistan-flag.svg');}
.all-country-flag .select2-result-sub li.tn-f, .allflag-show .selectedcountry.tn-f , .all-country-flag-react .countrys-Code.tn-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/tunisia-flag.svg');}
.all-country-flag .select2-result-sub li.to-f, .allflag-show .selectedcountry.to-f , .all-country-flag-react .countrys-Code.to-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/tonga-flag.svg');}
.all-country-flag .select2-result-sub li.tr-f, .allflag-show .selectedcountry.tr-f , .all-country-flag-react .countrys-Code.tr-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/turkey-flag.svg');}
.all-country-flag .select2-result-sub li.tt-f, .allflag-show .selectedcountry.tt-f , .all-country-flag-react .countrys-Code.tt-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/trinidad-tobago-flag.svg');}
.all-country-flag .select2-result-sub li.tv-f, .allflag-show .selectedcountry.tv-f , .all-country-flag-react .countrys-Code.tv-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/tuvalu-flag.svg');}
.all-country-flag .select2-result-sub li.tw-f, .allflag-show .selectedcountry.tw-f , .all-country-flag-react .countrys-Code.tw-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/taiwan-flag.svg');}
.all-country-flag .select2-result-sub li.tz-f, .allflag-show .selectedcountry.tz-f , .all-country-flag-react .countrys-Code.tz-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/tanzania-flag.svg');}
.all-country-flag .select2-result-sub li.ua-f, .allflag-show .selectedcountry.ua-f , .all-country-flag-react .countrys-Code.ua-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/ukraine-flag.svg');}
.all-country-flag .select2-result-sub li.ug-f, .allflag-show .selectedcountry.ug-f , .all-country-flag-react .countrys-Code.ug-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/uganda-flag.svg');}
.all-country-flag .select2-result-sub li.um-f, .allflag-show .selectedcountry.um-f , .all-country-flag-react .countrys-Code.um-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/us-outlying-islands-flag.svg');}
.all-country-flag .select2-result-sub li.vi-f, .allflag-show .selectedcountry.vi-f , .all-country-flag-react .countrys-Code.vi-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/virgin-Islands-us-flag.svg');}
.all-country-flag .select2-result-sub li.ae-f, .allflag-show .selectedcountry.ae-f , .all-country-flag-react .countrys-Code.ae-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/united-arab-emirates-flag.svg');}
.all-country-flag .select2-result-sub li.uy-f, .allflag-show .selectedcountry.uy-f , .all-country-flag-react .countrys-Code.uy-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/uruguay-flag.svg');}
.all-country-flag .select2-result-sub li.uz-f, .allflag-show .selectedcountry.uz-f , .all-country-flag-react .countrys-Code.uz-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/uzbekistan-flag.svg');}
.all-country-flag .select2-result-sub li.va-f, .allflag-show .selectedcountry.va-f , .all-country-flag-react .countrys-Code.va-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/vatican-city-state-flag.svg');}
.all-country-flag .select2-result-sub li.ve-f, .allflag-show .selectedcountry.ve-f , .all-country-flag-react .countrys-Code.ve-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/venezuela-flag.svg');}
.all-country-flag .select2-result-sub li.vn-f, .allflag-show .selectedcountry.vn-f , .all-country-flag-react .countrys-Code.vn-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/vietnam-flag.svg');}
.all-country-flag .select2-result-sub li.vu-f, .allflag-show .selectedcountry.vu-f , .all-country-flag-react .countrys-Code.vu-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/vanuatu-flag.svg');}
.all-country-flag .select2-result-sub li.wf-f, .allflag-show .selectedcountry.wf-f , .all-country-flag-react .countrys-Code.wf-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/wallis-futuna-flag.svg');}
.all-country-flag .select2-result-sub li.eh-f, .allflag-show .selectedcountry.eh-f , .all-country-flag-react .countrys-Code.eh-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/western-sahara-flag.svg');}
.all-country-flag .select2-result-sub li.ye-f, .allflag-show .selectedcountry.ye-f , .all-country-flag-react .countrys-Code.ye-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/yemen-flag.svg');}
.all-country-flag .select2-result-sub li.zm-f, .allflag-show .selectedcountry.zm-f , .all-country-flag-react .countrys-Code.zm-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/zambia-flag.svg');}
.all-country-flag .select2-result-sub li.zw-f, .allflag-show .selectedcountry.zw-f , .all-country-flag-react .countrys-Code.zw-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/zimbabwe-flag.svg');}
.all-country-flag .select2-result-sub li.vg-f, .allflag-show .selectedcountry.vg-f , .all-country-flag-react .countrys-Code.vg-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/virgin-islands-british-flag.svg');}
.all-country-flag .select2-result-sub li.cd-f, .allflag-show .selectedcountry.cd-f , .all-country-flag-react .countrys-Code.cd-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/democratic-republic-congo-flag.svg');}
.all-country-flag .select2-result-sub li.cg-f, .allflag-show .selectedcountry.cg-f , .all-country-flag-react .countrys-Code.cg-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/republic-congo-flag.svg');}
.all-country-flag .select2-result-sub li.ga-f, .allflag-show .selectedcountry.ga-f , .all-country-flag-react .countrys-Code.ga-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/gabon-flag.svg');}
.all-country-flag .select2-result-sub li.gg-f, .allflag-show .selectedcountry.gg-f , .all-country-flag-react .countrys-Code.gg-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/guernsey-flag.svg');}
.all-country-flag .select2-result-sub li.mk-f, .allflag-show .selectedcountry.mk-f , .all-country-flag-react .countrys-Code.mk-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/north-macedonia-flag.svg');}
.all-country-flag .select2-result-sub li.mo-f, .allflag-show .selectedcountry.mo-f , .all-country-flag-react .countrys-Code.mo-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/macao-flag.svg');}
.all-country-flag .select2-result-sub li.fm-f, .allflag-show .selectedcountry.fm-f , .all-country-flag-react .countrys-Code.fm-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/federated-states-micronesia-flag.svg');}
.all-country-flag .select2-result-sub li.nc-f, .allflag-show .selectedcountry.nc-f , .all-country-flag-react .countrys-Code.nc-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/new-caledonia-flag.svg');}
.all-country-flag .select2-result-sub li.kn-f, .allflag-show .selectedcountry.kn-f , .all-country-flag-react .countrys-Code.kn-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/saint-kitts-nevis-flag.svg');}
.all-country-flag .select2-result-sub li.lc-f, .allflag-show .selectedcountry.lc-f , .all-country-flag-react .countrys-Code.lc-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/saint-lucia-flag.svg');}
.all-country-flag .select2-result-sub li.sh-f, .allflag-show .selectedcountry.sh-f , .all-country-flag-react .countrys-Code.sh-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/saint-helena-flag.svg');}
.all-country-flag .select2-result-sub li.mf-f, .allflag-show .selectedcountry.mf-f , .all-country-flag-react .countrys-Code.mf-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/saint-martin-flag.svg');}
.all-country-flag .select2-result-sub li.bl-f, .allflag-show .selectedcountry.bl-f , .all-country-flag-react .countrys-Code.bl-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/saint-barthélemy.svg');}
.all-country-flag .select2-result-sub li.pm-f, .allflag-show .selectedcountry.pm-f , .all-country-flag-react .countrys-Code.pm-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/pierre-miquelon-flag.svg');}
.all-country-flag .select2-result-sub li.vc-f, .allflag-show .selectedcountry.vc-f , .all-country-flag-react .countrys-Code.vc-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/saint-vincent-grenadines-flag.svg');}
.all-country-flag .select2-result-sub li.bqb-f, .allflag-show .selectedcountry.bqb-f , .all-country-flag-react .countrys-Code.bqb-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/bonaire-flag.svg');}
.all-country-flag .select2-result-sub li.bqsa-f, .allflag-show .selectedcountry.bqsa-f , .all-country-flag-react .countrys-Code.bqsa-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/saba-flag.svg');}
.all-country-flag .select2-result-sub li.bqse-f, .allflag-show .selectedcountry.bqse-f , .all-country-flag-react .countrys-Code.bqse-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/sint-eustatius-flag.svg');}
.all-country-flag .select2-result-sub li.cn-f, .allflag-show .selectedcountry.cn-f , .all-country-flag-react .countrys-Code.cn-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/china-flag.svg');}
.all-country-flag .select2-result-sub li.cuc-f, .allflag-show .selectedcountry.cuc-f , .all-country-flag-react .countrys-Code.cuc-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/cuba-flag.svg');}
.all-country-flag .select2-result-sub li.gbn-f, .allflag-show .selectedcountry.gbn-f , .all-country-flag-react .countrys-Code.gbn-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/northern-ireland-flag.svg');}
.all-country-flag .select2-result-sub li.gbsct-f, .allflag-show .selectedcountry.gbsct-f , .all-country-flag-react .countrys-Code.gbsct-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/scotland-flag.svg');}
.all-country-flag .select2-result-sub li.gbwls-f, .allflag-show .selectedcountry.gbwls-f , .all-country-flag-react .countrys-Code.gbwls-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/wls-wales-flag.svg');}
.all-country-flag .select2-result-sub li.iri-f, .allflag-show .selectedcountry.iri-f , .all-country-flag-react .countrys-Code.iri-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/iran-flag.svg');}
.all-country-flag .select2-result-sub li.kpk-f, .allflag-show .selectedcountry.kpk-f , .all-country-flag-react .countrys-Code.kpk-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/korea-north-flag.svg');}
.all-country-flag .select2-result-sub li.sds-f, .allflag-show .selectedcountry.sds-f , .all-country-flag-react .countrys-Code.sds-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/sudan-flag.svg');}
.all-country-flag .select2-result-sub li.sys-f, .allflag-show .selectedcountry.sys-f , .all-country-flag-react .countrys-Code.sys-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/syria-flag.svg');}
.all-country-flag .select2-result-sub li.umu-f, .allflag-show .selectedcountry.umu-f , .all-country-flag-react .countrys-Code.umu-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/united-states-minor-outlying-islands-flag.svg');}
/*This Comment is for Bundle creation purpose only.*/
/*This Comment is for Bundle creation purpose only.*/


.react-addresscontrol .select2-container--default .select2-selection--single, .react-addresscontrol .select2-container--default .select2-selection--multiple { border: 0px!important; background: transparent!important; text-indent: 999px; }
.select2-dropdown { width: 330px!important;  } 
@media (max-width: 767px){
	.select2-dropdown { width: 330px!important;  }
}
.react-addresscontrol .select2-container--default .select2-selection--single:after, .react-addresscontrol .select2-container--default .select2-selection--multiple:after { display: none;}
.react-addresscontrol .addresswithcountry .countrydropdown:after{ font-weight: 900; font-family: "Font Awesome 5 Pro"!important;}

.all-country-flag-react ul.select2-results__options--nested > li .select2-result-label {
    margin: 0px;
    padding: 9px 11px 8px 36px !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.all-country-flag-react .select2-search::after{ font-family: "Font Awesome 5 Pro"!important; top: 28px;}
.all-country-flag-react {
    .countrys-Code { background-repeat: no-repeat; background-position: 8px 11px!important; }
    .select2-results { max-height: 285px!important;}
    .select2-result-sub li:hover {background: transparent!important;}
    .select2-results__option[aria-selected=true], .select2-results__option--highlighted[aria-selected] { background:transparent !important;}
    .select2-results__option { padding-left: 0px!important;}
    .select2-result-sub .select2-results__group .select2-result-label { padding-left: 0px!important;}
    .select2-results__option--highlighted .select2-result-label:hover { background-color: #f5f5f5!important;} 
    .select2-results__option[aria-selected=true] .select2-result-label {background-color: #f5f5f5!important;}
    /* .select2-result-sub .select2-results__group .select2-results__option:first-of-type:hover { background:#fff!important;} */
    /* .all-country-flag-react .countrys-Code.us-f {background-image: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/world-flags/united-states-flag.svg');} */

}

