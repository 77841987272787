.flexRow {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.mobileCenterOthersLeft {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 0 auto;
}

.profileImage {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-webkit-transition: -webkit-transform .4s;
	transition: -webkit-transform .4s;
	-o-transition: transform .4s;
	transition: transform .4s;
	transition: transform .4s, -webkit-transform .4s;
	width: 400px;
	height: 400px;
}

@media (min-width: 320px) {
	.profileImage{
		width: 200px;
		height:200px;
		margin: auto;
	}
	
}

@container (min-width: 320px) {
	.profileImage{
		width: 200px;
		height:200px;
		margin: auto;
	}
}

/* Desktop: 3 columns */
@container (min-width: 1024px) {
	.profileImage{
		width: 400px;
		height:400px;
		margin: auto;
	}
}

.staffProfileImage {
  width: 264px;
  height: 352px;
  object-fit: cover;
}

.initialPlaceholderWrapper {
  width: 400px;
	height: 600px;
  background-color: #ababab;
  display: flex;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
}
.staffInitialPlaceholderImage {
  width: 100%;
  height: 352px;
  object-fit: cover;
  width: 100px; /* Adjust the width and height as needed */
  height: 100px; /* These values should be the same for a perfect circle */
  border: 1px solid #cdcdcd;
  color: #333;
  background-color: #fff;
  border-radius: 50%;
  font-size: 2.5em;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center; /* Vertically center content */
}

@media (min-width: 320px) {
	.initialPlaceholderWrapper {
		width: 100%;
		height: 300px;
		background-color: #ababab;
		display: flex;
		justify-content: center; /* Center content vertically */
		align-items: center; /* Center content horizontally */
	}
}

@media (min-width: 600px) {
	.initialPlaceholderWrapper {
		width: 100%;
		height: 600px;
		background-color: #ababab;
		display: flex;
		justify-content: center; /* Center content vertically */
		align-items: center; /* Center content horizontally */
	}
}

/* Desktop: 3 columns */
@media (min-width: 1024px) {
	.initialPlaceholderWrapper {
		width: 100%;
		height: 600px;
		background-color: #ababab;
		display: flex;
		justify-content: center; /* Center content vertically */
		align-items: center; /* Center content horizontally */
	}
}


@container (min-width: 320px) {
	.initialPlaceholderWrapper {
		width: 100%;
		height: 420px;
		background-color: #ababab;
		display: flex;
		justify-content: center; /* Center content vertically */
		align-items: center; /* Center content horizontally */
	}
}

@container (min-width: 600px) {
	.initialPlaceholderWrapper {
		width: 320px;
		height: 520px;
		background-color: #ababab;
		display: flex;
		justify-content: center; /* Center content vertically */
		align-items: center; /* Center content horizontally */
	}
}

/* Desktop: 3 columns */
@container (min-width: 1024px) {
	.initialPlaceholderWrapper {
		width: 400px;
		height: 600px;
		background-color: #ababab;
		display: flex;

		justify-content: center; /* Center content vertically */
		align-items: center; /* Center content horizontally */
	}
}

.profile-card-relative {
	position: relative; /* Set the card to relative positioning */
	/* Other styling for the card (width, height, padding, etc.) */
	padding-bottom: 60px; /* Add padding to prevent content overlap with the button */
}

.buttons-at-bottom {
	position: absolute; /* Absolute positioning for the button */
	bottom: 0px; /* Distance from the bottom of the card */
	left: 50%; /* Center the button horizontally */
	transform: translateX(-50%); /* Adjust for exact centering */
	/* Additional styling for the button */
}


.alternating-card {
	/* Style your grid items */
	height: screen;
	border-width: 1px; /* Width of the border */
	border-style: solid; /* Style of the border */
	border-color: #ccc; /* Color of the border */
 /* Background color of the card */
}

/* Styles for all .alternating-card-text-wrapper elements */

.alternating-card-text-wrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;
	font-weight: bold;
	color: #000000;
	align-items: center; /* Default alignment */
	justify-content: center;
	width: 100%;
	padding: 64px;
	
}

.profileAltRow{
	display: flex;
	padding-top: 16px;
	padding-bottom: 16px;
	gap: 4px; /* Adjust gap as needed */
	/* Adjust padding as needed */
	padding-left: 4px;
	padding-right: 4px;
}

@container (min-width: 320px) {
	.profileAltRow {
		display: flex;
		flex-direction: column;
	}
}

@container (min-width: 600px) {
	.profileAltRow {
		grid-template-columns: repeat(2, 1fr);
	}
}

/* Desktop: 3 columns */
@container (min-width: 1024px) {
	.profileAltRow {
		grid-template-columns: repeat(3, 1fr);
	}
}


/* Styles for odd .alternating-card-text-wrapper elements */

.profile-alt-row:nth-child(even) .alternating-card-text-wrapper {
	/* Center alignment */
	display: flex;
	flex-direction: column;
	gap: 16px;
	font-weight: bold;
	color: #000000;
	align-items: center; /* Default alignment */
	justify-content: center;
	width: 100%;
	padding: 64px;
	 
}

/* Styles for even .alternating-card-text-wrapper elements */
.profile-alt-row:nth-child(odd) .alternating-card-text-wrapper {
	/* Left alignment */
	display: flex;
	flex-direction: column;
	gap: 16px;
	font-weight: bold;
	color: #000000;
	align-items: flex-start; 
	justify-content: flex-start;
	width: 100%;
	padding: 64px;

	 
}

/* Additional styles for even .alternating-card-text-wrapper elements */
.alternating-card-text-wrapper:nth-child(even) {
	/* Add your specific styles for even elements here */
	/* Example: change the background color */
	background-color: #000;
	/* Other styles */
}


.flex-row-normal {
  flex-direction: row;
	flex-basis: 100%;
	width: 100%;
	justify-content: space-between;
}

.flex-row-reverse {
  flex-direction: row-reverse;
	justify-content: space-between;
	flex-basis: 100%;
}

.alternating-card:nth-child(odd) {
	background-color: #f2f2f2;
		
}

.profile-alt-row {
	display: flex;
	padding-top: 16px;
	padding-bottom: 16px;
	gap: 4px; /* Adjust gap as needed */
	/* Adjust padding as needed */
	padding-left: 4px;
	padding-right: 4px;
}


@container (min-width: 600px) {
	.profile-alt-row {
		grid-template-columns: repeat(2, 1fr);
	}
}

/* Desktop: 3 columns */
@container (min-width: 1024px) {
	.profile-alt-row {
		grid-template-columns: repeat(3, 1fr);
	}
}


.profileImage {
	background-size: cover !important;
	background-position: center;
	background-repeat: no-repeat;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-webkit-transition: -webkit-transform 0.4s;
	transition: -webkit-transform 0.4s;
	-o-transition: transform 0.4s;
	transition: transform 0.4s;
	transition: transform 0.4s, -webkit-transform 0.4s;
	width: 400px;
	height: 600px;
}


/*******************************
 * Container and media query section.
 *******************************/


/* Mobile */
@media (min-width: 320px) {

	.profileImage {
		margin: 0 auto;
		background-size: cover !important;
		background-position: center;
		background-repeat: no-repeat;
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
		-webkit-transition: -webkit-transform 0.4s;
		transition: -webkit-transform 0.4s;
		-o-transition: transform 0.4s;
		transition: transform 0.4s;
		transition: transform 0.4s, -webkit-transform 0.4s;
		width: 320px;
		height: 520px;
	}

	.profile-alt-row {
		display: flex;
		flex-direction: column;
	}

	.mobileCenterOthersLeft {
		display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 0 auto;
	}
	
}
/* Tablet */
@media(min-width: 600px) {
	.mobileCenterOthersLeft {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		margin: 0 left;
		}
}

/* Desktop */
@media (min-width: 1024px) {

	.profileImage {
		width: 400px;
		height: 600px;
		margin: auto;
		background-size: cover !important;
		background-position: center;
		background-repeat: no-repeat;
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
		-webkit-transition: -webkit-transform 0.4s;
		transition: -webkit-transform 0.4s;
		-o-transition: transform 0.4s;
		transition: transform 0.4s;
		transition: transform 0.4s, -webkit-transform 0.4s;
	}

	.mobileCenterOthersLeft {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		margin: 0 left;
		}
}

/* Mobile */
@container (min-width: 320px) {

	.profileImage {
		margin: 0 auto;
		background-size: cover !important;
		background-position: center;
		background-repeat: no-repeat;
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
		-webkit-transition: -webkit-transform 0.4s;
		transition: -webkit-transform 0.4s;
		-o-transition: transform 0.4s;
		transition: transform 0.4s;
		transition: transform 0.4s, -webkit-transform 0.4s;
		width: 320px;
		height: 520px;
	}

	.profile-alt-row {
		display: flex;
		flex-direction: column;
	}

	.mobileCenterOthersLeft {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 0 auto;
	}
}
/* Tablet */
@container (min-width: 600px) {
	.mobileCenterOthersLeft {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		margin: 0 left;
		}
}

/* Desktop */
@container (min-width: 1024px) {

	.profileImage {
		width: 400px;
		height: 600px;
		margin: auto;
		background-size: cover !important;
		background-position: center;
		background-repeat: no-repeat;
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
		-webkit-transition: -webkit-transform 0.4s;
		transition: -webkit-transform 0.4s;
		-o-transition: transform 0.4s;
		transition: transform 0.4s;
		transition: transform 0.4s, -webkit-transform 0.4s;
	}

	.mobileCenterOthersLeft {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		margin: 0 left;
		}
}
