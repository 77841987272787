:root {
  --global-font-family-proximanova:"proxima-nova",sans-serif;
  --global-font-proximanova: "proxima-nova",sans-serif;
  --global-radius-regular: 3px;
  --global-radius-larger: 5px;
  --global-radius-full: 100px;
  --global-radius-input: 3px;
  --global-radius-table: 5px;
  --global-radius-button-default: 3px;
  --global-radius-button-full: 100px;
  --global-color-core-ink-lightest: #f8f8f8;
  --global-color-core-ink-lighter: #e7e7e7;
  --global-color-core-ink-light: #cdcdcd;
  --global-color-core-ink-base: #9a9a9a;
  --global-color-core-ink-dark: #6d6e6f;
  --global-color-core-ink-darker: #333333;
  --global-color-core-white: #ffffff;
  --global-color-core-black: #000000;
  --global-color-core-flamingo-default: #CC4744;
  --global-color-core-flamingo-dark: #b43136;
  --global-color-core-flamingo-darker: #942b28;
  --global-color-core-alert-lighter: #fbefef;
  --global-color-core-alert-light: #f5d9da;
  --global-color-core-alert-default: #b42318;
  --global-color-core-alert-warning: #ff9500;
  --global-color-core-alert-yellow: #f6d645;
  --global-color-core-green-default: #3e8438;
  --global-color-core-green-dark: #3f6d3b;
  --global-color-core-green-darker: #365c33;
  --global-color-core-blue-lighter: #f4faff;
  --global-color-core-blue-light: #e9f5ff;
  --global-color-core-blue-default: #2479c7;
  --global-color-core-blue-dark: #26639c;
  --global-color-core-overlay-01: #26639c;
  --global-sizing-1: 1px;
  --global-sizing-2: 2px;
  --global-sizing-4: 4px;
  --global-sizing-8: 8px;
  --global-sizing-12: 12px;
  --global-sizing-16: 16px;
  --global-sizing-20: 20px;
  --global-sizing-24: 24px;
  --global-sizing-28: 28px;
  --global-sizing-32: 32px;
  --global-sizing-36: 36px;
  --global-sizing-40: 40px;
  --global-sizing-44: 44px;
  --global-sizing-48: 48px;
  --global-sizing-56: 56px;
  --global-sizing-64: 64px;
  --global-sizing-72: 72px;
  --global-sizing-80: 80px;
  --global-sizing-96: 96px;
  --global-sizing-120: 120px;
  --global-sizing-160: 160px;
  --global-sizing-240: 240px;
  --global-sizing-320: 320px;
  --global-sizing-auto: auto;
  --global-sizing-none: 0px;
  --global-spacing-1: 1px;
  --global-spacing-2: 2px;
  --global-spacing-4: 4px;
  --global-spacing-8: 8px;
  --global-spacing-12: 12px;
  --global-spacing-16: 16px;
  --global-spacing-20: 20px;
  --global-spacing-24: 24px;
  --global-spacing-28: 28px;
  --global-spacing-32: 32px;
  --global-spacing-36: 36px;
  --global-spacing-40: 40px;
  --global-spacing-48: 48px;
  --global-spacing-56: 56px;
  --global-spacing-64: 64px;
  --global-spacing-72: 72px;
  --global-spacing-80: 80px;
  --global-spacing-96: 96px;
  --global-spacing-120: 120px;
  --global-spacing-160: 160px;
  --global-spacing-240: 240px;
  --global-spacing-320: 320px;
  --global-spacing-auto: auto;
  --global-spacing-none: 0px;
  --global-border-width-default: 1px;
  --global-border-width-strong: 2px;
  --global-border-style-none: none;
  --global-border-style-solid: solid;
  --global-border-style-dashed: dashed;
  --global-shadow-1: 0px 2px 10px 0px rgba(0,0,0,0.10);
  --global-shadow-2: 0px 2px 20px 0px rgba(0,0,0,0.15);
  --global-shadow-3-web: 0px 8px 40px 0px rgba(0,0,0,0.20);
  --global-shadow-3-mobile: 0px -8px 40px 0px rgba(0,0,0,0.20);
  --global-font-spacing-none: 0;
  --global-font-weight-regular: 400;
  --global-font-weight-semibold: 600;
  --global-font-size-12: 12px;
  --global-font-size-16: 16px;
  --global-font-size-18: 18px;
  --global-font-size-20: 20px;
  --global-font-size-24: 24px;
  --global-font-size-32: 32px;
  --global-font-height-16: 16px;
  --global-font-height-20: 20px;
  --global-font-height-24: 24px;
  --global-font-height-28: 28px;
  --global-font-height-36: 36px;
  --global-font-decoration-none: none;
  --global-font-decoration-underline: underline;
  --global-font-decoration-strikethrough: line-through;
  --global-paragraph-spacing-none: 0;
  --global-opacity-none: 0%;
  --global-opacity-10: 10%;
  --global-opacity-15: 15%;
  --global-opacity-20: 20%;
  --global-opacity-25: 25%;
  --global-opacity-50: 50%;
}
:root {  --color-text-neutral-default: var(--global-color-core-ink-darker);
  --color-text-neutral-secondary: var(--global-color-core-ink-dark);
  --color-text-neutral-weak: var(--global-color-core-ink-base);
  --color-text-neutral-inverse: var(--global-color-core-white);
  --color-text-alert-default: var(--global-color-core-alert-default);
  --color-text-blue-default: var(--global-color-core-blue-default);
  --color-text-blue-strong: var(--global-color-core-blue-dark);
  --color-icon-neutral-default: var(--global-color-core-ink-darker);
  --color-icon-neutral-secondary: var(--global-color-core-ink-dark);
  --color-icon-neutral-weak: var(--global-color-core-ink-base);
  --color-icon-neutral-inverse: var(--global-color-core-white);
  --color-icon-alert-default: var(--global-color-core-alert-default);
  --color-icon-blue-default: var(--global-color-core-blue-default);
  --color-icon-blue-strong: var(--global-color-core-blue-dark);
  --color-bkg-neutral-default: var(--global-color-core-white);
  --color-bkg-neutral-medium: var(--global-color-core-ink-lightest);
  --color-bkg-neutral-strong: var(--global-color-core-ink-darker);
  --color-bkg-neutral-hover: var(--global-color-core-ink-lightest);
  --color-bkg-neutral-pressed: var(--global-color-core-ink-lighter);
  --color-bkg-theme-default: var(--global-color-core-flamingo-default);
  --color-bkg-theme-hover: var(--global-color-core-flamingo-dark);
  --color-bkg-theme-pressed: var(--global-color-core-flamingo-darker);
  --color-bkg-green-default: var(--global-color-core-green-default);
  --color-bkg-green-medium: var(--global-color-core-green-dark);
  --color-bkg-green-hover: var(--global-color-core-green-dark);
  --color-bkg-green-pressed: var(--global-color-core-green-darker);
  --color-bkg-blue-default: var(--global-color-core-blue-default);
  --color-bkg-blue-weak: var(--global-color-core-blue-lighter);
  --color-bkg-blue-hover: var(--global-color-core-blue-lighter);
  --color-bkg-blue-pressed: var(--global-color-core-blue-light);
  --color-bkg-alert-default: var(--global-color-core-alert-default);
  --color-bkg-alert-hover: var(--global-color-core-alert-lighter);
  --color-bkg-alert-pressed: var(--global-color-core-alert-light);
  --color-bkg-alert-yellow: var(--global-color-core-alert-yellow);
  --color-bkg-alert-warning: var(--global-color-core-alert-warning);
  --color-border-neutral-default: var(--global-color-core-ink-light);
  --color-border-neutral-hover: var(--global-color-core-ink-base);
  --color-border-theme-default: var(--global-color-core-flamingo-default);
  --color-border-green-default: var(--global-color-core-green-default);
  --color-border-alert-default: var(--global-color-core-alert-default);
  --color-border-blue-default: var(--global-color-core-blue-default);
  --web-display-large-font-family: var(--global-font-family-proximanova);
  --web-display-large-font-weight: var(--global-font-weight-semibold);
  --web-display-large-line-height: var(--global-font-height-36);
  --web-display-large-font-size: var(--global-font-size-32);
  --web-display-large-letter-spacing: 0%;
  --web-display-large-text-decoration: none;
  --web-display-large-text-transform: none;
  --web-display-medium-strong-font-family: var(--global-font-family-proximanova);
  --web-display-medium-strong-font-weight: var(--global-font-weight-semibold);
  --web-display-medium-strong-line-height: var(--global-font-height-28);
  --web-display-medium-strong-font-size: var(--global-font-size-24);
  --web-display-medium-strong-letter-spacing: 0%;
  --web-display-medium-strong-text-decoration: none;
  --web-display-medium-strong-text-transform: none;
  --web-display-medium-default-font-family: var(--global-font-family-proximanova);
  --web-display-medium-default-font-weight: var(--global-font-weight-regular);
  --web-display-medium-default-line-height: var(--global-font-height-28);
  --web-display-medium-default-font-size: var(--global-font-size-24);
  --web-display-medium-default-letter-spacing: 0%;
  --web-display-medium-default-text-decoration: none;
  --web-display-medium-default-text-transform: none;
  --web-display-small-font-family: var(--global-font-family-proximanova);
  --web-display-small-font-weight: var(--global-font-weight-semibold);
  --web-display-small-line-height: var(--global-font-height-24);
  --web-display-small-font-size: var(--global-font-size-20);
  --web-display-small-letter-spacing: 0%;
  --web-display-small-text-decoration: none;
  --web-display-small-text-transform: none;
  --web-button-font-family: var(--global-font-family-proximanova);
  --web-button-font-weight: var(--global-font-weight-regular);
  --web-button-line-height: var(--global-font-height-20);
  --web-button-font-size: var(--global-font-size-16);
  --web-button-letter-spacing: 0%;
  --web-button-text-decoration: none;
  --web-button-text-transform: none;
  --web-subheading-font-family: var(--global-font-family-proximanova);
  --web-subheading-font-weight: var(--global-font-weight-semibold);
  --web-subheading-line-height: var(--global-font-height-20);
  --web-subheading-font-size: var(--global-font-size-16);
  --web-subheading-letter-spacing: 0%;
  --web-subheading-text-decoration: none;
  --web-subheading-text-transform: none;
  --web-body-font-family: var(--global-font-family-proximanova);
  --web-body-font-weight: var(--global-font-weight-regular);
  --web-body-line-height: var(--global-font-height-20);
  --web-body-font-size: var(--global-font-size-16);
  --web-body-letter-spacing: 0%;
  --web-body-text-decoration: none;
  --web-body-text-transform: none;
  --web-caption-strong-font-family: var(--global-font-family-proximanova);
  --web-caption-strong-font-weight: var(--global-font-weight-semibold);
  --web-caption-strong-line-height: var(--global-font-height-16);
  --web-caption-strong-font-size: var(--global-font-size-12);
  --web-caption-strong-letter-spacing: 0%;
  --web-caption-strong-text-decoration: none;
  --web-caption-strong-text-transform: none;
  --web-caption-font-family: var(--global-font-family-proximanova);
  --web-caption-font-weight: var(--global-font-weight-regular);
  --web-caption-line-height: var(--global-font-height-16);
  --web-caption-font-size: var(--global-font-size-12);
  --web-caption-letter-spacing: 0%;
  --web-caption-text-decoration: none;
  --web-caption-text-transform: none;
  --mobile-display-large-font-family: var(--global-font-family-proximanova);
  --mobile-display-large-font-weight: var(--global-font-weight-semibold);
  --mobile-display-large-line-height: var(--global-font-height-36);
  --mobile-display-large-font-size: var(--global-font-size-32);
  --mobile-display-large-letter-spacing: 0%;
  --mobile-display-large-text-decoration: none;
  --mobile-display-large-text-transform: none;
  --mobile-display-medium-strong-font-family: var(--global-font-family-proximanova);
  --mobile-display-medium-strong-font-weight: var(--global-font-weight-semibold);
  --mobile-display-medium-strong-line-height: var(--global-font-height-28);
  --mobile-display-medium-strong-font-size: var(--global-font-size-24);
  --mobile-display-medium-strong-letter-spacing: 0%;
  --mobile-display-medium-strong-text-decoration: none;
  --mobile-display-medium-strong-text-transform: none;
  --mobile-display-medium-default-font-family: var(--global-font-family-proximanova);
  --mobile-display-medium-default-font-weight: var(--global-font-weight-regular);
  --mobile-display-medium-default-line-height: var(--global-font-height-28);
  --mobile-display-medium-default-font-size: var(--global-font-size-24);
  --mobile-display-medium-default-letter-spacing: 0%;
  --mobile-display-medium-default-text-decoration: none;
  --mobile-display-medium-default-text-transform: none;
  --mobile-display-small-font-family: var(--global-font-family-proximanova);
  --mobile-display-small-font-weight: var(--global-font-weight-semibold);
  --mobile-display-small-line-height: var(--global-font-height-24);
  --mobile-display-small-font-size: var(--global-font-size-20);
  --mobile-display-small-letter-spacing: 0%;
  --mobile-display-small-text-decoration: none;
  --mobile-display-small-text-transform: none;
  --mobile-button-font-family: var(--global-font-family-proximanova);
  --mobile-button-font-weight: var(--global-font-weight-regular);
  --mobile-button-line-height: var(--global-font-height-20);
  --mobile-button-font-size: var(--global-font-size-20);
  --mobile-button-letter-spacing: 0%;
  --mobile-button-text-decoration: none;
  --mobile-button-text-transform: none;
  --mobile-subheading-font-family: var(--global-font-family-proximanova);
  --mobile-subheading-font-weight: var(--global-font-weight-semibold);
  --mobile-subheading-line-height: var(--global-font-height-20);
  --mobile-subheading-font-size: var(--global-font-size-16);
  --mobile-subheading-letter-spacing: 0%;
  --mobile-subheading-text-decoration: none;
  --mobile-subheading-text-transform: none;
  --mobile-body-font-family: var(--global-font-family-proximanova);
  --mobile-body-font-weight: var(--global-font-weight-regular);
  --mobile-body-line-height: var(--global-font-height-20);
  --mobile-body-font-size: var(--global-font-size-16);
  --mobile-body-letter-spacing: 0%;
  --mobile-body-text-decoration: none;
  --mobile-body-text-transform: none;
  --mobile-caption-strong-font-family: var(--global-font-family-proximanova);
  --mobile-caption-strong-font-weight: var(--global-font-weight-semibold);
  --mobile-caption-strong-line-height: var(--global-font-height-16);
  --mobile-caption-strong-font-size: var(--global-font-size-12);
  --mobile-caption-strong-letter-spacing: 0%;
  --mobile-caption-strong-text-decoration: none;
  --mobile-caption-strong-text-transform: none;
  --mobile-caption-font-family: var(--global-font-family-proximanova);
  --mobile-caption-font-weight: var(--global-font-weight-regular);
  --mobile-caption-line-height: var(--global-font-height-16);
  --mobile-caption-font-size: var(--global-font-size-12);
  --mobile-caption-letter-spacing: 0%;
  --mobile-caption-text-decoration: none;
  --mobile-caption-text-transform: none;
}

