.vgui-addonservices{max-width: 786px;
                    // *{font-family:"proxima-nova",sans-serif}
    .modal-content {
        .modal-header{
            z-index: 3;}
        .modal-body{font-size: 16px;}
        .modal-footer{
            .totalpricefooter{display: flex; flex-direction: column; padding: 0; margin: 0; padding-right: 15px; line-height: 1;}
            .totalprice{font-weight: 600; padding-top: 3px;}
        }
    }
    .page-title{padding-top: 30px; padding-bottom: 0px;font-family: var(--wbHeadFontFamily);}
}
.addon-servies-head{display: flex;
    .sericeimg{width: 120px; height: 150px; padding-right: 20px;}
    .vg-addon-service-description{flex: 1;}
    .vg-addon-service-img-box{width: 100%; height: 100%; background-repeat: no-repeat; background-position: center; background-size: cover; border-radius: 3px;}
    .servicename{font-size: 32px; font-weight: 600; line-height: 36px; margin-bottom: 8px; display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;overflow: hidden; font-family: var(--wbHeadFontFamily);}

    .services-description{padding-top: 16px;}
    .actual-price{color: rgba(51, 51, 51, 0.5);text-decoration: line-through;}
}



.addon-pack{padding-top: 25px;}
.pack-title{
    display: flex; align-items: flex-start;
    .pack-name{flex: 1; font-weight: 600; font-size: 20px; line-height: 24px;
        .choos-text{font-weight: 400; font-size: 16px; line-height: 20px;}
        .service-addon-title {font-family: var(--wbHeadFontFamily);}
    }
}

.pack-badge{
    .badge{border-color: #cdcdcd; padding: 4px 8px; line-height: 16px; font-weight: 600; font-size: 12px; border-radius: 3px; background: #fff;
           &.required-badge{background-color: var(--global-color-core-alert-default);color: var(--color-text-neutral-inverse);border-color: var(--global-color-core-alert-default)}
    }
    
}
.vg-status-deprecated-message{background-color: #fbefef;border-style: solid;border-width: 1px;border-color: #b42318;font-size: 16px;line-height: 16px;
    padding: 8px;border-radius: 5px;color: #333;display: inline-block;border-color: var(--color-border-alert-default);
    background-color: var(--color-bkg-alert-hover); margin-top:12px}
.pack-item{ display: flex; align-items: center; padding-top: 15px; padding-bottom: 15px; 
    border-bottom: 1px solid rgb(231, 231, 231);
    .pack-item-check-col{width: 36px;}
    .pack-item-img-col{width:46px; height: 68px;margin-right: 10px;border-radius: 3px;background-position: center;background-size: cover;background-repeat: no-repeat; }

    .pack-item-info-col{flex: 1; padding-right: 15px;
        h3, p{padding: 0; margin: 0;}
        h3{font-size: 16px; line-height: 20px; font-weight: 600;font-family: var(--wbHeadFontFamily); color: #333;}
        p{font-family: var(--wbHeadBodyFamily);}
    }

    .pack-item-price-col{
        font-weight: 600;
        .oldprice{color: rgba(51, 51, 51, 0.5);text-decoration: line-through; font-weight: 400;}
    }
}