.flexRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	
}

.profile-card-relative {
	position: relative; /* Set the card to relative positioning */
	/* Other styling for the card (width, height, padding, etc.) */
	padding-bottom: 60px; /* Add padding to prevent content overlap with the button */
}

.buttons-at-bottom {
	position: absolute; /* Absolute positioning for the button */
	bottom: 0px; /* Distance from the bottom of the card */
	left: 50%; /* Center the button horizontally */
	transform: translateX(-50%); /* Adjust for exact centering */
	/* Additional styling for the button */
}

.alternating-card {
	/* Style your grid items */
	height: screen;
	border-width: 1px; /* Width of the border */
	border-style: solid; /* Style of the border */
	border-color: #ccc; /* Color of the border */
	
	/* Background color of the card */
}

/* Styles for all .alternating-card-text-wrapper elements */

.alternating-card-text-wrapper {
	display: flex;
	flex-direction: column;
	gap: 20.96px; /* 32px / φ ≈ 20.96px */
	font-weight: bold;
	color: #000000;
	align-items: left; /* Default alignment */
	justify-content: center;
	width: 100%;
	margin-top: 64px;
}

/* Styles for odd .alternating-card-text-wrapper elements */

.profile-alt-row:nth-child(even) .alternating-card-text-wrapper {
	/* Center alignment */
	display: flex;
	flex-direction: column;
	gap: 16px;
	font-weight: bold;
	color: #000000;
	align-items: center; /* Default alignment */
	justify-content: center;
	width: 100%;
	padding: 64px;
	margin-top: 64px;
}

/* Styles for even .alternating-card-text-wrapper elements */
.profile-alt-row:nth-child(odd) .alternating-card-text-wrapper {
	/* Left alignment */
	display: flex;
	flex-direction: column;
	gap: 16px;
	font-weight: bold;
	color: #000000;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	padding: 64px;
	margin-top: 48px;
}

/* Additional styles for even .alternating-card-text-wrapper elements */
.alternating-card-text-wrapper:nth-child(even) {
	/* Add your specific styles for even elements here */
	/* Example: change the background color */
	background-color: #000;
	/* Other styles */
}

@container (min-width: 320px) {
	.alternating-card-text-wrapper {
		padding: 4.94px; /* 8px / φ ≈ 4.94px */
	}
}

@media (min-width: 600px) {
	.alternating-card-text-wrapper {
		padding: 9.88px; /* 16px / φ ≈ 9.88px */
		
	}
}

@container (min-width: 600px) {
	.alternating-card-text-wrapper {
		padding: 9.88px; /* 16px / φ ≈ 9.88px */
		
	}
}

/* Desktop: 3 columns */
@container (min-width: 1024px) {
	.alternating-card-text-wrapper {
		padding: 24.64px; /* 38.16px / φ ≈ 24.64px */
	}
}

.flex-row-normal {
	flex-direction: row;
	flex-basis: 100%;
	width: 100%;
	justify-content: space-between;
}

.flex-row-reverse {
	flex-direction: row-reverse;
	justify-content: space-between;
	flex-basis: 100%;
}

.alternating-card:nth-child(odd) {
	background-color: #f2f2f2;
}

.profile-alt-row {
	display: flex;
	padding-top: 16px;
	padding-bottom: 16px;
	gap: 4px;
	padding-left: 4px;
	padding-right: 4px;
}

@container (min-width: 320px) {
	.profile-alt-row {
		display: flex;
		flex-direction: column;
	}
}

@container (min-width: 600px) {
	.profile-alt-row {
		grid-template-columns: repeat(2, 1fr);
	}
}

/* Desktop: 3 columns */
@container (min-width: 1024px) {
	.profile-alt-row {
		grid-template-columns: repeat(3, 1fr);
	}
}

/* Adjust card ordering with flexbox */

@media (min-width: 320px) {
	/* .flex-row-to-column {
		display: flex;
		flex-direction: column;
	} */

	.alternating-card-image-wrapper {
		order: 1;
	}

	.alt-flex-item {
		order: 2;
	}
}

@media (min-width: 600px) {
	/* .flex-row-to-column {
		display: flex;
		flex-direction: column;
	} */

	.alternating-card-image-wrapper {
		order: 1;
	}

	.alt-flex-item {
		order: 2;
	}
}

/* Desktop: 3 columns */
@media(min-width: 1024px) {
	/* .flex-row-to-column {
		display: flex;
		flex-direction: column;
	} */

	.alternating-card-image-wrapper {
		order: 1;
		padding: 8px;
	}

	.alt-flex-item {
		order: 2;
	}
}


@container (min-width: 320px) {
	/* .flex-row-to-column {
		display: flex;
		flex-direction: column;
	} */

	.alternating-card-image-wrapper {
		order: 1;
	}

	.alt-flex-item {
		order: 2;
	}
}

@container (min-width: 600px) {
	/* .flex-row-to-column {
		display: flex;
		flex-direction: column;
	} */

	.alternating-card-image-wrapper {
		order: 1;
	}

	.alt-flex-item {
		order: 2;
	}
}

/* Desktop: 3 columns */
@container (min-width: 1024px) {
	/* .flex-row-to-column {
		display: flex;
		flex-direction: column;
	} */

	.alternating-card-image-wrapper {
		order: 1;
		padding: 8px;
	}

	.alt-flex-item {
		order: 2;
	}
}

/* Ends card ordering with flexbox */