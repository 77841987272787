.vgui-send-email{max-width: 678px;
    .modal-footer{
        .v-button{min-width: 95px;}
    }
}

.vgn-popup{
    z-index: 10000;
}
.senemailfrom{display: flex;
@media (max-width: 767px){
    display: block;
}
}
.businesscover{max-width: 200px; margin-right: 38px;
@media (max-width: 767px){
    max-width: 300px;
    width: 100%;
    margin: 0 auto 20px;
}
}
.cover-business-name{background-color: #CC4744; color: #fff; border-radius: 5px 5px 0 0; padding: 8px 10px 8px 10px;
    .bnme{line-height: 24px; font-size: 20px; font-weight: bold;}
    .ssbname{font-size: 14px; line-height: 18px;}
    .bnme.text-center{font-size: 16px; font-weight: normal; line-height: 20px;}
}
.cover-business-image{
    img{
        max-width: 100%;
    }
}

.sendemailform{width: 100%;}
.astrik{color: #b42318;}
.horizontal-vg-form{
    .form-row{display: flex;
    @media (max-width: 767px){
        display: block;
    }
    }
    .form-label{width: 100%; max-width: 110px; padding-right: 5px;
    @media (max-width: 767px){
        padding-bottom: 7px;
        max-width: 100%;
        width: 100%;
    }
    }
    .from-control-wrapper{width: 100%;}
}

.cover-business-image{
    position: relative;
    .deal-listing-discount.badge-button-discount{position: absolute; bottom: 0; right: 0;}
}

.sendemailform{
    .form-row textarea{height: auto;}
    .form-row + .form-row{margin-top: 15px;}

    
}

.businesscover{
    .photo-details{padding-top: 10px;
        .b-detail-row{display: flex; justify-content: space-between;}
        .btdbold{font-weight: bold;}
    }
}

