.vg-write-review-wrp{
    display: flex; padding-top: 8px;
    @media (max-width: 991px){
        flex-flow: column; 
     }
 

    .left{width: 35%; display: flex; padding-right: 30px; flex-direction: column;
        @media (max-width:991px) {
            width: 100%;
        }
    }
    .right{flex: 1;}


.business-image{padding-bottom: 10px;
    img{border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 3px; max-width: 100%;
        @media (max-width: 991px){
            width: 100%;
            max-width: 440px;
            margin: 0 auto 10px;
            display: block;
         }
         @media (max-width: 767px){
            display: none;
         }
    }
}
.review-business-name{font-weight: 600; font-size: 20px; line-height: 24px; color: #333;}
.review-locations{font-weight: 400; font-size: 16px; line-height: 20px; color: #333;}

.review-userImg{width: 100%; max-width: 36px; height: 36px;margin-right: 10px;border-radius: 100%;background-size: cover;background-color: #cdcdcd;display: flex;justify-content: center;align-items: center;}
.vagaro-avatar-md{width: 36px!important; height: 36px!important;
    .vg-avatar-inital{width: 36px!important; height: 36px!important;
        .vg-avatar-inital-inner-text{line-height: 36px!important;}
    }
}

.reviewuser{display: flex;width: 100%;margin-top: 15px;margin-bottom: 15px;padding-top: 15px;align-items: center;border-top: 1px solid rgba(0, 0, 0, 0.15);font-size: 16px; padding-bottom: 15px; border-bottom: 1px solid rgba(0, 0, 0, 0.15);}
.review-username{font-size: 16px;line-height: 20px;width: calc(100% - 36px);word-break: break-word;word-wrap: break-word;}



.form-row {
    .drop-file-upload{ height: 150px; text-align: center; width: 150px; border-radius: 3px; color: #333; gap: 8px;
        i{font-size: 24px; line-height: 24px; color: #333;}
        .upload-trigger{letter-spacing: 1px; color: #2479c7;}
        .upload-note{font-size: 12px; max-width: 113px; padding-top: 10px; line-height: 16px; padding: 0;}

        &:hover{border-color: #9a9a9a;}
        .showdelete.delete-option-horizontal{
            i{
                color: #FFF;
            }
            .moremenu .more-3dot:hover{
                background-color: transparent;
            }
        }
    }
    
    &.form-horizontal-row{
        display: flex; align-items: center; padding-top: 20px; padding-bottom: 20px; border-top: 1px solid rgba(0, 0, 0, 0.1); border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        .form-label{flex: 1;}
        .sub-lbl{display: block; font-weight: normal;}  
    }
    
}

.vg-btn-switch{
    .btn-group {
        > .btn{
            &:not(:last-child):not(.dropdown-toggle){border-top-left-radius: 50px;border-bottom-left-radius: 50px;}
            &:nth-child(n+3){border-top-right-radius: 50px;border-bottom-right-radius: 50px;}
        }
        .btn-primary{background: #fff; color: #333; border-color: #cdcdcd; min-width: 50px; text-align: center; padding: 8px 16px; font-size: 16px; font-weight: 400; line-height: 20px;}
        .btn-check:checked + .btn{background-color: #3E8438; border-color: #3E8438; color: #fff;}
    }
}

.review-row{ display: flex; justify-content: space-between; max-width: 90%;
    @media (max-width:991px) {
        max-width: 90%;
    }
    @media (max-width: 767px){
        display: block;
    }

    .review-col{padding-bottom: 30px;}
    .review-lbl{font-size: 20px; font-weight: 600; padding-bottom: 5px; line-height: 24px;
        .astrik{color: #CC4744;}
    }
}
}

.multifileupload-wrp{display: flex;
    .drop-file-upload{width: 175px; margin-right: 8px; background-size: cover;}
    .drop-file-upload.uploaded:hover{
        background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.6)); 
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.6));
    }
    .showdelete.delete-option-horizontal .moremenu {
        position: absolute;
        top: 0;
        right: 2px;
    }
    .showdelete.delete-option-horizontal .moremenu .more-3dot{
        transform: rotate(90deg);
    }
    .showdelete.delete-option-horizontal .moremenu:hover{
        background: transparent;
    }
    .showdelete .dropdown-menu.show{
        top: 24px;
        left: auto;
        margin: 0 auto;
        padding: 10px 0px !important;
        transform: none;
        min-width: 140px;
        width: 140px;
        }
    .showdelete .dropdown-menu a{
        color: #eb6663 !important;
        border: 1px solid #b42318 !important;
        display: block;
        font-size: 16px;
        border-radius: 5px;
    }
}

.review-form-wrapper{
    padding-top: 15px; border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.writereview-pop{
    .vg-star-rating button{padding-left: 0; padding-right: 0;}
    .modal-footer{
        .v-button{padding: 8px 16px;}
    }
}

@media (min-width:576px) {
    #writereviewpopup{
        --bs-modal-width:90%;
    }
    #writereviewpopup.writereview-pop{
        --bs-modal-width:90%;
    }
}
@media (min-width:992px) {
    #writereviewpopup{
        --bs-modal-width:1000px;
    }
    #writereviewpopup.writereview-pop{
        --bs-modal-width:800px;
    }
}
@media (min-width:1200px) {
    #writereviewpopup{
        --bs-modal-width:1000px;
    }
    #writereviewpopup.writereview-pop{
        --bs-modal-width:1030px;
    }
}

.vg-write-review-wrp {
    .review-business-name, .review-row .review-lbl, .form-row .form-label{font-family: var(--wbHeadFontFamily);}
    .vg-write-review-wrp {
        .review-business-name, .review-row .review-lbl, .form-row .form-label{font-family: var(--wbHeadFontFamily);}
        .form-control.form-select{font-family: var(--wbHeadFontFamily)!important;
            option{font-family: var(--wbHeadFontFamily)!important;}
        }
    }
}

@media (max-width: 767px){
.class-detail-popup #divExceptionFooter.modal-footer.footer-blue-buttons .btn.btn-default { margin-bottom: 15px;}  

}