.vg-giftCertificates{
    .page-title{ display: flex; align-items: center; font-family: var(--wbHeadFontFamily);
        a{color: #333; text-decoration: none; margin-right: 8px; padding-top: 2px; font-size: 34px;}
    }
}


.certificates-imgs .slick-slider{padding: 10px; border-radius: 5px; background: #f5f5f5;margin-bottom: 20px; margin-top: 7px;
    .slick-arrow{position: absolute; top: -52px; right: 0; font-size: 44px; color: #333; width: 18px; cursor: pointer;
        i{line-height: 0.7;}
        &.slick-disabled{color: #9a9a9a;}
        &.slick-prev{right:32px}
    }
    .slick-slide{border-radius: 3px; height: auto; border: 2px solid transparent; margin-right: 10px;
        &.slick-current{
            border-color: #000;
        }
    }
    .gc-thumitem{ display: block!important; padding-right: 0px;
        img{background-repeat: no-repeat; background-position: 50% 50%; background-size: cover; border-radius: 3px; border-radius: 3px; width: 100%; max-width: 100%;}
        
    }
    .slick-slide{
        &:last-child{
            .gc-thumitem{padding-right: 0;}
        }
    }

    .slick-track{white-space: nowrap;
        display: flex;

    }
}

.certificatePreview-wrapper{display: flex; 
    .gc-bg{ border: 1px solid rgba(0, 0, 0, 0.1);border-radius: 3px; position: relative;
        img{width: 500px; background-repeat: no-repeat; background-position: 50% 50%; background-size: cover; border-radius: 3px;}
     
        .gc-texts{position: absolute; top: 0; right: 0; max-width: 55%; height: 100%; padding: 0px 0px 10px 10px; width: 100%; margin-right: 10px; overflow: hidden;
            .price{font-size: 36px; font-weight: 700; line-height: normal; text-align: right; padding-top: 10px;min-height: 40px; padding-right: 40px;
            @media (max-width: 479px){ font-size: 10px; padding-top: 6px; min-height: 20px;}
            }
            .from {font-size: 15px;font-weight: 700;margin-bottom: 5px;line-height: 1;overflow: hidden;max-height: 16px;
            @media (max-width: 479px){ font-size: 7px;}
            }
            .fromto {font-size: 15px;font-weight: 700;margin-bottom: 5px;line-height: 1; max-height: 17px;text-overflow: clip;white-space: nowrap;margin-right: 20px;
            @media (max-width: 479px){ font-size: 7px; max-height: 8px;}
            }
            .message{font-size: 12px;line-height:14px;margin-top: 10px;max-height: 30px;overflow: hidden;word-wrap: break-word;
            @media (max-width: 479px){font-size: 7px; margin-top: 2px;}
            }
            .bottomtxts{position: absolute;bottom: 20px;right: 0;left: 10px;width: 100%;max-width: 215px;
            @media (max-width: 479px){ bottom: 10px;}
            }
            .gcid{font-size: 12px;font-weight: 700;line-height: 1;margin-bottom: 6px;
                @media (max-width: 479px){ font-size: 7px; margin-bottom: 1px;}
            }
            .business {font-size: 12px;font-weight: 700;line-height: normal;margin-bottom: 4px;
                @media (max-width: 479px){ font-size: 7px; margin-bottom: 1px; margin-top: 5px;}
            }
            .address {font-size: 10px;font-weight: 700;width: 65%;line-height: 13px;
                @media (max-width: 479px){ font-size: 8px; margin-bottom: 1px; line-height: 9px;}
            }
            .gc-expire{font-size: 12px;font-weight: 700;margin-bottom: 20px;line-height: normal;
                @media (max-width: 479px){ font-size: 7px; margin-bottom: 1px;}
            }
            .w-gc-price-service{ padding-top: 0px; min-height: 40px; padding-right: 40px;
            @media (max-width: 767px){
                min-height: 20px;
            }
            }
        }


    }

    .gc-form{flex: 1; padding-left: 10px; font-family: var(--wbHeadFontFamily);
        .form-row + .form-row{margin-top: 10px;}
        textarea.form-control{min-height: 182px; font-family: var(--wbHeadBodyFamily);}
        .form-control{ font-family: var(--wbHeadBodyFamily);}
    }

   

    @media (max-width:991px) {
        flex-direction: column;
        .gc-bg{
            img{width: 100%; max-width: 100%;}
        }
        .gc-form{flex: auto; padding: 0; margin-top: 15px;}
    }
}


.datepicker_wrp { position: relative;}
.f-18 { font-size: 18px;}
.datepicker_wrp .never-icon {cursor: pointer;position: absolute;right: 52px;top: 50%;
transform: translateY(-50%);z-index: 2;width: 30px;height: 35px;line-height: 39px;display: none;
text-align: center; font-weight: 300;}
.datepicker_wrp .clock-icon { position: absolute;
right: 34px;width: auto;top: 2px;font-size: 18px;color: #333;cursor: pointer;display: block;
z-index: 0;line-height: 39px; font-weight: 300;}
.datepicker_wrp .check-cal-icon { font-weight: 400; line-height: normal;cursor: pointer;z-index: 0;
position: absolute; right: 10px;top: 2px !important;
line-height: 39px;}
.datepicker_wrp:hover .never-icon {display: block;}

.datepicker_wrp {
    .form-control { text-align: left;}
}


.autocomplete {
    position: relative;
    display: inline-block;
  }
  
  #searchInput {
    width: 200px;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    border: 1px solid #ccc;
    background-color: white;
    list-style: none;
    padding: 0;
  }
  
  .dropdown-content li {
    padding: 5px;
    cursor: pointer;
  }
  
  .dropdown-content li:hover {
    background-color: #f0f0f0;
  }
  

  .vgGiftCertificteView {
    .modal-content{
        padding-bottom: 10px;
    }

    .vg-tabs,
    .vg-tabs li {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
        align-items: center;
    }

    .vg-tabs {
        border-bottom: 0px solid #e7e7e7;
        margin-top: 10px;

        li {
            &:not(:last-child) {
                margin-right: 16px;
            }

            a,
            .nav-link {
                display: block;
                padding: 8px 0;
                font-size: 18px;
                font-weight: 600;
                color: #6d6e6f;
                line-height: 24px;
                border: 0;
                border-bottom: 1px solid transparent;
                text-decoration: none;
                margin-bottom: 0;
                font-family: var(--wbHeadFontFamily);

                &:hover {
                    color: #333
                }

                &.active {
                    color: #333;
                    border-color: #333;
                }
            }
        }
    }

    .gc-tabs{
        
    
        .tab-content{padding: 15px 0;}
    
        .gc-amount-field{
            z-index: 9;
            position: relative;
        }
    }
.gc_selectservice .Service-dropdown-content{
    padding: 0px;

}
    .vg-tabs-wrapper{margin-top: -173px; border-bottom: 0; max-width: 45%;
        @media (max-width:991px) {
            max-width: 500px;
        }
        @media (max-width:768px) {
            max-width: 100%;
        }
    }

    .gc-action{padding: 15px 0 0 0; 
     button{padding: 10px 16px;
        font-size: 16px;
        margin-left: 8px;
    }
    }

    .gc-amount-field{
        .vg-wb-select{
            .dropdown-content{position: absolute; top: 100%; left: 0; width: 100%; max-height: 190px; overflow-y: auto; overflow-x: hidden;
                ul{ padding-left: 0;}
                li{padding: 10px 15px 10px 35px; font-size: 16px; white-space: normal;
                @media (max-width: 767px){
                    padding: 10px 15px 10px 30px; 
                }
                }
                &.gcDisCounts-dropdown {
                    li{padding: 10px 15px 10px 10px; font-size: 16px; white-space: normal;}
                }
            }
            
            &::after{/*content: "\f107";*/ height: 19px; font-weight: 300; font-size: 20px;
                content: url('data:image/svg+xml,<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.4805 5.85156L7.82227 10.0703C7.64648 10.2461 7.38281 10.2461 7.20703 10.0703L2.49023 5.85156C2.28516 5.67578 2.28516 5.38281 2.46094 5.20703C2.63672 5.00195 2.92969 5.00195 3.10547 5.17773L7.5 9.10352L11.8652 5.17773C12.041 5.00195 12.334 5.00195 12.5098 5.20703C12.6855 5.38281 12.6855 5.67578 12.4805 5.85156Z" fill="%23333333"/></svg>');
            }
        }
        .form-row-invalid{
            &.vg-wb-select{background-color: #FBEFEF;}
        }
        .invalid-feedback {
            display: none;
            color: #B42318;
            font-size: 12px;
            text-align: right;
            line-height: 16px;
            margin: 0;
            margin-top: 4px;
        }

        
    }
    .vgpay{
        .vgpay-note{display: flex; font-size: 12px; font-weight: 400;
            svg{width: 58px;}
            .paylater{padding-left: 7px;padding-top:5px}
        }
    }

    .product-qty{display: flex; align-items: center; padding-bottom: 15px; padding-top: 15px;
        label{padding-right: 5px;}
        .vg-stepbox{margin-right: 25px; width: auto;}

        .gc-price{color: #3e8438; font-size: 20px; font-weight: 600; line-height: 24px;}
        
    }
}


.groupSelectWrp {
    .select2.select2-container{min-width: 100%; width: 100% !important;}
}
@media (min-width:576px) {
    .vgGiftCertificteView{
        --bs-modal-width:90%;
    }
}
@media (min-width:992px) {
    .vgGiftCertificteView{
        --bs-modal-width:1000px;
    }
}
@media (min-width:1200px) {
    .vgGiftCertificteView{
        max-width:1020px;
    }
}
.badge-button-alert {
    background-color: var(--global-color-core-alert-default) !important;
    color: var(--color-text-neutral-inverse) !important;
    border-color: var(--global-color-core-alert-default) !important;
}
.deal-listing-discount-left {
    display: block;
    margin-top: 0;
    padding: 6px 14px;
    border-radius: 4px;
    position: absolute;
    left: 5px;
    bottom: 7px;
    background-color: #eb6663;
    color: #fff;
    font-size: 13px;
    text-align: right;
}
 .badge-button-alert {
    background-color:  #eb6663;
    color: #fff;
    border-style:solid;
    border-width: 1px;
    border-radius: 3PX;
    font-size: 12PX;
    padding: 4px 8px;
    line-height: 16px;
    font-weight: 600;
}
.servicealign{
    font-size: 15px;
    text-align: center;
    padding: 8px 5px 2px;
    @media (max-width: 479px){
        font-size: 10px;
        line-height: normal;
        min-height: 45px;
        overflow: hidden;
        height: 18px;
        margin-bottom: 3px;
    }
}
.certificatePreview-wrapper .gc-form .form-control:disabled { cursor :not-allowed;}
.gc_selectservice.gc-amount-field{
    .select2-container--default .select2-selection--single, 
    .select2-container--default .select2-selection--multiple{
        &::after{content: "\f107"; font-size: 20px; line-height: 12px; font-weight: 300;}
    }
}


@media (max-width: 767px) {
    .vgGiftCertificteView .vg-tabs-wrapper {
        margin-top: 0px;
    }
    .vgGiftCertificteView{
        margin: 0px;
        padding: 0px;
        border-radius: 0px;
        .modal-content{
            border-radius: 0px;
        }
    } 
}