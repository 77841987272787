.daily-deal-card{
    /*updating to static value to better handle multiple asspect ratios and allow better planning by user*/
    width: 330px; padding: 0 3px;

    .daily-deal-card-inner{ position: relative;
        img{width: 100%;}
    }

    .d-price{
        background: #333; border-radius: 4px 0 0 0; padding: 4px 8px; position: absolute; bottom: 0; right: 0; color: #fff;
    }
}
.v-daily-deals{
    position: relative; 
    .d-arrows{position: absolute; right: 3px; top: 0;
        button{padding: 0; margin: 0; background: transparent; color: #808080; font-size: 44px; border: 0; width: 14px; height: 40px; line-height: 20px; cursor: pointer;
            &.d-arrow-left{margin-right: 18px;}
            &:disabled{
                opacity: .4; cursor: default;
            }
        }
    }
}
.vgdailyDealList{display: flex;
    b{font-weight: 600;}

    &::-webkit-scrollbar {
     visibility: hidden;
    }
    &::-webkit-scrollbar-thumb {
        visibility: hidden;
    }
    &:hover::-webkit-scrollbar {
     visibility: visible;
    }
    &:hover::-webkit-scrollbar-thumb {
        visibility: visible;
       }
}


