﻿@font-face {
  font-family: 'vagaro-icons';
  src: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/vagaro-icons.eot');
  src: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/vagaro-icons.eot?#iefix') format('embedded-opentype'), url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/vagaro-icons.ttf') format('truetype'), url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/vagaro-icons.woff') format('woff'), url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/vagaro-icons.svg#vagaro-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'vagaro-icons-new';
  src: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/vagaro-icons-new.eot');
  src: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/vagaro-icons-new.eot?#iefix') format('embedded-opentype'), url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/vagaro-icons-new.ttf') format('truetype'), url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/vagaro-icons-new.woff') format('woff'), url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/vagaro-icons-new.svg#vagaro-icons-new') format('svg');
  font-weight: normal;
  font-style: normal;
}

.vgr-icon{font-family: 'vagaro-icons' !important;speak: none;font-style: normal;font-weight: normal;font-variant: normal;text-transform: none;line-height: 1;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}





html, body{height: 100%;}
#root{height: 100%;}
*, *:after, *:before {
       box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;

    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a{text-decoration:none;}
/* browser scrollbar style */
* {
  scrollbar-color:  #f6f4f3;
  scrollbar-width: 7px;
  /* scroll-behavior: smooth; */
}

::-webkit-scrollbar {
  width: 7px !important; 
  background-color: #d9d9d9;border-radius: 10px;
}

::-webkit-scrollbar:horizontal{
  height: 7px;
  background-color: #d9d9d9;border-radius: 10px;
}

@media (max-width: 462px){
  ::-webkit-scrollbar {
    width: 7px!important; 
  }
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #7d7e7e;border-radius: 10px;
  position: relative;
  z-index: 999;
}

@for $i from 1 through 10 {
    $zIndexBackdrop:  #{1000 + (5 * $i)};
    $zIndexContent:  #{1000 + (5 * $i) + 2};
    .modal-backdrop.show:nth-of-type(#{$i}) {
      z-index: $zIndexBackdrop;
    }
    div[role="dialog"][aria-modal="true"]:nth-of-type(#{$i}) {
      z-index: $zIndexContent;
    }
    .pswp:nth-of-type(#{$i}) {
        z-index: $zIndexContent!important;
    }
  }

.modal{color:#333}
body:not(.ipadandroid) ::-webkit-scrollbar, 
body:not(.ipadandroid) ::-webkit-scrollbar {width: 7px;background-color: #d9d9d9;border-radius: 10px;}
body:not(.ipadandroid) ::-webkit-scrollbar-thumb, 
body:not(.ipadandroid) ::-webkit-scrollbar-thumb {background-color: #7d7e7e;border-radius: 10px;}
body:not(.ipadandroid) ::-webkit-scrollbar-track, 
body:not(.ipadandroid) ::-webkit-scrollbar-track { border-radius: 10px; }
body{padding:0; margin:0;font-family: var(--web-body-font-family);
    font-weight: var(--web-body-font-weight);
    //line-height: var(--web-body-line-height);
    font-size: var(--web-body-font-size);
    letter-spacing: var(--web-body-letter-spacing);
    text-decoration: var(--web-body-text-decoration);
    text-transform: var(--web-body-text-transform);
    color: var(--color-text-neutral-default);}

.web-body-font {
    font-family: var(--web-body-font-family);
    font-weight: var(--web-body-font-weight);
    //line-height: var(--web-body-line-height);
    font-size: var(--web-body-font-size);
    letter-spacing: var(--web-body-letter-spacing);
    text-decoration: var(--web-body-text-decoration);
    text-transform: var(--web-body-text-transform);
    color: var(--color-text-neutral-default);
}

/* Avatar */
.vagaro-avatar .vg-avatar-inital {
  height: var(--global-sizing-48) !important;
  width: var(--global-sizing-48) !important;
  background-color: var(--color-bkg-neutral-pressed) !important;
  background-repeat: no-repeat !important;
  background-position: center;
  background-size: cover;
  border-width: var(--global-border-width-default) !important;
  border-color: var(--color-border-neutral-default) !important;
  border-style: var(--global-border-style-solid) !important;
  border-radius: var(--global-radius-full);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.vagaro-avatar .avtar-text-box {
  color: var(--color-text-neutral-default) !important;
  font-size: var(--web-body-font-size) !important;
  background-color: var(--color-bkg-neutral-pressed) !important;
  border-width: var(--global-border-width-default) !important;
  border-color: var(--color-border-neutral-default) !important;
  border-style: var(--global-border-style-solid) !important;
  height: var(--global-sizing-48) !important;
  width: var(--global-sizing-48) !important;
  border-radius: var(--global-radius-full) !important;
  line-height: 48px !important;
  text-align: center !important;
  float: left;
  min-width: 48px !important;
}
.vagaro-avatar-lg,
.vagaro-avatar-md,
.vagaro-avatar-sm {
  background-color: var(--color-bkg-neutral-pressed) !important;
  border-radius: var(--global-radius-full) !important;
  background-position: 50% 50% !important;
  background-size: cover !important;
  border-width: var(--global-border-width-default) !important;
  border-color: var(--color-border-neutral-default) !important;
  border-style: var(--global-border-style-solid) !important;
  height: var(--global-sizing-80) !important;
  width: var(--global-sizing-80) !important;
  /*display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: center;
        align-items: center;*/
}
.vagaro-avatar-lg .vg-avatar-inital,
.vagaro-avatar-md .vg-avatar-inital,
.vagaro-avatar-sm .vg-avatar-inital {
  background-color: var(--color-bkg-neutral-pressed) !important;
  font-weight: var(--web-body-font-weight) !important;
  color: var(--color-text-neutral-default) !important;
  border-radius: var(--global-radius-full) !important;
  text-align: center !important;
  border-width: var(--global-border-width-default) !important;
  border-color: var(--color-border-neutral-default) !important;
  border-style: var(--global-border-style-solid) !important;
  margin: -1px var(--global-spacing-none) var(--global-spacing-none) -1px;
}
.vagaro-avatar-lg .vg-avatar-inital .vg-avatar-inital-inner,
.vagaro-avatar-md .vg-avatar-inital .vg-avatar-inital-inner,
.vagaro-avatar-sm .vg-avatar-inital .vg-avatar-inital-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.vagaro-avatar-lg .vg-avatar-inital .vg-avatar-inital-inner-text,
.vagaro-avatar-md .vg-avatar-inital .vg-avatar-inital-inner-text,
.vagaro-avatar-sm .vg-avatar-inital .vg-avatar-inital-inner-text {
  /*display: table-cell;
                justify-content: center;
                align-items: center;*/
  height: 100% !important;
  width: 100% !important;
}
.vagaro-avatar-lg .vg-avatar-inital {
  height: var(--global-sizing-80) !important;
  width: var(--global-sizing-80) !important;
}
.vagaro-avatar-lg .vg-avatar-inital .vg-avatar-inital-inner-text {
  font-size: var(--web-display-medium-default-font-size) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--global-sizing-80) !important;
}
.vagaro-avatar-md {
  height: var(--global-sizing-48) !important;
  width: var(--global-sizing-48) !important;
}
.vagaro-avatar-md .vg-avatar-inital {
  height: var(--global-sizing-48) !important;
  width: var(--global-sizing-48) !important;
}
.vagaro-avatar-md .vg-avatar-inital .vg-avatar-inital-inner-text {
  font-size: var(--web-body-font-size) !important;
  line-height: 48px !important;
}
.vagaro-avatar-sm {
  background-color: var(--color-bkg-neutral-pressed) !important;
  height: var(--global-sizing-32) !important;
  width: var(--global-sizing-32) !important;
  color: var(--color-text-neutral-default) !important;
  font-size: var(--web-caption-font-size) !important;
  line-height: var(--global-sizing-32) !important;
}
.vagaro-avatar-sm .vg-avatar-inital {
  height: var(--global-sizing-32) !important;
  width: var(--global-sizing-32) !important;
}
.vagaro-avatar-sm .vg-avatar-inital .vg-avatar-inital-inner-text {
  font-size: var(--web-caption-font-size) !important;
  line-height: 32px !important;
}
.vagaro-avatar-attendee {
  text-align: center !important;
  border-radius: var(--global-radius-full) !important;
  background-color: var(--color-bkg-neutral-pressed) !important;
  border-style: var(--global-border-style-solid) !important;
  border-width: var(--global-border-width-default) !important;
  border-color: var(--color-border-neutral-default) !important;
  color: var(--color-text-neutral-default) !important;
  font-size: var(--web-caption-font-size) !important;
  height: var(--global-sizing-32) !important;
  width: var(--global-sizing-32) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.show > .btn-primary.dropdown-toggle{
    &:focus, &:active, &:hover {box-shadow: none;}
}
.btn{
    &:focus{box-shadow: none;}
}
.w-button{
    
    font-weight: var(--web-button-font-weight);
    line-height: var(--web-button-line-height);
    font-size: var(--web-button-font-size);
    letter-spacing: var(--web-button-letter-spacing);
    -webkit-text-decoration: var(--web-button-text-decoration);
    text-decoration: var(--web-button-text-decoration);
    text-transform: var(--web-button-text-transform);
    color:var(--color-text-neutral-default);
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center; border-width:1px; border-style:solid;
    cursor: pointer !important; box-sizing:border-box;
    border-radius:var(--global-radius-button-default);
    padding:calc(var(--global-spacing-8) + var(--global-spacing-1)) var(--global-spacing-16);
    &:hover{
        text-decoration:none;
        color:var(--color-text-neutral-default);
    }
    &-neutral, &-neutral.show{
        background:#333; color:#fff; border-color:#333;
        &:hover{background:#474747; color:#fff; border-color:#474747;}
        &:active{background:#5c5c5c; color:#fff; border-color:#5c5c5c;}
    }
    &-primary, &-primary.show{
        background:var(--color-bkg-green-default);
        border-color:var(--color-bkg-green-default);
        color:#fff;
        i{margin-right:8px;}
        &:hover{
            background:var(--color-bkg-green-hover);
            border-color:var(--color-bkg-green-hover);
            color:#fff;
            
        }
        &:active, &:first-child:active{
            background:var(--color-bkg-green-pressed);
            border-color:var(--color-bkg-green-pressed);
            color:#fff;
            
        }
    }
    &-ghost{
        background:transparent;
        border-color:transparent;
        color:var(--color-text-blue-default);
        padding:4px 8px;
        i{margin-right:4px;}
        &:hover{
            background:var(--color-bkg-blue-weak);
            border-color:var(--color-bkg-blue-weak);
            color:var(--color-text-blue-default);
        }
        &:active{
            background:var(--color-bkg-blue-pressed);
            border-color:var(--color-bkg-blue-pressed);
            color:var(--color-text-blue-default);
        }
    }
    &-secondary{
        background:#fff;
        //padding:var(--global-spacing-8);
        border-color:var(--global-color-core-ink-light); color:#333;
        &:hover{
            background:var(--color-bkg-neutral-medium);
            border-color:var(--global-color-core-ink-light);color:#333;            
        }
        &:active{
            background:var(--color-bkg-neutral-pressed);
            border-color:var(--global-color-core-ink-light); color:#333;         
            
        }
    }
    &-iconbtn{
        background:transparent;
        padding:var(--global-spacing-8);
        border-color:var(--color-icon-neutral-default); border-width:0;
        i{margin:auto;}
        &:hover{
            //background:var(--color-bkg-neutral-medium);
            //border-color:var(--global-color-core-ink-light);            
        }
        &:active{
            background:var(--color-bkg-neutral-pressed);
            border-color:var(--global-color-core-ink-light);          
            
        }
    }
    &-destructive{
        color:var(--color-text-alert-default);
        &:hover{
            //color:var(--color-text-alert-default);
            //background:var(--color-bkg-alert-hover);
        }
        &:active{
            color:var(--color-text-alert-default);
            background:var(--color-bkg-alert-pressed);
        }
    }
    &-destructive-outline{
        color:var(--color-text-alert-default);
        background:#fff;
        border-color:var(--color-border-alert-default);
        &:hover{
            color:var(--color-text-alert-default);
            background:var(--color-bkg-alert-hover);
            border-color:var(--color-border-alert-default);
        }
        &:active{
            color:var(--color-text-alert-default);
            background:var(--color-bkg-alert-pressed);
            border-color:var(--color-border-alert-default);
        }
    }
    
}

.badge {
    --bs-badge-padding-x:8px;
    --bs-badge-padding-y: 4px;
    --bs-badge-font-size: 10px;
    --bs-badge-font-weight: 700;
    --bs-badge-border-radius: 20px;
    --bs-badge-primary-color: #7CC576;
    display: inline-block;
    padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
    font-size: var(--bs-badge-font-size);
    font-weight: var(--bs-badge-font-weight);
    line-height: 12px;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline; border:1px solid #e7e7e7;
    border-radius: var(--bs-badge-border-radius); color:var(--color-text-neutral-default);
    

    &-primary{
        background:var(--bs-badge-primary-color); border-color:var(--bs-badge-primary-color); color:#fff;
    }
    &-inactive{border-radius: 3px;}
}

.tooltip{
    &.show{opacity:1;}
    .tooltip-arrow{width:16px; height:8px;}
    
    &.bs-tooltip-auto[data-popper-placement^=top], 
    &.bs-tooltip-top{padding:0px 0;
        .tooltip-arrow{
            &::before{border-width: 8px 8px 0; border-bottom-color:#333; top:0;}
        }
    }
    &.bs-tooltip-auto[data-popper-placement^=bottom], 
    &.bs-tooltip-bottom{padding:0px 0;
        .tooltip-arrow{
            &::before{border-width: 0 8px 8px; border-bottom-color:#333; bottom:0;}
        }
    }
    &.bs-tooltip-auto[data-popper-placement^=left], 
    &.bs-tooltip-start{padding:0px 0px;
        .tooltip-arrow{height:16px; width:8px;
            &::before{border-width: 8px 0 8px 8px; border-left-color:#333; left:0}
        }
    }
    &.bs-tooltip-auto[data-popper-placement^=right], 
    &.bs-tooltip-end{padding:0px 0px;
        .tooltip-arrow{height:16px; width:8px;
            &::before{border-width: 8px 8px 8px 0; border-right-color:#333; right:0}
        }
    }
    .tooltip-inner{background:#333; color:#fff; font-size:12px; line-height:16px; font-weight:400; border-radius:3px; padding:8px 12px;}
}
.dropdown-menu{
    box-shadow:0px 2px 10px rgba(0, 0, 0, 0.1); border-radius:3px; border:0; padding:4px 0;
    .dropdown-item{padding:12px; text-decoration:none; color:#333; line-height:20px; font-weight:400; cursor:pointer;
        &:hover, &.active{background:#ebebeb; color:#333;}
    }
    .with-checkbox{padding:0;
        .form-check{padding: 0; margin: 0;;}
       .check-label, .form-check-label{padding:12px; text-decoration:none; color:#333; line-height:20px; font-weight:400; cursor:pointer; padding-left:40px; position:relative; width: 100%; user-select: none;
            &::before{width:20px; height:20px; border:1px solid var(--color-border-neutral-default); border-radius:5px; content:""; position:absolute; left:12px; top:12px; background-color:#fff;}
            &::after{width:20px; height:20px; font-size:13px; content:"\f00c"; font-family:"Font Awesome 5 Pro"; font-weight:900; text-align:center; line-height:15px; position:absolute; top:15px; left:12px; color:#fff; display:none;}
            &:hover{background:#ebebeb; color:#333;}
        }
        input[type="checkbox"]{position:absolute; width:0; height:0; outline:0; border:0; opacity:0; top:0; left:0;
            &:checked{
                & + .check-label, & + .form-check-label{
                    &::before{background-color:var(--color-bkg-neutral-strong);}
                    &::after{display:block;}
                }
            }
        }
        
    }
}




.main-container{padding:0 var(--global-sizing-16);}
.main-content-wrapper{position: absolute;left: 0;right: 0;top: 50px;bottom: 0;display: flex;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;

    .panel-container{width:200px; min-width:200px; max-width:200px; border-right:1px solid var(--global-color-core-ink-lighter); overflow-x:hidden; overflow-y:auto; padding-top:8px;}
    .page-root{ padding: 0px;bottom: 0;width: 100%;max-height: 100%;position: relative;display: flex;justify-content: center;align-items: center; background:#f8f8f8;
        &.flex-start{align-items: flex-start;}
    }
}

.main-content-wrapper-template{position: absolute;left: 0;right: 0;top: 50px;bottom: 0;display: flex;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;

    .panel-container{width:200px; min-width:200px; max-width:200px; border-right:1px solid var(--global-color-core-ink-lighter); overflow-x:hidden; overflow-y:auto; padding-top:8px;}
    .page-root{ padding: 0px;bottom: 0;width: 100%;max-height: 100%;position: relative;display: flex;justify-content: center;align-items: center; background:#f8f8f8; overflow: auto;
        &.flex-start{align-items: flex-start;}
    }
}

.no-access-main-content-wrapper{position: absolute;left: 0;right: 0;top: 50px;bottom: 0;display: flex;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;

    .panel-container{width:200px; min-width:200px; max-width:200px; border-right:1px solid var(--global-color-core-ink-lighter); overflow-x:hidden; overflow-y:auto; padding-top:8px;}
    .page-root{ padding: 0px;bottom: 0;width: 100%;max-height: 100%;position: relative;display: flex;justify-content: center;align-items: center; background:#f8f8f8;
        &.flex-start{align-items: flex-start;}
    }
}

.py-t8{margin-top:8px;}
.py-t16{margin-top:16px;}
.py-t24{margin-top:24px;}

.py-t0{padding-top:0!important}
.py-b24{margin-bottom:24px;}

.page-title{font-size:var(--global-sizing-24); line-height:var(--global-font-height-28); font-weight:var(--web-display-large-font-weight); padding:0; margin:0}
.title-with-btn{display:flex; justify-content:space-between; align-items:center;
    .title-btn{margin-left:auto; margin-right:0;
        &.start-from-scratchbtn{padding-left: 8px;
            .w-button{min-width: 159px}
        }
    
        @media (max-width:991px) {
            .w-button{white-space: nowrap;}
        }
    }
}

.vg-tabs-wrapper{
    border-bottom:1px solid var(--global-color-core-ink-lighter);
    .vg-tabs{display: flex;padding: 0px;margin: 0px; flex-wrap:nowrap;
        li a{list-style:none; padding:8px 0; margin:0; cursor:pointer; border-bottom:1px solid transparent; color:var(--color-text-neutral-secondary);display: block;
           &.selected, &.active{border-bottom-color:#333; color:var(--color-text-neutral-default);}
           
        }
        .has-settingIcon, .has-pageIcon, .has-editIcon{
            svg{
                *{fill: #6D6E6F;}
            }
            .active{
                svg{
                    *{fill: #333;}
                }
            }
        }
        .has-settingIcon{
            svg{width: 15px; height: 15px;}
        }
        .has-pageIcon{
            svg{width: 12px; height: 20px;}
        }
        .has-editIcon{
            svg{width: 16px; height: 20px;}
        }
    }
}
.vg-tab-item{
    .tab-title{border-bottom:0; padding-left:32px}
}

.tab-links{
    display:flex; flex-direction:column;
    
    .tab-link{display:flex; text-decoration:none; padding:8px 8px 8px 32px;color:var(--color-text-neutral-default); line-height:20px; align-items:center; padding-right:20px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap;
        i{margin-right:8px; font-size:15px;}
        &.nested{padding-left:48px;}
        &.addpage{color:#9a9a9a;}
        &:hover{background:var(--color-bkg-neutral-hover)}
        &.active{background:var(--color-bkg-neutral-pressed)}
    }
    .tab-item{position:relative;
        &.active{
          .tab-link{
            background:var(--color-bkg-neutral-pressed)}
        }
        .tab-link-icon{position:absolute; top:12px; right:8px; width:16px; height:16px; line-height:15px;color:var(--color-text-neutral-default); font-size:15px; text-align:center; z-index:3;}
    }
}

.form-row{
    ::-webkit-input-placeholder {opacity:1; color:#9a9a9a}
    :-moz-placeholder {opacity:1; color:#9a9a9a}
    ::-moz-placeholder {opacity:1; color:#9a9a9a}
    :-ms-input-placeholder {opacity:1; color:#9a9a9a}
    ::-ms-input-placeholder {opacity:1; color:#9a9a9a}
    ::placeholder {opacity:1; color:#9a9a9a}
    .form-select:focus{box-shadow: none;}
    .form-label{display:block; padding:0; margin:0; padding-bottom:4px; line-height:20px; font-weight:600; font-size: 16px;}
    .form-control{border-radius:3px; border-color:var(--color-border-neutral-default); padding:9px 8px; box-shadow:none; font-weight:400; line-height:20px; font-size:16px; color:#333;
        &:focus{border-color:var(--color-border-blue-default);}
       
    }
    textarea{height:98px; resize:none}
    .form-text{color:var(--color-text-neutral-secondary); font-size:12px; line-height:16px; padding:0; margin:0}

    .drop-file-upload{
        max-width:280px; border:1px dashed var(--color-border-neutral-default); height:140px; display:flex; flex-direction:column; justify-content:center; align-items:center; position:relative; overflow:hidden; cursor:pointer; border-radius:5px; background:var(--color-bkg-neutral-medium); color:var(--color-text-neutral-weak); background-repeat: no-repeat; background-position: center;
        &-lg{max-width:474px; height:156px;}
        .uploadfile-control{position:absolute; top:0; left:0; overflow:hidden; width:100%; height:100%; opacity:0}
        i{font-size:42px; line-height:42px;}
        .upload-note{padding-top:16px; font-size:15px; padding-bottom:4px;}
        .upload-trigger{font-size:15px; color:#2A90D6}

        &.webclip{
            &.uploaded{
                width: 150px; height: 150px; background-size: cover;
            }
        }
        &.tempalteimg{background-size: cover; background-position: center top;}
        &:focus, &.focus{
            background-color:rgba(190, 227, 188, 0.25); border-color:#7CC576;
        }

        &.uploaded{
            .showdelete{position: absolute; background: rgba(0,0,0,0.7); text-align: center; display: flex; align-items: center; justify-content: center; top: 0; left: 0; width: 100%; height: 100%; opacity: 0; visibility: hidden; transition: all ease-in 0.2s;
                a{font-weight: 500; color: #fff; text-decoration: none;}
            }
            &:hover{
                .showdelete{opacity: 1; visibility: visible;}
            }
        }
        &.invalid{border-color: #B42318; background-color: #FBEFEF;}
        &.over{border-color: #7cc576; background-color: rgba(124, 197, 118, 0.15); border-style: solid;}
    }
    & + .form-row{margin-top:24px;}
    .vg-wb-select{
        background:#fff; position:relative;
        select.form-control, .form-control{background-color:transparent; position:relative; padding-right:30px; z-index:2; background-image: none;}
        &::after{font-family: "Font Awesome 5 Pro";font-weight: 900;position: absolute;right: 8px;top: 50%;transform: translateY(-50%);border: 0;margin: 0; z-index:1; width:16px; height:16px; text-align:center; line-height:1;/*content: "\f0d7";*/ content:url('data:image/svg+xml,<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.8945 7.11133L8.17383 10.8613C7.96875 11.0371 7.73438 11.125 7.5 11.125C7.23633 11.125 7.00195 11.0371 6.82617 10.8613L3.10547 7.11133C2.8125 6.84766 2.72461 6.4375 2.87109 6.08594C3.01758 5.73438 3.36914 5.5 3.75 5.5H11.2207C11.6016 5.5 11.9238 5.73438 12.0703 6.08594C12.2168 6.4375 12.1582 6.84766 11.8945 7.11133Z" fill="%23333333"/></svg>')}
    }
    .vg-datepicker-wb{position: relative;background-color:#fff;
        .form-control{position: relative;z-index: 1;cursor: pointer;background-color:transparent;text-align: left;}
        i{position: absolute; top: 10px; right: 8px; font-size: 18px;z-index: 0;}
    }

    .vg-switch-checkboxhtml {
	    padding-left: 65px;
	    min-height: 30px;
	    >label {
		    cursor: pointer;
		    line-height: 20px;
		    display: inline-block;
		    vertical-align: top;
		    font-size: 16px;
		    font-weight: 600;
		    padding-top: 6px;
		    position: relative;
		    margin-bottom: 0;
		    -webkit-user-select: none;
		    -moz-user-select: none;
		    -ms-user-select: none;
		    user-select: none;
		    span {
			    color: #777;
			    display: block;
			    font-size: 16px;
			    font-weight: 400;
			    margin-bottom: 2px;
			    margin-top: 0px;
		    }
	    }
	    >label.padding-top0 {
		    padding-top: 0;
	    }
	    >input[type="checkbox"] {
		    display: none;
		    + {
			    label {
				    &:before {
					    background-color: #eee;
					    content: '';
					    height: 30px;
					    width: 50px;
					    overflow: hidden;
					    cursor: pointer;
					    top: 0;
					    left: -65px;
					    position: absolute;
					    border-radius: 50px;
					    border: 1px solid rgba(0,0,0,0.1);
					    transition: background-color 200ms ease 0s;
					    box-sizing: border-box;
					    margin-top: 0px !important;
				    }
				    &:after {
					    background-color: #fff;
					    content: '';
					    height: 28px;
					    width: 28px;
					    overflow: hidden;
					    cursor: pointer;
					    top: 1px;
					    left: -64px;
					    position: absolute;
					    box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.15);
					    border-radius: 100%;
					    transition: transform 100ms ease-in 0s;
					    transform: translateX(0) translateY(0) translateZ(0);

					    transform-style: preserve-3d;
					    -webkit-backface-visibility: hidden;
					    backface-visibility: hidden;
					    margin-top: 0px !important;
				    }
			    }
		    }
		    &:checked {
			    + {
				    label {
					    &:before {
						    background-color: #7cc576;
					    }
					    &:after {
						    transform: translateX(20px) translateY(0) translateZ(0);
					    }
				    }
			    }
		    }
		    &:disabled {
			    + {
				    label {
					    &:before {
						    opacity: 0.3;
						    cursor: default;
					    }
					    cursor: default;
					    &:after {
						    cursor: default;
					    }
				    }
			    }
		    }
	    }
    }
    .access-level-title {
	    position: relative;
	    display: inline-block;
	    margin-bottom: 6px;
	    padding-top: 0px;
	    float: none;
	    font-size: 16px;
	    line-height: 20px;
	    font-weight: 600;
	    word-wrap: break-word;
	    word-break: break-word;
    }

    .input-group{
        .input{flex: 1; padding-right: 8px;}
        .w-button{border-radius: 3px!important; max-height: 40px;}
    }
}
.form-row-invalid{
    .invalid-feedback{display: block; color: #B42318; font-size: 12px; text-align: right; line-height: 16px; margin: 0; margin-top: 4px;
        &.abovehelpmsg{margin-top: -12px;}
    }
    .form-select, .form-control{border-color: #B42318; background-color: #FBEFEF; }
    .select2-container--default {
        .select2-selection{border-color: #B42318; background-color: #FBEFEF;}
    }
    .vg-wb-select{background-color: #FBEFEF;}
}
.google-search-preview{ border-radius: 3px; border:1px solid var(--color-border-neutral-default);padding:8px;font-weight: 400;line-height: 20px;font-size: 16px;color: #333;
     span{display:block;}
    .g-title{color:#1A0DAB; font-size:20px; line-height:24px;}
    .g-url{color:#026621; font-size:16px; line-height:20px;}
    .g-desc{color:#333; font-size:16px; line-height:20px;}
}
.title-with-actions{display:flex; align-items:center;}
.inline-actions-btns{display:flex; margin-left:auto; margin-right:0; align-items:center; justify-content:flex-end;
    .w-button + .w-button{margin-left:16px;}
    .w-button-iconbtn + .w-button-iconbtn{margin-left:8px;}
}

.moremenu{ width:32px; height:32px; text-align:center;
    
        .more-3dot{
        background:transparent;
        padding:var(--global-spacing-4); display:inline-block; border-radius:var(--global-radius-button-default);
        border-color:var(--color-icon-neutral-default); border-width:0; text-align:center; cursor:pointer; color: #333; box-shadow: none!important; width: 20px;
        i{margin:auto;}
        &::after{display: none;}
        &:hover{
            background:var(--color-bkg-neutral-pressed);
            border-color:var(--color-bkg-neutral-pressed);            
        }
        &.show{
            background:#B3B3B3;
            border-color:#B3B3B3;
            color: #333;          
            
        }
        &:active{
            background:#B3B3B3;
            border-color:#B3B3B3;
            color: #333;          
            
        }
    }
   &.size24{
       i{font-size:24px; line-height:24px;}
   } 
}


.vgn-popup{
    margin: 0 auto;
    .modal-content {
    box-shadow: 0 4px 14px 0 rgba(0,0,0,.25);
    border: 0 solid rgba(0,0,0,.2);
    border-radius: 5px;
    .modal-header{padding:18px 40px 0px 20px; border-bottom:0;
        .modal-title{font-size:20px; line-height:24px; font-weight:600; padding:0; margin:0;}
        .close, .btn-close{width:24px; height:24px; text-indent:0; text-align:center; background:transparent; background-image:none;font-family: "Font Awesome 5 Pro"; font-weight:400; font-size:15px; color:#333; margin:0; float:none; position:absolute; top:12px; right:12px; outline:0; border:0; opacity: 1; padding: 0; line-height: 16px; border-radius:24px;
               span{display:none;}
               &::before{content: url('data:image/svg+xml,<svg width="17" height="17" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.9531 10.8613C12.2168 11.1543 12.2168 11.5938 11.9531 11.8574C11.6602 12.1504 11.2207 12.1504 10.957 11.8574L7.5 8.37109L4.01367 11.8574C3.7207 12.1504 3.28125 12.1504 3.01758 11.8574C2.72461 11.5938 2.72461 11.1543 3.01758 10.8613L6.50391 7.375L3.01758 3.88867C2.72461 3.5957 2.72461 3.15625 3.01758 2.89258C3.28125 2.59961 3.7207 2.59961 3.98438 2.89258L7.5 6.4082L10.9863 2.92188C11.25 2.62891 11.6895 2.62891 11.9531 2.92188C12.2461 3.18555 12.2461 3.625 11.9531 3.91797L8.4668 7.375L11.9531 10.8613Z" fill="%23333333"/></svg>');}
               &:hover{background:#f8f8f8}
               &:focus{box-shadow: none;}
        }
    }
    .modal-body {
        padding: 15px 20px;
        font-size: 15px;
        margin-bottom: 0px;
        font-weight: 400;
        line-height: 20px;
    }
    .modal-footer {
    
        padding: 15px 20px;
        
        
        justify-content: flex-end;
        border-top: none;
        .w-button {
            margin:0;
            & + .w-button{margin-left:15px;}
        } 
    }
    }
    
}

.modal{
    .vg-w480{max-width: 480px;}
}

.vg-alert-strip{
    padding:12px 16px; border-radius:3px; font-size:15px; line-height:20px; font-weight:500; border-width:1px; border-style:solid; border-color:transparent; display:inline-block; margin:0;
    &.vg-passive{border-radius:5px; text-align:center; display:block; margin-bottom:15px; margin-top:15px;}
    &.vg-natural{background:var(--color-bkg-neutral-strong); border-color:var(--color-bkg-neutral-strong); color:var(--color-border-neutral-default);}
    &.vg-info{background:#EEF3FF; border-color:#EEF3FF; color:#2A90D6;}
    &.vg-warn{background:#FF9500; border-color:#FF9500; color:#ffffff;}
    &.vg-success, &.alert-success{background:#3E8438; border-color:#3E8438; color:#ffffff;}
    &.alert-danger{background:var(--color-bkg-alert-default); border-color:car(--color-bkg-alert-default); color:#fff;
        i{vertical-align: middle; font-size: 18px; margin-right: 5px;}
    }
}

.getstarted-strip{
    padding-bottom: 16px;
    .vg-alert-strip{text-align: center; width: 100%; font-weight: 400;}
    a{margin-left: 24px; color: #fff; display: inline-block; vertical-align: baseline;
    i{margin-left: 8px; font-size: 15px;}
}
}
.js-hasScroll{overflow: auto;}

.grid-left{ padding-top: 8px; border-right: 1px solid var(--global-color-core-ink-lighter);
    .panel-tabs{ gap: 0;
        border-bottom: 1px solid var(--global-color-core-ink-lighter);
        .tab-button{ padding: 8px 0;
            &:nth-child(1){
                svg{width: 15px; height: 15px;}
            }
            &:nth-child(2){
                svg{width: 12px; height: 20px;}
            }
            &:nth-child(3){
                svg{width: 16px; height: 20px;}
            }
        }
    }
}


.vg-table-bdr{overflow: hidden; border-radius: 5px; border: 1px solid #cdcdcd;
    .table{margin: 0; border: 0;
        thead{
            th{background: #F8F8F8; font-weight: 500; color: #333; font-size: 15px; padding: 16px 8px; line-height: 20px; border-bottom: 1px solid #e7e7e7;}
        }
        tbody{
            td{font-weight: 400; color: #333; font-size: 15px; padding: 12px 8px; line-height: 20px; border-bottom: 1px solid #e7e7e7;
                &.word-break-td{word-wrap: break-word; word-break: break-all; white-space: normal;}
            }
            tr{
                &:last-child{
                    td{border-bottom: 0;}
                }

                &:hover{
                    background: #f8f8fa;
                }
            }
        }
    }
    .text-connected{color: #3E8438;}
    .text-disconnected{color: #D83F34;}
}

.tablscrollbdr{border-radius: 5px; border: 1px solid #cdcdcd; overflow: auto;
    .vg-table-bdr{overflow: visible; border-radius: 0; border: none;}   
}

.page-title.actions-bottoms{padding-bottom: 30px;}

.left-flyout{
    .main-content-wrapper{top: 0;}
}

.vg-star-rating{ 
    button{padding: 0; margin: 0; border: 0; background: transparent; padding: 5px 2px 5px 0;}    
    .star{font-size: 32px;font-family: "Font Awesome 5 Pro"; font-weight: 900; line-height: 1; color: #e7e7e7;}
    .off{
        .star{color: #e7e7e7;}
    }
    .on{
        .star{color: #f6d645;}
    }
}
.share-social{display: flex;
    .social-share-icon{text-decoration: none; width: 100%; height: 30px; border-radius: 30px; display: flex; text-align: center; align-items: center; overflow: hidden; line-height: 1; font-size: 18px; min-width: 30px; max-width: 30px; color: #fff;
        &:not(:first-child){margin-left: 10px;}
        i, svg{margin: auto;}
        &.facebook{background: #3a589b; color: #fff;}
        &.twitter{background: #00aced; color: #fff;}
        &.pintrest{background: #cb1f27; color: #fff;}
    }
}

.vg-stepbox{
    input{border-radius:3px; border-width: 1px; border-style: solid; border-color:var(--color-border-neutral-default); padding:9px 8px; box-shadow:none; font-weight:400; line-height:20px; font-size:16px; color:#333; text-align: center; width: 60px;
        &:focus{border-color:var(--color-border-blue-default);}
    }
    button{padding: 0; margin: 0; border: 0; width: 40px; text-align: center; border: 1px solid var(--color-border-neutral-default); color:#333;
        &:last-child{border-radius: 0 3px 3px 0;}
    }
}
// Container query setup for front end component layouts.
.card-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    border: solid 1px rgb(134, 16, 16);
    resize: horizontal;
    overflow: auto;
    max-width: 100%;
    container-type: inline-size;
  }
  .testcard {
    display: flex;
    gap: 24px;
    align-items: center;
    // background-color: rgba(236, 217, 255, 0.473);
    border-radius: 12px;
    padding: 24px;
  }

@container (max-width: 767px) {
    .testcard {
      flex-direction: column;
      gap: 12px;
      background-color: blue;
    }
  }

  @container (min-width: 576px) {
    .testcard {
        flex-direction: column;
        gap: 12px;
        background-color: red;
      }
    }

  @container (min-width: 992px) {
    .testcard {
        flex-direction: row;
        gap: 12px;
        background-color: yellow;
      }
  }
// ends here
.profile-drop-menu {
    .pofile-drop {
            display: none; left:auto; right:-6px; width:275px; border-radius:0; padding-top:0; padding-bottom:0; max-height:calc(100vh - 50px); overflow:auto;position: absolute;
    box-shadow: 0 2px 10px rgba(0,0,0,.1);padding: 4px 0;z-index: 1000;background: #fff;
            .navigation-item{
                padding:12px 16px; line-height:normal; display:flex; align-items:center; transition:all ease-in-out 0.3s;
                i{display:flex; width:32px; height:32px; line-height:20px; text-align:center; align-items:center; margin-right:4px; justify-content:center; font-size:20px;}
                &:hover{
                    padding-left:20px;
                }
            }
        }
    &:hover {
        .pofile-drop {display:block;}
    }
}

.mobilebusinesscontrols{
    .vagaro-avatar-sm{font-size:16px!important}
    .v-login{width:100%!important; height:auto!important;margin:10px; line-height: normal;
             .hideinmob{display:none!important;}
        .pofile-drop{position:static; display:block; transform:none!important; background-color:transparent; box-shadow:none; max-height:none; overflow:visible; width:100%;
                     .navigation-item{
                 display:flex; align-items:center;
                i{display:flex; width:32px; height:32px; line-height:20px; text-align:center; align-items:center; margin-right:4px; justify-content:center; font-size:20px;}
                &:hover{
                    padding-left:16px;
                }
            }

        }
    }
}

[data-link-key="call"]  {
    .v-button{min-width:121px}
}


#writereviewpopup, #VgReviewByEmployee, #Vgstaffreview, #vgSendMessage, .vgui-send-message, #vgGiftCertificte, #vgGridPhoto{
    .modal-content{font-family:var(--vg-body-font);color:#333; }
}
.vgn-popup{
    .modal-dialog{
        .modal-header{
            .modal-title{color:#333;font-family:var(--wbHeadFontFamily)!important;}
        }
        .modal-content{font-family:var(--vg-body-font); color:#333; }
    }
    .vgn-modal-alert{max-width:415px}
    .writereview-alert{
        .btn-close{display:none}
    }

    &.vgn-modal-alert{max-width:360px;
        .modal-footer{
            .v-button{min-width:96px}
        }
    }
}

.wbvg-passive-alert{position:fixed; left:0; width:100%; z-index:5555; text-align:center;font-family:var(--vg-body-font); display:none; overflow:hidden;
    &.warning{
        .wbvg-passive-alert-content{background-color:#ff9500; color:#fff}
    }
    &.success{
        .wbvg-passive-alert-content{background-color:#3e8438; color:#fff}
    }
    .wbvg-passive-alert-content{display:flex; max-width:fit-content; padding:12px 16px; border-radius:5px; font-size:16px; margin:auto;transform:translateY(-50px); transition:transform 300ms ease 0s; align-items:center; line-height:normal}
        .wbvg-passive-alert-icon{margin-right:8px;}

    &.wbvg-passive-show{ display:block;
        .wbvg-passive-alert-content{transform:translateY(0px)}
    }
}

html.wbMobile body{overflow:hidden;}

.utility-page-content {
    text-align: center;
}

.utility-page-wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.utility-page-content .space-30 {
    margin-top: 30px;
}

.common-btn-style.flamingo-button {
    padding: 8px 16px;
}

.vgn-popup .vgn-modal-alert.locationpopup { max-width:500px; height: 100%; margin-top: 0; margin-bottom: 0px;}
.vgn-popup .vgn-modal-alert.locationpopup .modal-body, .vgn-popup .vgn-modal-alert.locationpopup .modal-content { overflow:visible; } 
.vgn-popup .vgn-modal-alert.locationpopup .dropdown-content { width: 100%; max-height: 200px; overflow: auto;}
.vgn-popup .vgn-modal-alert.locationpopup .dropdown-content li { padding: 8px; font-family: var(--wbHeadFontFamily)!important;}
.vgn-popup .vgn-modal-alert.locationpopup .vg-wb-select::after { display: none;}
.vgn-popup .vgn-modal-alert.locationpopup .vg-wb-select .form-control { padding-left: 30px; font-family: var(--wbHeadFontFamily)!important;}
.vgn-popup .vgn-modal-alert.locationpopup .vg-wb-select::before {color: rgb(154, 154, 154);
font-family: "Font Awesome 5 Pro";font-size: 15px;font-weight: 400;left: 9px; position: absolute; top: 50%; transform: translateY(-50%);}
.location-model-hide { display: none;; }
@media (max-width: 767px) {
    .vgn-modal-alert.mobile-fullscreen  { margin: 0; padding: 0;
        .modal-dialog { margin: 0; padding: 0;}
        .modal-content { box-shadow: none; border-radius: 0; height: 100%;}
        .modal-header { display: none;}
        .v-button.secondary, .v-button.primary { width: 100%; height: 50px;}
        .modal-footer { border-top: solid #d9d9d9 1px; border-radius: 0;}
        .v-button.secondary { display: none;}
        
        
    }
    .search-control-location { width: 85%;}
    .location-model-hide { display: block; position: absolute; right:15px; top: 32px; color: var(--theme-color-1); }
    .vgn-popup .vgn-modal-alert.locationpopup .vg-wb-select .form-control { padding-right: 10px;}
    .vgn-popup .vgn-modal-alert.locationpopup .vg-wb-select .form-control { height: 50px;}
    .vgn-popup .vgn-modal-alert.locationpopup .dropdown-content { width: auto; border: 0; top: 65px; left:0px; position: fixed; right:0px; max-height:calc(100vh - 170px)!important;}
    .vgn-popup .vgn-modal-alert.locationpopup .dropdown-content li { padding: 8px 20px; font-size:16px;}
  }

