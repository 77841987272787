/* style.module.css */
.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr)); /* Default to 3 columns */
  max-width: 1024px;
  margin: 32px auto;
  padding: 64px 0px 48px 0px;
}

.gridItem {
  min-height: 300px;
  padding-bottom: 32px;
  text-align: center;
  flex-direction: column;
  justify-content: center; /* Align items vertically in the center */
  align-items: center; /* Align items horizontally in the center */
}

.gridContent {
  flex-grow: 1;
  /* Additional styles to center content within .gridContent, if needed */
  display: flex;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
}

.perfectShadow {
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.2) !important;
}

.staffTitle {
 
  padding-top: 13px; /* Padding based on the golden ratio */
  padding-bottom: 13px; /* Padding based on the golden ratio */
  text-align: left;
}

.staffBusinessSummaryText {
  text-align: left;
}
 
.staffProfileImage {
  width: 264px;
  height: 352px;
  object-fit: cover;
}

.RectStaffProfileImage {
  width: 264px;
  height: 352px;
  object-fit: cover;
}

.rectInitialPlaceholderWrapper {
  width: 264px;
  height: 352px;
  background-color: #333;
  display: flex;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
}
.rectStaffInitialPlaceholderImage {
  width: 264px;
  height: 352px;
  object-fit: cover;
  width: 100px; /* Adjust the width and height as needed */
  height: 100px; /* These values should be the same for a perfect circle */
  border: 1px solid #cdcdcd;
  color: #333;
  background-color: #fff;
  border-radius: 50%;
  font-size: 2.5em;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center; /* Vertically center content */
}

    /* Mobile */
    @media (min-width: 320px) {
      .RectStaffProfileImage {
        width: 100% !important;
        height: 352px;
        object-fit: cover;
      }
      .rectInitialPlaceholderWrapper {
        width: 100% !important;
        height: 352px;
        object-fit: cover;
       
      }
      .rectStaffInitialPlaceholderImage {
        width: 100% !important;
        height: 352px;
        object-fit: cover;
      
      }
       
}

  /* Mobile */
  @container (min-width: 320px) {
    .RectStaffProfileImage {
      width: 100% !important;
      height: 352px;
      object-fit: cover;
    }

    .rectInitialPlaceholderWrapper {
      width: 100% !important;
      height: 352px;
      object-fit: cover;
     
    }
    .rectStaffInitialPlaceholderImage {
      width: 100% !important;
      height: 352px;
      object-fit: cover;
    
    }
  }

/* Tablet */
@media (min-width: 600px) {
  .RectStaffProfileImage {
    width: 264px;
    height: 352px;
    object-fit: cover;
  }

  .rectInitialPlaceholderWrapper {
    width: 264px;
    height: 352px;
   
  }
  .rectStaffInitialPlaceholderImage {
    width: 264px;
    height: 352px;
    object-fit: cover;
  
  }
}

  /* Tablet */
  @container (min-width: 600px) {
    .RectStaffProfileImage {
      width: 264px;
      height: 352px;
      object-fit: cover;
    }
    
  }

/* Desktop */
@media (min-width: 1024px) {
  .RectStaffProfileImage {
    width: 264px;
    height: 352px;
    object-fit: cover;
  }

  .rectInitialPlaceholderWrapper {
    width: 264px;
    height: 352px;
   
  }
  .rectStaffInitialPlaceholderImage {
    width: 264px;
    height: 352px;
    object-fit: cover;
  
  }
}
  /* Desktop */
  @container (min-width: 1024px) {
    
    .RectStaffProfileImage {
      width: 264px;
      height: 352px;
      object-fit: cover;
    }
    .rectInitialPlaceholderWrapper {
      width: 264px;
      height: 352px;
     
    }
    .rectStaffInitialPlaceholderImage {
      width: 264px;
      height: 352px;
      object-fit: cover;
    
    }
  }

.initialPlaceholderWrapper {
  width: 264px;
  height: 352px;
  background-color: #333;
  display: flex;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
}
.staffInitialPlaceholderImage {
  width: 264px;
  height: 352px;
  object-fit: cover;
  width: 100px; /* Adjust the width and height as needed */
  height: 100px; /* These values should be the same for a perfect circle */
  border: 1px solid #cdcdcd;
  color: #333;
  background-color: #fff;
  border-radius: 50%;
  font-size: 2.5em;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center; /* Vertically center content */
}


.buttonContainerWrapper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

/* Show the div on hover */
.buttonContainerWrapper:hover {
  opacity: 1;
  visibility: visible;
}

.buttonContainer {
  width: 100%;
  text-align: center;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.25rem;

 /* Mobile */
@media (min-width: 667px) {
  flex-direction: column;
}

@container (min-width: 667px) {
  flex-direction: column;
  justify-content: center;
 
  align-items: center;
  margin: 0 auto;
}

 /* Tablet */
@media (min-width: 668px) and (max-width: 1098px) {
  flex-direction: column;
  justify-content: center;
 
  align-items: center;
  margin: 0 auto;
  width: 180px;
}

/* Tablet */
@container (min-width: 668px) and (max-width: 1098px) {
  flex-direction: column;
  justify-content: center;
  
  align-items: center;
  margin: 0 auto;
  width: 180px;
}
/* Desktop */
@media (min-width: 1099px) {
  flex-direction: column;
  justify-content: center;
 
  align-items: center;
  margin: 0 auto;
  margin: 0 auto;
  width: 180px;
}
@container (min-width: 1099px) {
  flex-direction: column;
  justify-content: center;

  align-items: center;
  margin: 0 auto;
  width: 180px;
}
}

.buttonText {
  color: white;
  font-size: 16px !important;
}


.customBookBtn:hover {
  background-color: #333 !important; /* Change the background color on hover */
}
 
 



/* Tablet adjustments (assuming tablets are between 601px and 900px) */
@media (max-width: 900px) {
  .gridContainer {
    grid-template-columns: repeat(2, minmax(200px, 1fr)); /* 2 columns for tablets */
  }
}

/* Mobile adjustments */
@media (max-width: 600px) {
  .gridContainer {
    grid-template-columns: 1fr; /* Stacked (1 column) for mobile */
  }
}

/* Tablet adjustments (assuming tablets are between 601px and 900px) */
@container (max-width: 900px) {
  .gridContainer {
    grid-template-columns: repeat(2, minmax(200px, 1fr)); /* 2 columns for tablets */
  }
}

/* Mobile adjustments */
@container(max-width: 600px) {
  .gridContainer {
    grid-template-columns: 1fr; /* Stacked (1 column) for mobile */
  }
}


/* Move this to RenderStaffMemberCards.module.css */

.cardWrapper {
  margin: 32px auto;
  padding: 8px;
  max-width: 1300px;
  
 
}