/*! PhotoSwipe main CSS by Dmytro Semenov | photoswipe.com */

.pswp {
    --pswp-bg:rgba(244, 244, 244, 0.96);
    --pswp-placeholder-bg: #222;
    
  
    --pswp-root-z-index: 100000;
    
    --pswp-preloader-color: rgba(79, 79, 79, 0.4);
    --pswp-preloader-color-secondary: rgba(255, 255, 255, 0.9);
    
    /* defined via js:
    --pswp-transition-duration: 333ms; */
    
    --pswp-icon-color: rgba(0,0,0,0.5);
    --pswp-icon-color-secondary: #333;
    --pswp-icon-stroke-color: rgba(0,0,0,0.5);
    --pswp-icon-stroke-width: 0px;
  
    --pswp-error-text-color: var(--pswp-icon-color);

  }
  
  
  /*
      Styles for basic PhotoSwipe (pswp) functionality (sliding area, open/close transitions)
  */
  
  .pswp {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: var(--pswp-root-z-index);
      display: none;
      touch-action: none;
      outline: 0;
      opacity: 0.003;
      contain: layout style size;
      z-index: 1037;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  
  /* Prevents focus outline on the root element,
    (it may be focused initially) */
  .pswp:focus {
    outline: 0;
  }
  
  .pswp * {
    box-sizing: border-box;
  }
  
  .pswp img {
    max-width: none;
  }
  
  .pswp--open {
      display: block;
  }
  
  .pswp,
  .pswp__bg {
      transform: translateZ(0);
      will-change: opacity;
  }
  
  .pswp__bg {
    opacity: 1 !important;;
      background: var(--pswp-bg);
  }
  
  .pswp,
  .pswp__scroll-wrap {
      overflow: hidden;
  }
  
  .pswp__scroll-wrap,
  .pswp__bg,
  .pswp__container,
  .pswp__item,
  .pswp__content,
  .pswp__img,
  .pswp__zoom-wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
  }
  
  .pswp__img,
  .pswp__zoom-wrap {
      width: auto;
      height: auto;
  }
  
  .pswp--click-to-zoom.pswp--zoom-allowed .pswp__img {
      cursor: -webkit-zoom-in;
      cursor: -moz-zoom-in;
      cursor: zoom-in;
  }
  
  .pswp--click-to-zoom.pswp--zoomed-in .pswp__img {
      cursor: move;
      cursor: -webkit-grab;
      cursor: -moz-grab;
      cursor: grab;
  }
  
  .pswp--click-to-zoom.pswp--zoomed-in .pswp__img:active {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing;
  }
  
  /* :active to override grabbing cursor */
  .pswp--no-mouse-drag.pswp--zoomed-in .pswp__img,
  .pswp--no-mouse-drag.pswp--zoomed-in .pswp__img:active,
  .pswp__img {
      cursor: -webkit-zoom-out;
      cursor: -moz-zoom-out;
      cursor: zoom-out;
  }
  
  
  /* Prevent selection and tap highlights */
  .pswp__container,
  .pswp__img,
  .pswp__button,
  .pswp__counter {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }
  
  .pswp__item {
      /* z-index for fade transition */
      z-index: 1;
      overflow: hidden;
  }
  
  .pswp__hidden {
      display: none !important;
  }
  
  /* Allow to click through pswp__content element, but not its children */
  .pswp__content {
    pointer-events: none;
  }
  .pswp__content > * {
    pointer-events: auto;
  }
  
  
  /*
  
    PhotoSwipe UI
  
  */
  
  /*
      Error message appears when image is not loaded
      (JS option errorMsg controls markup)
  */
  .pswp__error-msg-container {
    display: grid;
  }
  .pswp__error-msg {
      margin: auto;
      font-size: 1em;
      line-height: 1;
      color: var(--pswp-error-text-color);
  }
  
  /*
  class pswp__hide-on-close is applied to elements that
  should hide (for example fade out) when PhotoSwipe is closed
  and show (for example fade in) when PhotoSwipe is opened
   */
  .pswp .pswp__hide-on-close {
      // opacity: 0.005;
      opacity: 1;
      will-change: opacity;
      transition: opacity var(--pswp-transition-duration) cubic-bezier(0.4, 0, 0.22, 1);
      z-index: 10; /* always overlap slide content */
      pointer-events: none; /* hidden elements should not be clickable */
  }
  
  /* class pswp--ui-visible is added when opening or closing transition starts */
  .pswp--ui-visible .pswp__hide-on-close {
      opacity: 1;
      pointer-events: auto;
  }
  
  /* <button> styles, including css reset */
  .pswp__button {
      position: relative;
      display: block;
      width: 50px;
      height: 60px;
      padding: 0;
      margin: 0;
      overflow: hidden;
      cursor: pointer;
      background: none;
      border: 0;
      box-shadow: none;
      opacity: 0.85;
      -webkit-appearance: none;
      -webkit-touch-callout: none;
  }
  
  .pswp__button:hover,
  .pswp__button:active,
  .pswp__button:focus {
    transition: none;
    padding: 0;
    background: none;
    border: 0;
    box-shadow: none;
    opacity: 1;
  }
  
  .pswp__button:disabled {
    opacity: 0.3;
    cursor: auto;
  }
  
  .pswp__icn {
    fill: var(--pswp-icon-color);
    color: var(--pswp-icon-color-secondary);
  }
  
  .pswp__icn {
    position: absolute;
    top: 14px;
    left: 9px;
    width: 32px;
    height: 32px;
    overflow: hidden;
    pointer-events: none;
    @media (max-width: 767px){
      top: 7px;
    }
  }
  
  .pswp__icn-shadow {
    stroke: var(--pswp-icon-stroke-color);
    stroke-width: var(--pswp-icon-stroke-width);
    fill: none;
  }
  
  .pswp__icn:focus {
      outline: 0;
  }
  
  /*
      div element that matches size of large image,
      large image loads on top of it,
      used when msrc is not provided
  */
  div.pswp__img--placeholder,
  .pswp__img--with-bg {
      background: var(--pswp-placeholder-bg);
  }
  
  .pswp__top-bar {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 60px;
      display: flex;
    flex-direction: row;
    justify-content: flex-end;
      z-index: 10;
  
      /* allow events to pass through top bar itself */
      pointer-events: none !important;
  }
  .pswp__top-bar > * {
    pointer-events: auto;
    /* this makes transition significantly more smooth,
       even though inner elements are not animated */
    will-change: opacity;
  }
  
  
  /*
  
    Close button
  
  */
  .pswp__button--close {
    margin-right: 6px; opacity: 0.8;
    svg *{fill: #000;}

    &:hover{opacity: 1;}
  }
  
  
  /*
  
    Arrow buttons
  
  */
  .pswp__button--arrow {
    position: absolute;
    top: 0;
    width: 75px;
    height: 100px;
    top: 50%;
    margin-top: -50px;

    &:hover{
      svg * {fill: #212529;}
    }
  }
  
  .pswp__button--arrow:disabled {
    display: none;
    cursor: default;
  }
  
  .pswp__button--arrow .pswp__icn {
    top: 50%;
    margin-top: -30px;
    width: 60px;
    height: 60px;
    background: none;
    border-radius: 0;
  }
  
  .pswp--one-slide .pswp__button--arrow {
    display: none;
  }
  
  /* hide arrows on touch screens */
  .pswp--touch .pswp__button--arrow {
    visibility: visible;
  }
  
  /* show arrows only after mouse was used */
  .pswp--has_mouse .pswp__button--arrow {
    visibility: visible;
  }
  
  .pswp__button--arrow--prev {
    right: auto;
    left: 0px;
  }
  
  .pswp__button--arrow--next {
    right: 0px;
  }
  .pswp__button--arrow--next .pswp__icn {
    left: auto;
    right: 14px;
    /* flip horizontally */
    transform: scale(-1, 1);
  }
  
  /*
  
    Zoom button
  
  */
  .pswp__button--zoom {
    display: none;
  }
  
  .pswp--zoom-allowed .pswp__button--zoom {
    display: block;
  }
  
  /* "+" => "-" */
  .pswp--zoomed-in .pswp__zoom-icn-bar-v {
    display: none;
  }
  
  
  /*
  
    Loading indicator
  
  */
  .pswp__preloader {
    position: relative;
    overflow: hidden;
    width: 50px;
    height: 60px;
    margin-right: auto;
  }
  
  .pswp__preloader .pswp__icn {
    opacity: 0;
    transition: opacity 0.2s linear;
    animation: pswp-clockwise 600ms linear infinite;
  }
  
  .pswp__preloader--active .pswp__icn {
    opacity: 0.85;
  }
  
  @keyframes pswp-clockwise {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  
  /*
  
    "1 of 10" counter
  
  */
  .pswp__counter {
    display: none;
  }
  
  .pswp--one-slide .pswp__counter {
    display: none;
  }
  


  // custome designs 


 
 .vgphotos{
     display: flex; flex-wrap: nowrap;
 
     .vgphoto-item{ padding-right: 8px; cursor: pointer; position: relative; overflow: hidden;
         &:last-child{padding-right: 0;}
         img{
             background-color: #cdcdcd; width: 100%; height: 118%; background-repeat: no-repeat; background-position: center center; background-size: cover;
         }
         &.moreitem{width: 200px;}
 
         .morecount{position: absolute; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; background: rgba(0,0,0,0.3); top: 0; left: 0; z-index: 3; color: #fff;}
     }
 }
 
 
 .vgStylePhotos{
     max-width: 100%;
     &::after {
         content: '';
         display: block;
         clear: both;
       }
       .vgphoto-m-item-sizer, .vgphoto-m-item{width:calc(33% - 5px);
        @media (max-width:864px){
          width:calc(50% - 5px);
        }
        @media (max-width:584px){
          width:calc(100% - 5px);
        }
      }
       .vgphoto-m-item{float: left; margin-bottom:0; padding: 10px; border-radius: 10px; overflow: hidden; cursor: pointer;
         img{display: block;max-width: 100%; border-radius: 5px;}
         &:hover{
             background: #f5f5f5;
         }
         
     }
 }

  .portfolio-images{display: flex; margin: 0 -10px;
    @media (max-width: 767px){
      display: block;
    }
    .portfolio-image{flex: 0 1 auto; padding: 0 10px; width: 100%;
      .img-fluid{max-width: 700px !important; width: 100%;}
    }
    .title-dec{padding-top: 5px;}
  }

  .gallary-top-meta{display: flex;
    .button-icon{
      
      

      svg{width: 18px;
        path{fill: currentColor;}
      }
      &.liked{
        color: red;
      }
    }
    .left-metas{display: flex;
      .send-button{padding-left: 8px;
        .dropdown-menu{min-width: 200px;}
        .v-button.secondary{padding: 11px;}
      }
      .btnlike{cursor: pointer; padding: 11px;}
    }
  }

  .meta-btns{margin-left: auto; margin-right: 0;
    .v-button{margin: 0!important; border-color: inherit;
      &:first-child{margin-right: -1px!important; border-top-right-radius: 0;border-bottom-right-radius: 0; padding: 8px 16px; border-top-left-radius: 3px; border-bottom-left-radius: 3px;}
      &:last-child{margin-right: -1px!important; border-top-left-radius: 0;border-bottom-left-radius: 0; padding: 8px 16px; border-top-right-radius: 3px; border-bottom-right-radius: 3px; border-left: 1px solid #00000024;}
    }
  }

  .sharePopOver{ position: absolute; z-index: 100000; background: #fff; padding: 10px; color: #333; border-radius: 3px; box-shadow: 2px 2px 9px 0 #0003; width: 100%; max-width: 240px; padding-bottom: 0; overflow: hidden;
    .share-style-div{margin: 0 -10px; padding: 10px; background: #f5f5f5;}

    .share-social-icon-div{display: flex; margin-top: 8px;
      .share-social-icon{width: 30px; height: 30px; border-radius: 30px; margin-right: 10px; line-height: 1; text-align: center; padding-top: 6px;
        color: #fff; cursor: pointer;
        svg{
          *{fill: currentColor;}
        }
        &.facebook{background-color: #3a589b;}
        &.twitter{background-color: #00aced;}
        &.pinterest{background-color: #cb1f27;}
      }
    }
  }
  .portfolio-cardcontent{ padding-top: 15px; padding-bottom: 10px;
    .heading{font-size: 32px;font-family: var(--wbHeadFontFamily); font-weight: 600;}
  }
  
  .portfolio-dec{padding-top: 20px;
    .portfolio-dec-head{font-weight: 600; font-size: 20px; padding-bottom: 10px;font-family: var(--wbHeadFontFamily);}
  }

.pswp__content{
  .htmlContainerPhoto{
    font-size: 16px; padding: 20px 36px; background-color: #fff; box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.2); border-radius: 5px; max-height: calc(100% - 80px); overflow: auto;
  }
 

  .pswp-html-content{ height: 100%; padding: 0 5%; width: 100%; display: flex; align-items: center; justify-content: center;}
}
.pswp, .vgphoto-m-item-card-list {
  .loaderNew{position: absolute;}
}
.vgphoto-m-item-card-list{

  position: relative;
  img{width: 100%;}
  .gallary-top-meta{position: absolute; top: 0; left: 0; width: 100%; visibility: hidden; padding: 5px;}

  .portfolio-cardcontent .heading{
    font-size: 16px; font-weight: 600; 
  }
  
}

.vgphoto-m-item{
  &:hover, &:focus{
    .gallary-top-meta{visibility: visible;}
  }
  &.hoverd{
    .gallary-top-meta{visibility: visible;
      
    }
  }
}

.pswp--no-mouse-drag.pswp--zoomed-in .pswp__img, .pswp--no-mouse-drag.pswp--zoomed-in .pswp__img:active, .pswp__img{cursor: default}

.vgPhotoGridview{
  .modal-content{max-width: 1000px;
    .modal-body{padding-top: 0; padding-right: 10px; padding-left: 10px;
      .vgStylePhotos{margin-right: -10px; max-width: none;}
    }
  }
  
}

.htmlcontitemmax{max-width: 100%; width: 698px;}
.htmlContainerPhotoMulti{
  .htmlcontitemmax{max-width: 100%; width: 100%;}
}

.justify-content-modal{
  justify-content: center;
}

.vgPhotoGridview{
  max-width: 1000px;
  @media(max-width:991px){
    max-width: 98%;
  }
}