.outerWrapper {
  padding: 7px 13px 13px 13px;
}
.reviewsContainer {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
}
.review {
  display: flex;
  justify-content: flex-start;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

/* Mobile Devices */
@media (max-width: 576px) {
  .review {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
    width: 100%;
    
    margin: 0 auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  .metaContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 13px; /* Approximately 8px * 1.618 */
    padding-left: 8px;
    padding-right: 13px;
    padding-bottom: 8px;
  }

  /* More mobile styles here */
}

/* Mobile Devices */
@container (max-width: 576px) {
  .review {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
    width: 100%;
    
    margin: 0 auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  .metaContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 13px; /* Approximately 8px * 1.618 */
    padding-left: 8px;
    padding-right: 13px;
    padding-bottom: 8px;
  }
}

/* Reviewer styling here. */
.metaContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 13px; /* Approximately 8px * 1.618 */
  padding-left: 8px;
  padding-right: 13px;
}

.reviewsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
 
  margin-bottom:16px;
}

.serviceProviderResponseWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  gap: 12px;
  padding-bottom: 8px;
}

.venueResponseWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  gap: 12px;
  padding-bottom: 8px;

}

.circleWrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(76, 207, 76) !important; /* Green background */
  color: white; /* White text color */
  border-radius: 50%; /* Makes the shape a circle */
  /* Adjust as needed */
  font-size: 12px; /* Adjust font size as needed */
  width: 20px;
  height: 20px;
}

.username {
  font-size: 1rem !important;
  line-height: 1.25rem !important;
  font-weight: bold !important;
  color: #333;
 
}

.date {
  font-size: 0.875rem;
    line-height: 1.25rem;
  color: #333;
}

.rating {
  font-size: 1.25rem;
  color: #f5a623;
  margin-bottom: 10px;
}

.status {
  font-size: 1rem;
  color: #f5a623;
  padding-top: 8px;
 
}

.comment {
  font-size: 1rem;
  color: #333;
 
}
