.vgui-send-message{max-width: 666px;
    .v-button.primary{min-width: 120px;}

    .modal-content .modal-body{padding-bottom: 0; overflow: visible;}
    .form-content{margin-bottom: 0;}
}
.bnameHead{display: flex; align-items: center;
    .bimage{background-color: #e7e7e7; border: 1px solid #cdcdcd; display: flex; align-items: center; justify-content: center; font-size: 12px; font-weight: 300; line-height: 1;color: #212529; width: 32px; height: 32px; border-radius: 32px; margin-right: 18px; background-position: 50% 50%; background-size: cover;}
}

.name-email-div-flex{display: flex; justify-content: space-between; align-items: flex-start; padding-bottom: 20px;
    .form-row{max-width: 49%; width: 100%; margin: 0;}
}