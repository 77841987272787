.container-query-target {
	container-type: inline-size;
}

.staff-grid-container {
	margin: 0 auto;
	display: grid;
	max-width: 98%;
	grid-template-columns: repeat(1, 1fr); /* Default to 1 column for mobile */
	grid-auto-rows: 1fr;
	gap: 4px; /* Adjust gap as needed */
	/* Adjust padding as needed */
	padding: 8px;
}

/* Tablet: 2 columns */
@media (min-width: 600px) {
	.staff-grid-container {
		grid-template-columns: repeat(2, 1fr);
		grid-auto-rows: 1fr;
	}
}

/* Desktop: 3 columns */
@media (min-width: 1024px) {
	.staff-grid-container {
		grid-template-columns: repeat(3, 1fr);
		grid-auto-rows: 1fr;
	}
}
@container (min-width: 320px) {
	.staff-grid-container {
		grid-template-columns: repeat(1, 1fr);
	}
}

@container (min-width: 600px) {
	.staff-grid-container {
		grid-template-columns: repeat(2, 1fr);
	}
}

/* Desktop: 3 columns */
@container (min-width: 1024px) {
	.staff-grid-container {
		grid-template-columns: repeat(3, 1fr);
	}
}

.staff-grid-item {
	/* Style your grid items */
	text-align: center;
	height: 100%;
	background-color: #fff;
	/* border-width: 1px; 
	border-style: solid; 
	border-color: #ccc; */
}

 
.alt-profile-image {
	background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform 0.4s;
    transition: -webkit-transform 0.4s;
    -o-transition: transform 0.4s;
    transition: transform 0.4s;
    transition: transform 0.4s, -webkit-transform 0.4s;
    width: 400px !important;
    height: 500px !important; /* Adjusted for 4:3 aspect ratio */
;
}

@media (min-width: 320px) {
	.alt-profile-image {
		background-size: cover;
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
		-webkit-transition: -webkit-transform 0.4s;
		transition: -webkit-transform 0.4s;
		-o-transition: transform 0.4s;
		transition: transform 0.4s;
		transition: transform 0.4s, -webkit-transform 0.4s;
		width: 200px;
		height: 300px;
		margin: auto;
		background-repeat: no-repeat !important;
	}
}

@container (min-width: 320px) {
	.alt-profile-image {
		background-size: cover;
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
		-webkit-transition: -webkit-transform 0.4s;
		transition: -webkit-transform 0.4s;
		-o-transition: transform 0.4s;
		transition: transform 0.4s;
		transition: transform 0.4s, -webkit-transform 0.4s;
		width: 200px;
		height: 300px;
		margin: auto;
		background-repeat: no-repeat !important;
	}
}

/* Desktop: 3 columns */
@container (min-width: 1024px) {
	.alt-profile-image {
		background-size: cover;
		width: 300px;
		height: 400px;
		margin: auto;
		background-repeat: no-repeat !important;
	}
}

.profile-card-relative {
	position: relative; /* Set the card to relative positioning */
	/* Other styling for the card (width, height, padding, etc.) */
	padding-bottom: 60px; /* Add padding to prevent content overlap with the button */
}

.buttons-at-bottom {
	position: absolute; /* Absolute positioning for the button */
	bottom: 0px; /* Distance from the bottom of the card */
	left: 50%; /* Center the button horizontally */
	transform: translateX(-50%); /* Adjust for exact centering */
	/* Additional styling for the button */
}

.alternating-card {
	/* Style your grid items */
	height: screen;
	border-width: 1px; /* Width of the border */
	border-style: solid; /* Style of the border */
	border-color: #ccc; /* Color of the border */
	/* Background color of the card */
}

 
.flex-row-normal {
	flex-direction: row;
	flex-basis: 100%;
	width: 100%;
	justify-content: space-between;
}

.flex-row-reverse {
	flex-direction: row-reverse;
	justify-content: space-between;
	flex-basis: 100%;
}

.alternating-card:nth-child(odd) {
	background-color: #f2f2f2;
}

.profile-alt-row {
	display: flex;
	padding-top: 16px;
	padding-bottom: 16px;
	gap: 4px; /* Adjust gap as needed */
	/* Adjust padding as needed */
	padding-left: 4px;
	padding-right: 4px;
}

@container (min-width: 320px) {
	.profile-alt-row {
		display: flex;
		flex-direction: column;
	}
}

@container (min-width: 600px) {
	.profile-alt-row {
		grid-template-columns: repeat(2, 1fr);
	}
}

/* Desktop: 3 columns */
@container (min-width: 1024px) {
	.profile-alt-row {
		grid-template-columns: repeat(3, 1fr);
	}
}

 



.perfect-shadow {
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.2) !important;
}

.container-query-target {
  container-type: inline-size;
}

/* ucq: use container queries */
.ucq .col-sm-6,
.ucq .col-md-4,
.ucq .col-lg-3,
.ucq .col-12 {
  width: 100%;
	/* background-color: red; */
}

@container (min-width: 576px) {
  .ucq .col-sm-6 { 
		width: 100%; 
		/* background-color: rgb(255, 127, 225); */
	}
}

@container (min-width: 576px) {
  .ucq .col-md-4 { 
		width: 100%; 
		/* background-color: aquamarine; */
	}
}


@container (min-width: 992px) {
  .ucq .col-lg-3 { 
		width: 25%; 
		/* background-color: cornflowerblue; */
	}
}

/* Add this in your CSS file */
@media (max-width: 576px) {
	.mobile-text-align-left {
			text-align: left !important;
	}
	.mobile-text-align-center {
		text-align: center !important;
}
}

/* Add this in your CSS file */
@container (max-width: 576px) {
	.mobile-text-align-left {
			text-align: left !important;
	}
	.mobile-text-align-center {
		text-align: center !important;
}
}
 