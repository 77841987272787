.vgui-reviewby-employee{max-width: 786px;
    .modal-content {
        .modal-header{padding-top: 0; z-index: 3; padding: 1rem 1rem;
        
        }
        .modal-body{overflow: hidden; display: flex; flex-direction: column; font-size: 16px;
            @media (max-width: 767px){
                overflow-y: auto;
            }
            @container (max-width: 767px){
                overflow-y: auto;
            }
            .review-top{margin-bottom: 10px;}
            .reviewbody{flex: 1; overflow: auto;
            @media (max-width: 767px){
                overflow: initial;
            }
            @container (max-width: 767px){
                overflow: initial;
            }
            .review-body-title{
                display: flex;
                
            }
            .reviewbyemployee{
              
                .review-item{
                    .review-item-head{
                        .review-item-meta{
                            .review-name, .review-date{
                                box-shadow: none !important;
                                background: none !important;
                                text-shadow: none !important;
                                color: #333 !important;
                                text-align: left !important;
                            }
                        }
                    }
                    .review-text-row {
                        h3, p{
                            box-shadow: none !important;
                            background: none !important;
                            text-shadow: none !important;
                            color: #333 !important;
                            text-align: left !important;
                        }

                    }
                }

               
            }
            .bodyhasFilter {
                min-height: 274px;
            }
            }
            .review-sorting{margin-right: 10px;
            @media (max-width: 767px){
                display: block;
            }
            @container (max-width: 767px){
                display: block;
            }
            }
        }        
    }
}

.reviewbyemployee{

    overflow: auto;


}
.reviewbtnparent.bethe-first {
    justify-content: flex-start !important;
}
.review-body-title{ margin-top: 0px;
    @media (max-width: 767px){
        display: block !important;
    }
    @container (max-width: 767px){
        display: block !important;
    }
    .page-title{
        font-size: 20px; line-height: 24px;
    }
}
.reviewbody.bodyhasFilter {
    min-height: 274px;
    flex-direction: column;
    display: flex;
}


#writereviewpopup {
    .modal-header{position: relative; z-index: 5;}
    .review-body-title{ margin-top: 0px;}
}
#writereviewpopup {
    .modal-header{position: relative; z-index: 5;}
    .review-body-title{ margin-top: 0px;}
}
.review-data-popup{
    .review-item-head {
        .review-name, .review-date {
            box-shadow: none !important;
            background: none !important;
            text-shadow: none !important;
            color: #333 !important;
            text-align: left !important;
        }
    }
    .review-text-row{
        h3, p{
        box-shadow: none !important;
        background: none !important;
        text-shadow: none !important;
        color: #333 !important;
        text-align: left !important;
        }
    }
}

.stenciled {
    .stencil-item {
        .review-date {
            color: transparent !important;
        }
    }
}


.review-item{
    padding-top: 25px; padding-bottom: 25px;
    & + .review-item{border-top: 1px solid rgba(0, 0, 0, 0.15);}
}

.review-stars{
    &.vg-star-rating{ display: flex; align-items: center;margin-top: 5px; color: #f6d645; gap:2px;
        .star{color: #f6d645; font-size: 16px; margin-right: 1px;}
        .verified{color: #3e8438!important; padding-left: 7px; font-size: 16px; font-weight: 600; display: flex; align-items: center; padding-top: 1px;
            i{font-size: 12px; width: 20px; height: 20px; line-height: 20px; margin-right: 5px;color: #fff!important; background: #3e8438; border-radius: 20px; text-align: center; padding-top: 1px;}
        }
        .reviewcount{font-size: 16px; padding-left: 5px;}

        svg{width: 18px!important; height: 18px!important; margin-right: 2px;}

        .gap-large{color: #333;
                   @media (max-width: 767px){

                margin-left:5px;

            }
             @container (max-width: 767px){

                margin-left:5px;

            }
        }

    }
}
.review-item-head{display: flex; padding-bottom: 3px;
    .review-profile{margin-right: 15px;}
    .review-name{font-size: 1.25em; font-weight: 600; line-height: 24px;}
    .review-date{font-weight: 400; line-height: 20px;}
    
}

.review-text-row{ padding-top: 10px; padding-bottom: 10px;
    h3{padding: 0; margin: 0; font-size: 20px; line-height: 1.8; font-weight: 600;}
    p{word-wrap: break-word; margin-bottom: 0;}
}

html .reviewhead{display: flex; padding-bottom: 24px; border-bottom: 1px solid rgba(0, 0, 0, 0.15); margin-bottom: 10px;
    @media (max-width: 767px){
        display: block;
    }
    @container (max-width: 767px){
        display: block;
    }
    .review-emp-profile{flex: 1; 
    @media (max-width: 767px){
        text-align: center;
    }
    @container (max-width: 767px){
        text-align: center;
    }
    }
    .reviewimg{max-height: 260px; max-width: 260px; margin-right: 25px;
        @media (max-width: 767px){
            margin: 0 auto 30px;
        }
        @container (max-width: 767px){
            margin: 0 auto 30px;
        }
        img{max-width: 100%; max-height: 100%; object-fit: cover; height: 100%; width: 100%;
        @media (max-width: 767px){
            width: auto;
            display: block;
            margin: 0 auto;
        }
        @container (max-width: 767px){
            width: auto;
            display: block;
            margin: 0 auto;
        }
        }
    }
    h3{padding: 0; margin: 0; font-size: 32px; font-weight: 600; line-height: 36px; padding-bottom: 0px; color: #333;
    @media (max-width: 767px){
        text-align: center !important;
    }
    @container (max-width: 767px){
        text-align: center !important;
    }
    }
    p{line-height: 1.35;}

    .vg-star-rating{padding-bottom: 8px; color: #f6d645;
        @media (max-width: 767px){
            justify-content: center;
        }
        @container (max-width: 767px){
            justify-content: center;
        }
       svg{width: 20px!important; height: 20px!important;

            @media (max-width: 767px){

                width: 21px!important; height: 21px!important;


            }
            @container (max-width: 767px){

                width: 21px!important; height: 21px!important;

            }

        }
    }
    &.noimg{margin-bottom: 0;
        .vg-star-rating{}
        p{margin-bottom: 0;}
    }
}

.review-sorting{display: flex; 
    
    .dropdown{
        .w-button{width: 77px; text-align: center; display: flex; align-items: center; height: 40px;
            i{font-size: 18px; line-height: 1; margin: 3px 5px 0px 0;}
            .angle{font-size: 12px; margin: 0;}
        }
        & + .dropdown{margin-left: 10px;}

        .v-button{display: flex; align-items: center; width: 100%; justify-content: center;
            svg{}
            svg:first-child{ margin-right: 6px; }
        }
    }
    .dropdown + .w-button {margin-left: 10px;}

    
}
.vgn-popup{
    .page-title.review-text-head{font-size: 20px; font-family: var(--wbHeadFontFamily)!important; padding: 0;
        @media (max-width: 767px){
            margin-bottom: 10px;
        }
        @container (max-width: 767px){
        margin-bottom: 10px;
    }
    }
    .review-text-row{ 
        h3{color: #333!important;}
        p{color: #333!important;}
    }
}

.stenciled{
    .review-text-row{
        h3{color: transparent!important;}
        p{color: transparent!important;}
    }
}

.review-sorting.gap-small .dropdown-menu {
    right: 0!important;
    left: auto!important;
    padding-top: 0px;
}
.review-sorting.gap-small {
    @media (max-width: 767px){
        display: flex;
        height: auto;
        flex-flow: column-reverse;

        .dropdown:first-child .dropdown-menu{
            right: auto !important;
        }
    }
    @container (max-width: 767px){
        display: flex;
        height: auto;
        flex-flow: column-reverse;

        .dropdown:first-child .dropdown-menu{
            right: auto !important;
        }
    }
    button{
        @media (max-width: 767px){
        margin: 0px 0px 10px !important;
        width: 100% !important;
        }
        @container (max-width: 767px){
            margin: 0px 0px 10px !important;
            width: 100% !important;
            }
    }
    .review-filter-section{
        display: flex;
        @media (max-width: 767px){width: 100%; margin-bottom: 10px;}
        .dropdown{
            width: inherit;
        }
        @container (max-width: 767px){width: 100%; margin-bottom: 10px;}
        .dropdown{
            width: inherit;
        }
    }
}  

.vg-custom-style{
    box-shadow: none !important;
    background: none !important;
    text-shadow: none !important;
    color: #333 !important;
    text-align: left !important;
}
.review-icon-rating-staff {
  svg.review-star
  {
    width: 20px !important;
    height: 20px !important;
  }
   svg {
    width: 20px !important;
    height: 20px !important;
}
}