/* * Prefixed by https://autoprefixer.github.io * PostCSS: v8.4.14, * Autoprefixer: v10.4.7 * Browsers: last 4 version */
:root{
    --editor-transparent: #3E843877;
    --editor-primary: #3E8438;
    --editor-hover: #3f6d3b;
    --section-default-height:450px;
    --outline-primary: #3E8438;
    --outline-hover:#3f6d3b;
    --default-animation-duration: 0.5s;
    --default-animation-delay:.2s;
}
*{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.v-product-details{
    width:100%;
}
.review-user-image{
    -o-object-fit: cover;
    object-fit: cover;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
}
.v-image-grid{
    overflow: hidden;
}

.review-user-image.mr15{

    cursor: pointer;
}
.review-user-row{
   margin-top: 10px;
    margin-bottom: 10px;
}
.v-product-list{
    min-height: 500px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.v-button .dropdown, .v-button .comp-drop-down{
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top:0;
    background: none !important;
    
    
}

.v-button .dropdown .dropdown-toggle{

    position: absolute;
    height: 100%;
    width: 100%;
    top:0;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    font-weight: inherit;
    text-transform: inherit;
    text-decoration: inherit;
    color: inherit;
}


.v-button .dropdown-menu{

   min-width: 100%;
}
.dropown-caret::after{
    
  display: inline-block;
margin-left: 0.5em;
vertical-align: 0.255em;
content: "";
border-top: 0.3em solid;
border-right: 0.3em solid transparent;
border-bottom: 0;
border-left: 0.3em solid transparent;

}
.inherit-font-w{
    font-weight: inherit;
    font-family: inherit;
    font-size: inherit;
    text-transform: inherit;
}


.flex-row-to-column , .flex-row-to-column-tablet, .flex-row-to-column-475{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.v-button .dropdown-toggle::after {
   
    margin-left: 0.5em !important;

  }
  
  .tracked-dropdown{
    background: white;
    padding: 4px 0px;
    border-radius: 4px;
    box-shadow: 0px 2px 4px 0px #0000001a;
  }
  .tracked-dropdown-item{

    background: white !important;
    color: #333 !important;
    display: block !important;
    text-align: left !important;
    outline: none !important;
    border: none !important;
    padding: .5em 1em !important;

  }

  .tracked-dropdown-item:hover,  .tracked-dropdown-item.selected{

    background: #ebebeb !important;

  }

.gift-certificate-contianer{
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;
}
.gc-cards-grid .gift-card{
    padding: 12px;
}
.gc-cards-grid .gift-card .inner-gift-card{
    height: 220px;
    border-radius: 5px;
    background-position: 0 0 !important;
}
@container (max-width: 400px) {
    .flex-row-to-column{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
   }
    .text-align-left-mobile{
        text-align: left !important;
   }
    .text-align-right-mobile{
        text-align: right !important;
   }
   .text-align-center-mobile{
    text-align: center !important;
    }
}

.mobile-break    .text-align-center-mobile{
    text-align: center !important;
    }


@media (max-width: 400px) {
    .flex-row-to-column{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
   }
    .text-align-left-mobile{
        text-align: left !important;
   }
    .text-align-right-mobile{
        text-align: right !important;
   }
   .text-align-center-mobile{
    text-align: center !important;
    }
}
@container (max-width: 767px) {
    .flex-row-to-column-tablet{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
   }
}
@media (max-width: 767px) {
    .flex-row-to-column-tablet{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
   }
   .review-profile-inner-div {
    outline: none !important;
}

.render .cta-container{
    display: block;
   }

}

.mobile-break .flex-row-to-column{
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.tablet-break .flex-row-to-column-tablet{
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
html{
    scroll-behavior: smooth ;
}
.v-site{
    position: relative;
    scroll-behavior: smooth ;
}
.v-site h1, .v-site h2, .v-site h3, .v-site h4{
    margin: 0px;
}
.v-site h4{
    font-size: 1.2em;
}
.v-site h3{
    font-size: 1.5em;
}
.v-site h2{
    font-size: 1.75em;
}
.v-site h1{
    font-size: 2em;
}
.invisible-button {
    outline: none;
    border: none;
    cursor: pointer;
}
.strikethrough{
    text-decoration: line-through;
    opacity: .5;
}
.nice-scroll::-webkit-scrollbar {
    width: 10px;
    border-radius: 4px;
}
.nice-scroll::-webkit-scrollbar-track {
    background: #eee;
    border-radius: 4px;
}
.content-centering-spacer {
    text-align: center;
}
.nice-scroll::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 4px;
}
.nice-scroll::-webkit-scrollbar-thumb:hover {
    background: #ccc;
}
.canvas{
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    max-height: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}
.hierarchy{
    -webkit-overflow-scrolling: touch;
    background-position: center;
    background-size: cover;
    margin:0;
}
.v-page-root{
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.v-site .v-page-root{
    min-height: 100vh;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
}
.inline-container>*{
    display: inline !important;
}
.v-spacer{
    width:50px;
    height:50px;
}
.v-iframe{
    max-width: 100%;
    max-height: 100%;
}
iframe{
    width: 100%;
    height: 100%;
    border: none;
}
.v-section {
   /* background: #d1d1d1;
   */
    width: 100% !important;
    display: -webkit-box;
    display: -ms-flexbox;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    /* overflow: hidden; */
    height: auto;
    min-height: 60px !important;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
   /* height: var(--section-default-height);
    */
}
.v-section.v-navigation-bar{
    min-height: 32px !important;
    background: white;
    font-weight: 600;
}
.inner-section{
    display: inherit;
    height: 100%;
    gap: inherit;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    display: inherit;
}
.resizable-container:not(.v-navigation-bar){
    container-type: inline-size;
}
.v-site.render .v-navigation-bar.fixed-above, .wbheader.fixed-above, .render .announcement.fixed-above, .fixed-above.fixed-container{
    position: sticky;
    top: 0;
    z-index: 11;
}

.announcement{

    text-align: center;
}

.announcement+button:hover{
    opacity: .75;
   
}

.v-site.render .v-navigation-bar.fixed{
    position: absolute;
    top: 0;
}
.v-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    min-height: 12px;
    -webkit-box-flex:1;
    -ms-flex:1 0 auto;
    flex:1 0 auto;
    padding:4px;
    overflow: hidden;
    padding: var(--default-padding);
    position: relative;
}
.v-col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
 
    -webkit-box-flex: 1;
    -ms-flex: 1 0;
    flex: 1 0 ;
    position: relative;
    overflow: hidden;
}
.v-facility{
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
}
.relative {
    position: relative;
}
.inner-column{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: inherit;
    -webkit-box-direction: inherit;
    -ms-flex-direction: inherit;
    flex-direction: inherit;

 
    width: 100%;
    min-height: 100%;
    gap:inherit;
    -webkit-box-pack: inherit;
    -ms-flex-pack: inherit;
    justify-content: inherit;
    -webkit-box-align: inherit;
    -ms-flex-align: inherit;
    align-items: inherit;
    -ms-flex-line-pack: inherit;
    align-content: inherit;
    padding: var(--default-padding);
}
.v-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    min-width: 20px;
    min-height: 20px;
    overflow: hidden;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    max-width: 100%;
    max-height: 100%;
    padding: var(--default-padding);
    position: relative;

}


.v-container >*{
    -ms-flex-negative: 0;
    flex-shrink: 0;
    pointer-events: all;
}
.force-btn-size{
    height: 40px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.hierarchy.v-image{
    overflow: hidden !important;
}
.v-map{
    width:400px;
    height: 300px;
    overflow: hidden;
}
.mobile-navigation-content .pofile-drop.dropdown-menu{
    left: 50% !important;
    right:auto !important;
    transform: translateX(-50%);
}
.b-panel-container{
    display: flex;
}
.v-button{
    outline:none;
    border-style: none;
    display: inline-block;
    text-decoration: none;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    color: inherit;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    border-radius: 0px;
    background-color: white;
    padding:.5em 1.5em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    max-width: 100%;
    max-height: 100%;
    text-align: center;
    -webkit-transition: background-color .2s;
    -o-transition: background-color .2s;
    transition: background-color .2s;
    border-width: 1px;
    border-style: none;
    border-color: black;
}

.vwb-absolute.v-button{
    max-width: initial;
    max-height: initial;
}
.v-button-with-icon{
   display: -webkit-inline-box;
   display: -ms-inline-flexbox;
   display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: inherit;
}
.v-button-with-icon svg{
   margin-right: 5px;
}
.vagarodefault-sec.secondary{
   border-color: #cdcdcd;
    background: #fff;
    color: #333;
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    padding: 9px 16px;
    line-height: 20px;
}
.vagarodefault-sec.secondary:hover{
   background: #f8f8f8;
}
.v-text, v-heading{
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
    margin: 0px;
    overflow: hidden;
}
.vwb-absolute.v-text{
    max-width: initial;
    max-height: initial;
}
.v-image{
    -o-object-fit: cover;
    object-fit: cover;
}
.image-place-holder{
    overflow: hidden;
}
.inner-column>.hierarchy{
    -ms-flex-negative: 0;
    flex-shrink: 0;
}
.v-row>.hierarchy{
    -ms-flex-negative: 0;
    flex-shrink: 0;
}
.v-footer{
    background-color: #888;
    width: 100% !important;
    z-index: 10;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.preview-screen .v-footer{
    z-index: 11;
}
.v-footer.wl{
    padding-bottom: 40px !important;
    overflow: visible !important;
}

.v-site .v-footer.wl{
    padding-bottom: 40px !important;
    overflow: visible !important;
}

.canvas .v-footer.wl{
    padding-bottom: 40px !important;
    overflow: visible !important;
}

.v-image-slider>div>div>button:nth-child(3){

    margin: -27px 0px 0px !important;
    right: 10px !important;
}
.v-image-slider>div>div>button:nth-child(2){


    left: 10px !important;
}

.overflow-auto{
    overflow: auto;
}

.mobile-navigation .profile-drop-menu{
    color:inherit !important;
}
.mobile-navigation .pofile-drop.dropdown-menu{
    color:inherit !important;
}

.v-navigation-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: auto;
    max-width: 100%;
    max-height: 100%;
    background-color: none;
    list-style: none;
    display: flex;
    gap:30px;
    color: inherit;
    margin: 0px;
    padding: 0px;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    overflow: visible;
    position: relative;
    row-gap: 6px !important;
}
.v-business-social-links{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap:1em;
    color: inherit;
}
.v-business-social-links a{
    color: inherit;
}
.hours-table{
    margin: auto;
    border-collapse: collapse;
    color:inherit;
}
.service-title{
    text-align: left;
}
/* @container (max-width:1000px){
    .service-card{
        width: calc(50% - 8px) !important;
   }
}
*/
@container (max-width: 767px) {
    .full-width-tablet{
        width:100% !important;
   }
    .half-width-tablet{
        width:50% !important;
   }
   .mobile-navigation-content .pofile-drop.dropdown-menu{
    width: 100%;
   }
   .mob-nav-list-item {
    padding: 0px !important;
}
}
@media (max-width: 767px) {
    .full-width-tablet{
        width:100% !important;
   }
    .half-width-tablet{
        width:50% !important;
   }
}
.tablet-break .full-width-tablet{
    width:100% !important;
}
.tablet-break .half-width-tablet{
    width:50% !important;
}
@container (max-width: 400px) {
    .full-width-mobile{
        width:100% !important;
   }
}
@media (max-width: 400px) {
    .full-width-mobile{
        width:100% !important;
   }
}

@container (max-width: 475px) {
    .full-width-475{
        width:100% !important;
   }

   .top-right-475{
    position: absolute;
    top: 0;
    right: 0;

   }

     .flex-row-to-column-475{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    }
}
@media (max-width: 475px) {
    .full-width-475{
        width:100% !important;
   }
   .top-right-475{
    position: absolute;
    top: 0;
    right: 0;

   }

  .flex-row-to-column-475{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    }
}
.mobile-break .full-width-mobile{
    width:100% !important;
}
.hours-table td{
    padding: .2em 8px;
}
.hours-table tr td:last-child{
    text-align: right;
}
.hours-table td:first-child{
    padding-right: 40px;
}
@container (max-width: 350px) {
    .full-width-mobile-half{
        width: 100% !important;
   }
}
@media (max-width: 375px) {
    .full-width-mobile-half{
        width: 100% !important;
   }
}
.v-navigation-bar .hierarchy{
    overflow: visible !important;
}
.v-navigation-list .back-btn{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    font-weight: inherit;
    text-transform: inherit;
    gap:.5em;
    padding-left: 10px;
}
.v-navigation-bar{
    display: flex;
    align-items: center;
    position: relative;
    padding: 6px;
    z-index: 10;
    overflow: visible !important;

}
.ql-editor{
    outline: none !important;
    border: none !important;
    width: 100%;
    height: 100%;
	-webkit-user-modify:read-write !important;
	-webkit-user-select: text;
    user-select: text;
}
.ql-editor *{
	-webkit-user-select: text;
    user-select: text;
	-webkit-user-modify:read-write !important
}

.v-button .ql-editor{
    display: flex;
    align-items: center;
    justify-content: center;
}

.ql-clipboard{
    display: none;
}
.v-site p, .v-site ol, .v-site ul{
    margin: 0 ;
}
.ql-indent-1{
    padding-left: 1em;
}
.ql-indent-2{
    padding-left: 2em;
}
.ql-indent-3{
    padding-left: 3em;
}
.ql-indent-4{
    padding-left: 4em;
}
.ql-indent-5{
    padding-left: 5em;
}
.ql-indent-6{
    padding-left: 6em;
}
.ql-indent-7{
    padding-left: 7em;
}
.ql-indent-8{
    padding-left: 8em;
}
.ql-indent-9{
    padding-left: 9em;
}
.ql-indent-10{
    padding-left: 10em;
}
.ql-indent-11{
    padding-left: 11em;
}
.ql-indent-12{
    padding-left: 12em;
}
.gap-small{
    gap: 4px;
}
.gap-med{
    gap: 8px 
}
.gap-large{
    gap:16px;
}
.w-full{
    width: 100%;
}
.w-half{
    width: 50%;
}
.max-100{
    max-width: 100%;
    max-height: 100%;
}
.form-width-100{
    width: 100%;
}
.fixed-height .clamp-height{
    max-height: 100%;
}

.max-h-100{
    max-height: 100%;
}

.max-w-100{
    max-width: 100%;
}
.min-w-350{
    min-width: 350px !important ;
}
.vwb-absolute.max-100{
    max-width: initial;
    max-height: initial;
}
.h-full{
    height: 100%;
}
.dir-row-reverse{
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}
.dir-row{
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}
.dir-column-reverse{
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
}
.dir-column{
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.no-shrink{
    -ms-flex-negative: 0;
    flex-shrink: 0;
}
/* Tokens v */
.w-nowrap{
    white-space: nowrap;
}
.inherit-color{
    color:inherit;
}
.scrollable{
    overflow:auto;
}
.center-all{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
}
.flex-row{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.membership-flex-row{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
}
.flex-column{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-line-pack: start;
    align-content: flex-start;
}
.flex-wrap{
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.justify-center{
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.justify-start{
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
.justify-end{
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.justify-between{
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.justify-evenly{
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
}
.justify-around{
    -ms-flex-pack: distribute;
    justify-content: space-around;
}
.align-center{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.align-start{
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}
.align-end{
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}
.align-between{
    -webkit-box-align: space-between;
    -ms-flex-align: space-between;
    align-items: space-between;
}
.align-evenly{
    -webkit-box-align: space-evenly;
    -ms-flex-align: space-evenly;
    align-items: space-evenly;
}
.align-around{
    -webkit-box-align: space-around;
    -ms-flex-align: space-around;
    align-items: space-around;
}
.rsis-container>div{
    background-position: center;
}
/*override imported slider with center background positioning and dynamic image width and heights*/
.v-image-slider{
    position: relative;
    width: 500px;
    height: 300px;
    max-height: 100%;
    max-width: 100%;
}
.text-align-left {
    text-align: left ;
}
.text-align-right {
    text-align: right;
}
.text-align-center {
    text-align: center ;
}
.v-image-slider>div:first-child{
    width:100% !important;
    height:100% !important;
    background: none !important;
}
.v-image-slider>div:first-child>div:first-child{
    width:100% !important;
    height:100% !important;
}
.v-image-slider button[data-id]{
    -webkit-box-shadow:0 0 12px -2px #00000066 !important;
    box-shadow:0 0 12px -2px #00000066 !important;
}

.v-form{
    max-width: 1071px;
    max-height: 882px;
}

.v-service-list{
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow-y: auto;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap:12px;
}
.v-service-list .v-service-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    gap: 16px;
}
.v-service-list .accordian{
    background: #F8F8F8;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-left: 8px;
    border: 1px solid #ffffff66;
    border-radius: 3px;
    padding: 8px 8px;
}
.v-service-list .v-service-item{
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: calc(50% - 8px);
    position: relative;
    text-align: left;
    padding: 12px 16px;
    background: #F8F8F8;
    border: 1px solid #ffffff66;
    border-radius: 3px;
    gap:8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.v-service-item h4, .v-service-list .accordian h3{
    margin:0px;
}
.v-service-item h4{
    font-size: 1.25em;
}

.flex-wrapper{
    flex-shrink: 0;
}
.v-service-item p{
    min-height: 50px;
}
.v-service-item .button-row{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 100%;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    gap: 16px;
    height: 100%;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}
.v-service-item .pricing{
    position: absolute;
    top:12px;
    right:16px;
}
.overlay{
    position: relative;
}
.overlay::before{
    content: "";
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: .5;
}
.overlay.dark::before{
    opacity: .25;
}
.overlay.darker::before{
    opacity: .5;
}
.overlay.light::before{
    background: white;
    opacity: .5;
}
.overlay.lighter::before{
    opacity: .25;
    background: white;
}
.vanimation-hidden{
    opacity: 0;
}
.vanimation-fade-in-up{
    -webkit-animation: fade-in-up var(--default-animation-duration) ease-in-out forwards;
    animation: fade-in-up var(--default-animation-duration) ease-in-out forwards;
    -webkit-animation-delay: var(--default-animation-delay);
    animation-delay: var(--default-animation-delay);
}
.vanimation-fade-in-down{
    -webkit-animation: fade-in-down var(--default-animation-duration) ease-in-out forwards;
    animation: fade-in-down var(--default-animation-duration) ease-in-out forwards;
    -webkit-animation-delay: var(--default-animation-delay);
    animation-delay: var(--default-animation-delay);
}
.vanimation-fade-in-left{
    -webkit-animation: fade-in-left var(--default-animation-duration) ease-in-out forwards;
    animation: fade-in-left var(--default-animation-duration) ease-in-out forwards;
    -webkit-animation-delay: var(--default-animation-delay);
    animation-delay: var(--default-animation-delay);
}
.vanimation-fade-in-right{
    -webkit-animation: fade-in-right var(--default-animation-duration) ease-in-out forwards;
    animation: fade-in-right var(--default-animation-duration) ease-in-out forwards;
    -webkit-animation-delay: var(--default-animation-delay);
    animation-delay: var(--default-animation-delay);
}
.vanimation-grow{
    -webkit-animation: grow var(--default-animation-duration) ease-in-out forwards;
    animation: grow var(--default-animation-duration) ease-in-out forwards;
    -webkit-animation-delay: var(--default-animation-delay);
    animation-delay: var(--default-animation-delay);
}
.vanimation-fade-in{
    -webkit-animation: fade-in var(--default-animation-duration) ease-in-out forwards;
    animation: fade-in var(--default-animation-duration) ease-in-out forwards;
    -webkit-animation-delay: var(--default-animation-delay);
    animation-delay: var(--default-animation-delay);
}
.screen-desktop .hide-desktop{
    display: none;
}
.screen-tablet .hide-tablet{
    display: none;
}
.screen-mobile .hide-mobile{
    display: none;
}
.screen-mobile .show-desktop,.screen-tablet .show-desktop{
    display: none;
}
.screen-desktop .show-tablet,.screen-mobile .show-tablet{
    display: none;
}
.screen-desktop .show-mobile,.screen-tablet .show-mobile{
    display: none;
}
input.no-arrow-num::-webkit-outer-spin-button, input.no-arrow-num::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@media (min-width: 767px){
    #divcontent .show-mobile, #wbfooter .show-mobile{
    display: none;
 }
}

@media (max-width: 767px){
    #divcontent .show-tablet, #wbfooter .show-tablet{
       display: none;
    }

    #divcontent .hide-mobile, #wbfooter .hide-mobile{
        display: none;
    }

   }

@media (min-width: 1200px){
    #divcontent .show-tablet, #wbfooter .show-tablet{
       display: none;
    }

    #divcontent .hide-desktop, #wbfooter .hide-desktop{
        display:none;
      }
 }

 @media (max-width:1200px) {
    #divcontent .show-desktop, #wbfooter .show-desktop{
        display: none;
    }

 }

 
.video-cover iframe{
    object-fit: cover;
}

.video-cover video{
    object-fit: cover;
}



a.no-a{
    color:inherit;
    text-decoration: none;
}
/* Firefox */
input.no-arrow-num[type=number] {
    -moz-appearance: textfield;
}
.no-select{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.account-controls{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 100;
}
.v-site-main-content-container{
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
@-webkit-keyframes grow{
    0%{
        opacity: .5;
        -webkit-transform: scale(0);
        transform: scale(0);
   }
    100%{
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
   }
}

@keyframes max-height-infinity {
    0% {
        max-height: 0px;
   }
    99% {
        max-height: 1200px;
   }
    100% {
        max-height: 50000px;
   }
}

@keyframes max-height-shrink {
    0% {
        max-height: 1200px;
   }
    99% {
        max-height: 0px;
   }

}

@-webkit-keyframes max-height-infinity {
    0% {
        max-height: 0px;
   }
    99% {
        max-height: 1200px;
   }
    100% {
        max-height: 10000px;
   }
}

@-webkit-keyframes max-height-shrink {
    0% {
        max-height: 1200px;
   }
    99% {
        max-height: 0px;
   }

}

@keyframes stencil-anim {
    0% {
        background-position: 100% 50%;
   }
    100% {
        background-position: 0% 50%;
   }
}
@keyframes grow{
    0%{
        opacity: .5;
        -webkit-transform: scale(0);
        transform: scale(0);
   }
    100%{
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
   }
}
@-webkit-keyframes fade-in-up{
    0%{
        opacity: 0;
        -webkit-transform: translateY(150px);
        transform: translateY(150px);
   }
    100%{
        opacity: 1;
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
   }
}
@keyframes fade-in-up{
    0%{
        opacity: 0;
        -webkit-transform: translateY(150px);
        transform: translateY(150px);
   }
    100%{
        opacity: 1;
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
   }
}
@-webkit-keyframes fade-in-down{
    0%{
        opacity: 0;
        -webkit-transform: translateY(-150px);
        transform: translateY(-150px);
   }
    100%{
        opacity: 1;
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
   }
}
@keyframes fade-in-down{
    0%{
        opacity: 0;
        -webkit-transform: translateY(-150px);
        transform: translateY(-150px);
   }
    100%{
        opacity: 1;
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
   }
}
@keyframes fade-in-up{
    0%{
        opacity: 0;
        -webkit-transform: translateY(150px);
        transform: translateY(150px);
   }
    100%{
        opacity: 1;
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
   }
}
@-webkit-keyframes fade-in{
    0%{
        opacity: 0;
   }
    100%{
        opacity: 1;
   }
}
@keyframes fade-in{
    0%{
        opacity: 0;
   }
    100%{
        opacity: 1;
   }
}
@-webkit-keyframes fade-in-left{
    0%{
        opacity: 0;
        -webkit-transform: translateX(150px);
        transform: translateX(150px);
   }
    100%{
        opacity: 1;
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
   }
}
@keyframes fade-in-left{
    0%{
        opacity: 0;
        -webkit-transform: translateX(150px);
        transform: translateX(150px);
   }
    100%{
        opacity: 1;
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
   }
}
@-webkit-keyframes fade-in-right{
    0%{
        opacity: 0;
        -webkit-transform: translateX(-150px);
        transform: translateX(-150px);
   }
    100%{
        opacity: 1;
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
   }
}
@keyframes fade-in-right{
    0%{
        opacity: 0;
        -webkit-transform: translateX(-150px);
        transform: translateX(-150px);
   }
    100%{
        opacity: 1;
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
   }
}
/* BUSINESS DATA COMPONENT STYLING */
/*services*/
.services-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap:4px;
}
.service-box{
   /* position:relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: calc(50% - 8px);
    min-width: 300px;
    */
    width: 100% ;
  
    overflow: hidden;
}
/* .service-box.two{
    width: calc(33.333% - 11px);
}
.tablet-break .service-box.two{
    width: calc(50% - 8px);
}
.mobile-break .service-box{
    width:100% !important;
}
.tablet-break .service-box.four{
    width:100% !important;
}
*/
.service-image{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform .4s;
    transition: -webkit-transform .4s;
    -o-transition: transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
}
.service-box:hover .service-image{
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}
.service-price-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap:4px;
}
.three .service-price-container{
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}
.service-content{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 150px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
}
.service-header{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 12px;
}
.service-header h4{
    margin:0!important;
}
.data-accordion h2{
    margin:0;
}
.promo-container{
    padding:4px 8px;
    color:white;
    background:#3e8438;
    border-radius:4px;
    display:-webkit-inline-box;
    display:-ms-inline-flexbox;
    display:inline-flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    gap:4px;
    -ms-flex-negative:0;
    flex-shrink:0;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
}
html body #divcontent .promo-container.s-other{
   color: #fff!important;
}
.service-panel{
    border:1px solid rgba(0, 0, 0, 0.05);
	
}
.accordian-label{
    border-radius: 4px;
    margin: 0px 8px;
    padding: 12px 8px;
    cursor: pointer;
}
.service-footer{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    gap: 12px;
}
.loading-spinner{
    width:2em;
    height:2em;
    border: .4em solid var(--theme-color-3);
    border-top: .4em solid #33333388;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    border-radius: 100%;
   
}
.mobile-navigation>a{
    width: 100%;
}

.mobile-navigation .dropdown-menu {
    width: 100%;
}

@-webkit-keyframes spin{
    0%{
        -webkit-transform: rotateZ(0deg);
        transform: rotateZ(0deg);
   }
    100%{
        -webkit-transform: rotateZ(360deg);
        transform: rotateZ(360deg);
   }
}
@keyframes spin{
    0%{
        -webkit-transform: rotateZ(0deg);
        transform: rotateZ(0deg);
   }
    100%{
        -webkit-transform: rotateZ(360deg);
        transform: rotateZ(360deg);
   }
}
.service-info-row{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.three .service-pricing {
    text-align: right;
    max-width:200px;
}
.four .service-pricing{
    text-align: left !important;
}
/* .four .s-vpl{
    text-align: left !important;
}
*/
.one .service-pricing, .four .service-pricing {
    text-align: right;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}
.one .service-pricing, .three .service-pricing{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap:4px;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}
.two .service-pricing {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}
.font-small{
    font-size:12px;
}
.font-med{
    font-size: 16px;
}
.font-large{
    font-size: 20px;
}
.font-bold{
    font-weight: 600;
}
.font-regular{
    font-weight: 400;
}
.example {
    display: -ms-grid;
    display: grid;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: -o-linear-gradient(top, white, black);
    background: -webkit-gradient(linear, left top, left bottom, from(white), to(black));
    background: linear-gradient(to bottom, white, black);
}
.mr15{
   margin-right: 15px;
}
.vgui-start-rating-container {
    padding: 15px 0;
    display: flex;
}
.vgui-start-rating-container .vgui-start-rating-row {
    margin: 0 -15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.vgui-start-rating-container .vgui-start-rating-row .star-rating-col {
    padding: 0 15px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
}
.vgui-start-rating-container .vgstars .star {
    font-size: 16px;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    line-height: 1;
    color: #e7e7e7;
    padding: 0 1.65px;
}
.vgui-start-rating-container .vgstars .star.yellow {
    color: #f6d645;
}
.rating-row-stars .vgstars{
   position: relative;
}
.rating-row-stars .vgstars .reatedstar-colored{
   position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    max-width: 100%;
}
.vgui-start-rating-container .overall-rating {
    text-align: center;
}
.vgui-start-rating-container .overall-rating .big-font {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 36px;
    margin-bottom: 5px;
    text-align: center;
}
.vgui-start-rating-container .number-of-reviews {
    /* color: #2479c7;  https://dev.azure.com/Vagaro/TeamDevelopment/_workitems/edit/264854*/
    margin-top: 5px;
}
.vgui-start-rating-container .review-bars .review-bar-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 20px;
}
.vgui-start-rating-container .review-bars .review-bar-row .review-bar-num {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 8px;
    margin-right: 8px;
    max-width: 8px;
}
.vgui-start-rating-container .review-bars .review-bar-row .review-bar {
    background: var(--theme-color-5);
    height: 10px;
    border-radius: 10px;
}
.vgui-start-rating-container .ratings-rows {
    display: inline-block;
    margin: auto;
}
.vgui-start-rating-container .ratings-rows .ratings-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 3px 0 5px 0;
    line-height: 20px;
}
.vgui-start-rating-container .ratings-rows .ratings-row .rating-text {
    text-align: right;
    width: 100px;
    margin-right: 35px;
}
.vgui-start-rating-container .bookmark-btnsrows {
    padding: 7px 0;
}
.vgui-start-rating-container .bookmark-btnsrows .bookmarkbnt + .bookmarkbnt {
    padding-top: 10px;
}
.product-search-filter-row{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 0 8px 0 8px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}
.product-search-filter-row>.product-search-filter-row{
    padding:0px;
}
.product-search-filter-row>.product-search-filter-row>div{
    padding:4px;
}
.product-search-filter-row>.product-search-filter-row .third{
    width: 33.3333% ;
}
@container (max-width: 650px) {
    .product-search-filter-row{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
   }
    .vgui-start-rating-container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
   }
    .bookmark-btnsrows{
        border-top:1px solid #00000044;
        padding-top: 12px !important;
        margin-top:8px !important;
        margin-left:4px;
        margin-right:4px;
   }
    .star-rating-col.bars-row{
        display: none;
   }
   .vgui-start-rating-row{
        -webkit-box-orient:vertical;
        -webkit-box-direction:normal;
        -ms-flex-direction:column;
        flex-direction:column;
        gap:8px;
   }
   
    .product-search-filter-row>div{
        width:100% !important;
   }
    .product-search-filter-row .v-button{
        width: 100% !important;
   }
    .product-search-filter-row>.product-search-filter-row>div{
        padding:4px;
        width: 100% !important;
   }
}
.vgui-start-rating-row{
    flex-grow: 1;
}
@container (max-width: 700px){
    .star-rating-col.bars-row{
        display: none;
   }
    .vgui-start-rating-row{
        justify-content: space-around;
   }
    .star-rating-col{
        flex-grow:0 !important;
   }
}
@media (max-width: 700px){
    .star-rating-col.bars-row{
        display: none;
   }
    .vgui-start-rating-row{
        justify-content: space-around;
   }
    .star-rating-col{
        flex-grow:0 !important;
   }


   
}

@container (max-width: 380px){
    .vgui-start-rating-row{
        -webkit-box-orient:vertical;
        -webkit-box-direction:normal;
        -ms-flex-direction:column;
        flex-direction:column;
        gap:8px;
   }
}

@media (max-width: 380px){
    .vgui-start-rating-row{
        -webkit-box-orient:vertical;
        -webkit-box-direction:normal;
        -ms-flex-direction:column;
        flex-direction:column;
        gap:8px;
   }
}
@media (max-width: 650px) {
    .product-search-filter-row{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
   }
   .vgui-start-rating-row{
        -webkit-box-orient:vertical;
        -webkit-box-direction:normal;
        -ms-flex-direction:column;
        flex-direction:column;
        gap:8px;
   }
   
    .star-rating-col.bars-row{
        display: none;
   }
    .product-search-filter-row>div{
        width:100% !important;
   }
    .product-search-filter-row .v-button{
        width: 100% !important;
   }
    .product-search-filter-row>.product-search-filter-row>div{
        padding:4px;
        width: 100% !important;
   }
}
.inherit-styling{
    color:inherit;
    -webkit-box-flex: inherit;
    -ms-flex: inherit;
    flex: inherit;
    display: inherit;
    gap:inherit;
    -webkit-box-pack: inherit;
    -ms-flex-pack: inherit;
    justify-content: inherit;
    -webkit-box-align: inherit;
    -ms-flex-align: inherit;
    align-items: inherit;
    -ms-flex-line-pack: inherit;
    align-content: inherit;
    -webkit-box-orient: inherit;
    -webkit-box-direction: inherit;
    -ms-flex-direction: inherit;
    flex-direction: inherit;
    -ms-flex-wrap: inherit;
    flex-wrap: inherit;
    ;
}
.min-h-200{
    min-height: 200px;
}
.min-h-300{
    min-height: 300px;
}
.min-h-400{
    min-height: 400px;
}
.vb-control{
    position: relative;
}
.quarter-third-half-full{
    width:25% 
}
.pagesetting-bnts button{
   margin-right: 8px;
}
.cart-comp{
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 5px;
   color: inherit;
    padding-right: 8px;
    position: relative
}
.cart-comp .cart-comp-num{
   font-size: 14px;
    font-weight: 800;
    position: absolute;
    padding: 0 3px;
    font-family: system-ui;
    min-width: 20px;
    line-height: 20px;
    height: 20px;
    display: flex;
    color:#333;
    bottom: 17px;
    border-radius: 100px;
    right: -2px;
    background: white;
    align-items: center;
    outline: none !important;
    border:1px solid #44444466;
    justify-content: center;
 
}
.cart-comp .cart-comp-num:empty, .cart-comp-num.hidden{
    display: none;
}
.booknowdrp .dropdown-menu{
   width: 100%;
    min-width: 1px;
}
.booknowdrp .bicon{
   font-size: 18px;
    margin-right: 6px;
    vertical-align: middle;
}
.booknowdrp .bicon2{
   font-size: 18px;
    margin-left: 6px;
   vertical-align: middle;
}
@media (max-width: 1400px) {
    .quarter-third-half-full{
        width:33.3332%;
   }
}
@media (max-width: 940px) {
    .quarter-third-half-full{
        width:50%;
   }
}
@media (max-width: 600px) {
    .quarter-third-half-full{
        width:100%;
   }
}
@container (max-width: 1400px) {
    .quarter-third-half-full{
        width:33.3332%;
   }
}
@container (max-width: 940px) {
    .quarter-third-half-full{
        width:50%;
   }
}
@container (max-width: 600px) {
    .quarter-third-half-full{
        width:100%;
   }
}
.main-navigation .no-a,.main-navigation .no-link{
   
    color:inherit;
    cursor:pointer;
}
.main-navigation{
    background-color: inherit;
    row-gap: 4px !important;
}
/* .navigation-item:not(a){
    margin-top: 2px;
    margin-bottom: 2px;
}
*/
.no-margin{
    margin: 0 !important;
}
.undo-redo:disabled{
    opacity: .5;
    cursor: default !important;
}
.navigation-item{
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s;
}
/* CSS Document */
.main-navigation li{
    color: inherit;
    list-style: none;
    position: relative;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;

    background: inherit;
    white-space: nowrap;
}
.main-navigation li:hover{
    color: inherit;
    list-style: none;
    position: relative;
}
.flex-row-reverse {
    -ms-flex-direction: row-reverse !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    flex-direction: row-reverse !important;
}
.navigation-item{
    padding: 0em .25em;
    line-height: 2em;
    display: block;
    color: inherit;
    font-weight: inherit;
    cursor: pointer;
}
.mobile-navigation-menu .navigation-item{
    padding: .5em 1em;
}
.main-navigation li.set-width a, .main-navigation li.set-width span{
    width:"9em";
}

.mobile-submenu{
    overflow: hidden;
    animation: mobile-expand .3s ease-in-out forwards;
}
.main-navigation li ul{
    position: absolute;
    display: none;
    list-style: none;
    padding: 0px;
    top:100%;
    background-color: inherit;
    left: 0px;
    min-width: 100%;
}
/* .main-navigation li ul::before{
    content: "";
    position: absolute;
    z-index: -1;
    left:0px;
    width:100%;
    height:100%;
    top: 0px;
    background: var(--default-bg-color);
} */
.main-navigation>li{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.main-navigation>li a, .main-navigation>li span{
    margin-top: 2px;
    padding-left: 8px;
    padding-right: 8px;
    border-bottom: 2px solid transparent;
}
.main-navigation>li span:hover{
    border-color: transparent !important;
}
.main-navigation li li a {
    border-width: 0px !important;
    padding-top: .3em;
    padding-bottom: .3em;
}
.main-navigation li:hover ul{
    display: flex;
    flex-direction: column;
}
.mobile-navigation-content{
    position: absolute;
    width: 100%;
    left:0px;
    top:100%;
}


.mobile-navigation-scroll-content{
    min-height: 100%;
    min-width: 100%;
    max-width: 100%;
    position: relative;
}
/* .mobile-navigation-scroll-content::before{
    content: "";
    z-index: -1;
    position: absolute;
    left: 0;
    top:0;
    width:100%;
    height: 100%;
    background: var(--default-bg-color);
} */
.CustomToast {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 12px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 4px;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    border-radius: 5px;
    background: var(--editor-primary);
    z-index: 122;
    color: white;
    position: fixed;
    top:16px;
    left:50%;
    right:auto;
    transform: translateX(-50%);
    pointer-events: none;
    -webkit-animation: toast-anim 3s ease-in-out forwards;
    animation: toast-anim 3s ease-in-out forwards;
}
.top-toast{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 12px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 4px;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    border-radius: 5px;
    background: var(--editor-primary);
    z-index: 122;
    color: white;
    position: absolute;
    top:16px;
    left:50%;
    right:auto;
    width: calc(100% - 32px);
    transform: translateX(-50%);
    pointer-events: none;
    -webkit-animation: toast-anim 3s ease-in-out forwards;
    animation: toast-anim 3s ease-in-out forwards;
}
.top-toast.fixed{
    position: fixed;
}
.top-toast.hanging{
 
 
    gap:8px;
    pointer-events: all;
    -webkit-animation: toast-anim-hanging .5s ease-in-out forwards;
    animation: toast-anim-hanging .5s ease-in-out forwards;
}
.top-toast .toast-btn{
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.top-toast .toast-btn:hover{
    background: #ffffff66;
}
@-webkit-keyframes toast-anim-hanging{
    0% {
        -webkit-transform: translatey(-50px) translateX(-50%);
        transform: translatey(-50px) translateX(-50%);
        opacity: 0;
   }
    100%{
        -webkit-transform: translatey(0px) translateX(-50%);
        transform: translatey(0px) translateX(-50%);
        opacity: 1;
   }
}
@keyframes toast-anim-hanging{
    0% {
        -webkit-transform: translatey(-50px) translateX(-50%);
        transform: translatey(-50px) translateX(-50%);
        opacity: 0;
   }
    100%{
        -webkit-transform: translatey(0px) translateX(-50%);
        transform: translatey(0px) translateX(-50%);
        opacity: 1;
   }
}
@-webkit-keyframes toast-anim{
    0% {
        -webkit-transform: translatey(-50px) translateX(-50%);
        transform: translatey(-50px) translateX(-50%);
        opacity: 0;
   }
    10%{
        -webkit-transform: translatey(0px) translateX(-50%);
        transform: translatey(0px) translateX(-50%);
        opacity: 1;
   }
    90%{
        -webkit-transform: translatey(0px) translateX(-50%);
        transform: translatey(0px) translateX(-50%);
        opacity: 1;
   }
    100% {
        -webkit-transform: translatey(0) translateX(-50%);
        transform: translatey(0) translateX(-50%);
        opacity: 0;
   }
}
@keyframes toast-anim{
    0% {
        -webkit-transform: translatey(-50px) translateX(-50%);
        transform: translatey(-50px) translateX(-50%);
        opacity: 0;
   }
    10%{
        -webkit-transform: translatey(0px) translateX(-50%);
        transform: translatey(0px) translateX(-50%);
        opacity: 1;
   }
    90%{
        -webkit-transform: translatey(0px) translateX(-50%);
        transform: translatey(0px) translateX(-50%);
        opacity: 1;
   }
    100% {
        -webkit-transform: translatey(0) translateX(-50%);
        transform: translatey(0) translateX(-50%);
        opacity: 0;
   }
}

@keyframes mobile-expand{
    0% {
        max-height: 0px;
   }
    99%{
        max-height: 400px;
   }
   100%{
    max-height: auto;
   }
}
.b-iframe{
    min-height: 80vh !important;
}
.mobile-break .section-buffer{
    display: none;
}
.mobile-break .v-section>.inner-section>.v-col{
    min-width: 100% !important;
}
.mobile-break .mobile-hide{
    display: none;
}
.mobile-break .mobile-quarter{
    width: 25% !important;
    max-width: 25% !important;
    min-width: 25% !important;
}
.mobile-break .mobile-third{
    width: 33.333% !important;
    max-width: 33.333% !important;
    min-width: 33.333% !important;
}
.mobile-break .mobile-half{
    width: 50% !important;
    max-width: 50% !important;
    min-width: 50% !important;
}
.mobile-break .mobile-two-third{
    width: 66.666% !important;
    max-width: 66.666% !important;
    min-width: 66.666% !important;
}
.mobile-break .mobile-three-quarters{
    width: 75% !important;
    max-width: 75% !important;
    min-width: 75% !important;
}
.mobile-break .mobile-reverse-row{
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
}
.mobile-break .text-shrink{
    font-size: 0.8em !important;
}
.mobile-break .text-grow{
    -webkit-text-size-adjust: 1.2em !important;
    -moz-text-size-adjust: 1.2em !important;
    -ms-text-size-adjust: 1.2em !important;
    text-size-adjust: 1.2em !important;
}
.mobile-break .mobile-full{
    width:100% !important;
    min-width:100% !important;
    max-width:100% !important;
}
.mobile-break .v-section{
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column ;
    flex-direction: column ;
    min-height: 200px;
    height: auto !important;
}

 .mobile-break .v-page-root>.v-section{
    height: auto !important;
}

.v-site.mobile-break .v-section{
    height: auto !important;
}

.mobile-break .v-section>.v-col{
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
    height: min-content;
}
.mobile-break .v-section.reverse{
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
}
.mobile-break .v-navigation-bar{
    min-height: auto;
}
.mobile-break .v-section>.v-col, .mobile-break .v-section>.v-row{
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
}

.product-details-section{
    padding:100px 0px 100px 0px;
}
.product-details-image-section{
    padding:32px;
}

.v-accordion{
    min-width: 150px;
    margin: 4px 0px;
}
.v-accordion .accordion-label{
    padding: .5em;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
}

.v-accordion .accordion-label.left{
    justify-content: flex-end;
    gap:.5em;
    flex-direction: row-reverse;
}

.v-accordion .second{
    transition: .2s;
}
.v-accordion .accordion-label .accordion-caret{
    width:1em;
    height:1em;
    position: relative;
    transform: rotateZ(0deg);

    transition: transform .3s ease-in-out;
}



.v-accordion.toggled-on .accordion-caret{
    transform: rotateZ(90deg);

}
.v-accordion.toggled-on .accordion-caret .second{
    transform: rotateZ(90deg) !important;

}
.v-accordion .accordion-content{
    max-height: 0px;
    transition: .3s ease-out;
    overflow: hidden;
    opacity: 0;
   
}
.v-accordion.after-toggle .accordion-content{
    animation: max-height-shrink .3s ease-in-out forwards;
    -webkit-animation: max-height-shrink .3s ease-in-out forwards;
}

.accordion-content>*{
    flex-shrink: 0;
}
.v-accordion.toggled-on .accordion-content{
    max-height: 50000px;

    transition-timing-function: ease-in;
    transition-duration: .3s;
    opacity: 1;
}
.v-accordion.after-toggle.toggled-on .accordion-content{

    animation: max-height-infinity .3s ease-in-out forwards;
    -webkit-animation: max-height-infinity .3s ease-in-out forwards;
}



@media (min-width:1200px) {
    .tablet-hide{
        display: none;
   }
}
/*mobile breakage*/
@media (max-width: 767px) {

    .product-details-section{
        padding:12px 0px 100px 0px;
    }
    .product-details-image-section{
        padding:0px;
    }

    .v-page-root>.v-section{
        height: auto !important;
    }

    .v-site.render .v-section{
        height: auto !important;
    }

    .v-section{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column ;
        flex-direction: column ;
        min-height: 200px;
        height: auto !important;
   }
    .v-section>.v-col{
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
        height: min-content;
   }
    .v-section.reverse{
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
   }
    .v-navigation-bar{
        min-height: auto;
   }
    .v-section>.v-col,.v-section>.v-row{
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
   }
    .section-buffer{
        display: none;
   }
    .v-section>.inner-section>.v-col{
        min-width: 100% !important;
   }
    .mobile-hide{
        display: none;
   }
    .mobile-quarter{
        width: 25% !important;
        max-width: 25% !important;
        min-width: 25% !important;
   }
    .mobile-third{
        width: 33.333% !important;
        max-width: 33.333% !important;
        min-width: 33.333% !important;
   }
    .mobile-half{
        width: 50% !important;
        max-width: 50% !important;
        min-width: 50% !important;
   }
    .mobile-two-third{
        width: 66.666% !important;
        max-width: 66.666% !important;
        min-width: 66.666% !important;
   }
    .mobile-three-quarters{
        width: 75% !important;
        max-width: 75% !important;
        min-width: 75% !important;
   }
    .mobile-reverse-row{
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
   }
    .text-shrink{
        font-size: 0.8em !important;
   }
    .text-grow{
        -webkit-text-size-adjust: 1.2em !important;
        -moz-text-size-adjust: 1.2em !important;
        -ms-text-size-adjust: 1.2em !important;
        text-size-adjust: 1.2em !important;
   }
    .mobile-full{
        width:100% !important;
        min-width:100% !important;
        max-width:100% !important;
   }
   .review-icon-rating-staff{
    justify-content: center;
   }
   span.membership-pricing-text{
    width: 100%;
    text-align: right;
    display: block;
   }
}
.review-sorting{
    height: 3em;
    font-size: 16px;
}
.review-sorting .v-button{
    min-height: 40px;
}
.vagaroIframe iframe{
    min-height: 90vh !important;
}
.vgbookmarkicon{
   margin-right: 6px;
    font-size: 18px;
    line-height: 20px;
}
.gctitle-comp{
   padding-left: 20px;
    padding-bottom: 10px;
}


.hidden-text {  visibility: hidden; position: relative; display: flex;}
.call-text { position: absolute;  width: 100%; justify-content: center; display: flex; z-index: 0;
     align-items: center;
    align-content: center;}
.realative-posi { position: relative;}


/*GAP FIXING for OLDER DEVICES*/

.row-gap-small>*:not(:last-child){
    margin-right: 4px;
}
.row-gap-med>*:not(:last-child){
    margin-right: 8px;
}

.row-gap-mid>*:not(:last-child){
    margin-right: 10px;
}
.row-gap-large>*:not(:last-child){
    margin-right: 16px;
}

.col-gap-small>*:not(:last-child){
    margin-bottom: 4px;
}
.col-gap-med>*:not(:last-child){
    margin-bottom: 8px;
}
.col-gap-large>*:not(:last-child){
    margin-bottom: 16px;
}



.vgr-product:before{content:"\E901"}

.vgr-vpl{
    &:before{content:"\E901"}
}
.vgn-popup .vgn-modal-alert.locationpopup .vg-wb-select::before{
    &:before{content: "\f002";}
}

.screen-mobile.mblmenuOpen{overflow: hidden;}


.location-input-wrap:after {
    content: '\f002';
}

input, textarea{
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}
.pagination-control { gap: 0!important;margin-top: 10px;margin-bottom: 10px;}
.pagination-control button { text-decoration: none!important; color: #333!important; border-radius: 50%; width: 28px; height: 28px; line-height:27px; padding: 0; margin-left:3px; margin-right: 3px;}
.pagination-control button:hover, .pagination-control button.active { text-decoration: none!important; color: var(--theme-color-1);
    background-color: var(--theme-color-3);}


/* .fixed-cta-button{
    transition: all .2s ease-in-out;
} */

.cta-container{
    position: fixed;
    left:0px;
    bottom: 0px;
    padding: 6px;
    z-index: 9;
    width: 100%;
    display: none;
}

.cta-container span, .cta-container>a{
    line-height: 2em;

}

.mobile-navigation a.v-login{
    line-height: 2em !important;
}

.hide-cta .cta-container{
    display: none !important;
}


.cta-container button, .cta-container a{
    width: 100%;
    margin: 0 !important;
}

.cta-container .dropdown-menu.show{
    width: 100% !important;
    inset: auto !important;
    left:0 !important;
    top:0 !important;
    transform: translateY(-100%) !important;
}

.proxima{
    font-family: "proxima-nova" !important;
}

@media (max-width: 480px){
    .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box>div>div{
        font-size: 13px !important; 
    }
}

.Warning-top-toast{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 8px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 4px;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    border-radius: 5px;
    background: #ff9f08;
    z-index: 122;
    color: white;
    position: absolute;
    top:8px;
    left:50%;
    right:auto;
    width: auto;
    transform: translateX(-50%);
    pointer-events: none;
    -webkit-animation: toast-anim 3s ease-in-out forwards;
    animation: toast-anim 3s ease-in-out forwards;
}

@media (max-width: 767px){
    .Warning-top-toast{
        padding: 10px;
        width: calc(100% - 10px);
        font-family: inherit;
    }
}

.container-query-container {
    container-type: inline-size;
}
.form-wb-wrapper .new-form-card{
    text-align: center;
    min-height: 700px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}
.form-wb-wrapper .new-form-card svg{
    margin-bottom: 16px;
}
.form-wb-wrapper .new-form-card .heading-text-div.forms-heading{
    text-align: center;
    max-width: 100% !important; 
}
.form-wb-wrapper .new-form-card .heading-text-div.forms-heading h1{
    margin-bottom: 16px;
    font-family: "proxima-nova" !important;
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
}
.form-wb-wrapper .new-form-card .heading-text-div.forms-heading h1.cc-subheading{
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #000;
}
.form-wb-wrapper .new-form-card .heading-text-div.forms-heading .div-block-45.hero .settings-get-started{
display: flex;
justify-content: center;
}
.form-wb-wrapper .new-form-card .heading-text-div.forms-heading .div-block-45.hero .settings-get-started .get-started-btn{
    margin-top: 0;
}
.form-wb-wrapper .new-form-card .heading-text-div.forms-heading .div-block-45.hero{
    justify-content: center;
}

@supports (-webkit-touch-callout: none) {
    
    .v-site.render  .v-page-root *{
        background-attachment: scroll !important;
    }
}

#btn-dropdown-container{
    position: fixed;
    z-index: 4000;
}

/* Showcitymodal css start here */
#ShowCityModal .modal-body {
    max-height: 58vh;
    overflow: auto;
    padding: 16px 20px;
    height: auto;
}

#ShowCityModal .alertContent {
    display: flex;
    flex-wrap: wrap;
}
#ShowCityModal .alertContent span {
    margin-bottom: 16px;
    flex: 0 0 33%;
    padding-left: 5px;
}

.multi-location-details-section{
    margin: 18px auto;
    max-width: 1000px;
    width: 95%;
    display: flex;
    align-items: center;
}
.multi-hlocation-heading-form {
    line-height: 44.46px;
    margin: 0 auto;
    max-width: 1000px;
    width: 95%;
    font-size: 30px;
    font-weight: 400;
}
.multi-hlocation-heading-form svg{
    width: 24px;
    height: 24px;
    margin-right: 8px;
    font-size: 24px;
    margin-top: -5px;
}
.multi-business-details{
    font-size: 20px;
    font-weight: 400;
    margin: 0px 10px;
}
.multi-business-details-padding{
   padding: 8px
}
.businssname {   

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
 
}