.title-pricing-row{

    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 8px;
    align-items: flex-start;
}


.right-to-left-align{
    text-align: right;
    justify-content: flex-end;
}
.title-pricing-row .vp-2{
    color: var(--theme-color-1) !important;
}
@media (max-width: 767px){
    .title-pricing-row{
        display: block;
    }
    .title-pricing-row .vp-2{
        width: 100% !important;
        text-align: right;
        display: block;
    }
}
@media (max-width:500px) {
    .title-pricing-row{
        flex-direction: column;
    }
    .membership-pricing-text {
        width: 100%; 
        text-align: right;
    }
}

@container (max-width:500px) {
    .title-pricing-row{
        flex-direction: column;
    }

    .right-to-left-align{
        text-align:left;
        justify-content: flex-start;
    }
}

.panel-card{
    .right-to-left-align{
        text-align:left;
        justify-content: flex-start;
    }
    .title-pricing-row{
        flex-direction: column;
    }

}
