.select2.select2-container *{cursor: pointer;}
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  z-index: 99999;
  width: 100%;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 40px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-search--inline {
  float: left; width: 100%;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  pointer-events: none;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 10px 12px 10px 12px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 8px; position: relative;
}
.select2-search--dropdown::after{
  color: rgb(154, 154, 154);
    content: "\f002";
    font-family: "Font Awesome 5 Pro";
    font-size: 15px;
    font-weight: 400;
    left: 17px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.select2-search--dropdown .select2-search__field {
  font-size: 15px;
    height: auto;
    line-height: 1;
    padding: 10px 10px 10px 28px; width: 100%; border: 1px solid #cdcdcd;-webkit-appearance: none; border-radius: 3px; background: #fff;
}
.select2-search--dropdown .select2-search__field:hover,
.select2-search--dropdown .select2-search__field:focus{background: #fff; border-color: #2479c7;}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder {
  float: right;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #2479c7; background: #fff;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
}

.select2-container--default .select2-results>.select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f6f6f6;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, #ffffff 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, #ffffff 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, #ffffff 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#eeeeee', GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#cccccc', GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #ffffff 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, #ffffff 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, #ffffff 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#eeeeee', GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #ffffff 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #ffffff 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #ffffff 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#ffffff', GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
}

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results>.select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey;
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

/*custome select drop down css*/
textarea:focus,
input:focus,
select:focus {
  outline: 0;
  box-shadow: none;
}

.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {

  border: solid #cccccc 1px;
}

.select2-container .select2-selection--single {
  height: 34px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 36px; padding-top: 1px;
  font-size: 14px;
  color: #333;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 3px; display: none;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  font-size: 14px;
  font-family: Arial;
  color: #333;
}

.select2-container .select2-search--inline .select2-search__field {
  font-size: 16px;
  color: #333;
  margin-top: 0px; width: 100%; cursor: pointer;
  width: 100%;height: 40px; line-height: 40px; padding: 0; margin: 0; padding-bottom: 2px;
}

.select2-dropdown {
  border: solid #d9d9d9 1px;
}

.select2-container--default .select2-results__group {
  
  padding-bottom: 5px;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background: #f5f5f5;
  color: #333;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: #f5f5f5;
  color: #333;
}

.select2-container--default .select2-results__option {
  font-size: 16px;
  color: #333;
}

.select2-selectAll--dropdown {
  background: #f5f5f5;
  color: #333;
  padding: 8px;
  display: block;
  font-size: 15px;
}

.select2-selectAll--dropdown a {
  color: #333;
  text-decoration: none !important; font-size: 15px; font-weight: 500;
}

.select2-selectAll--dropdown a:hover {
  text-decoration: none;
  color: #2a90d6;
}

.selectnone {
  float: right;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid #ccc 1px;
  outline: 0;
}

.fa {
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.select2-container--default .select2-results__option .select2-results__option i:before {
  font: normal normal normal 18px/1 FontAwesome;
  content: "\f096";
  padding: 7px 7px 7px 0px;
  color: #333;
}

.select2-container--default .select2-results__option[aria-selected=true] i:before {
  font: normal normal normal 18px/1 FontAwesome;
  content: "\f046";
  padding: 7px 7px 7px 0px;
  color: #333;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding-left: 8px;
  font-size: 16px;
  color: #333;
  padding-top: 0px;
  padding-bottom: 0px; line-height: 38px; height: 38px;
}

.select2-container--default .select2-selection--multiple .select2-selection__arrow {
  position: absolute;
  top: 16px;
  right: 1px;
  width: 20px;
  display: none;
} 

.select2-search__field::-webkit-input-placeholder {
  color: #333;
}

.select2-search__field:-moz-placeholder {
  color: #333;
  opacity: 1;
}

.select2-search__field::-moz-placeholder {
  color: #333;
  opacity: 1;
}

.select2-search__field:-ms-input-placeholder {
  color: #333;
}

.select2-results__option {
  position: relative;
}

.select2-container--default .select2-selection--multiple .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.multicheck-checkbox {
  background: url("https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/select-check-unchecknew.png") no-repeat left 3px;
  position: absolute;
  left: 10px;
  top: 2px;
  width: 18px;
  height: 20px;
  display: inline-block;
}

.labelpadd {
  padding-left: 30px;
  cursor: pointer;
  font-weight: normal;
  margin-bottom: 0px;
}

.select2-container--default .select2-results__option[aria-selected=true] .multicheck-checkbox {
  background: url("https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/select-check-unchecknew.png") no-repeat left -47px;

}

/*remove !important by @mahendra*/
.select2-dropdown--below {
  width: 250px;
  margin-top: 1px;
  border: solid #d9d9d9 1px !important;
}

.select2-dropdown--above {
  width: 250px;
  margin-bottom: 1px;
  border: solid #d9d9d9 1px !important;
}

.select2-selection__clear {
  display: none;
}

.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
  height: 38px;
}

::-ms-clear {
  display: none;
}

.select2-container--default .select2-selection--single:after, .select2-container--default .select2-selection--multiple:after {
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "\f0d7";
  font-family: "Font Awesome 5 Pro"; font-weight: 900;
  font-size: 16px;
  color: #333333;
}

/* // custome */
.datepicker-width .select2-dropdown--below, .datepicker-width .select2-dropdown--above{ width: 391px !important; }

.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
  background: white;
  position: relative;
  border-radius: 3px;
  border-color: #cdcdcd;
}
.select2-container--default .select2-selection--single:after,
.select2-container--default .select2-selection--multiple:after {
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "\f0d7";
  font-family: "Font Awesome 5 Pro";
  font-size: 15px; font-weight: 900;
  color: #333333; line-height: 1; padding-top: 1px;
}
.select2-container--default.select2-container--open .select2-selection--single,
.select2-container--default.select2-container--open .select2-selection--multiple{border-color: #2479c7;}
.multicheck-checkbox {
  background: white;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
  width: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.select2-container--default .select2-results__option[aria-selected=true] .multicheck-checkbox {
  background: #333333;
  font-family: "Font Awesome 5 Pro";
  color: white;
  text-align: center; font-weight: 400;;
  line-height: 20px;
  font-size: 14px;
  border: 1px solid #333333;
}
.select2-container--default .select2-results__option[aria-selected=true] .multicheck-checkbox:after {
  content: "\f00c";
}

.select2-link-add a{
  display: block; cursor: pointer; padding: 8px; font-size: 16px; color: #333; font-weight: 400; padding-left: 12px;  
}
.select2-link-add a:hover{
  background-color: #f5f5f5;
}

.select2-container:not(.select2){width: auto;}

.labelpadd.multilocation-group-item {
  padding-left: 0;
  cursor: default;
}