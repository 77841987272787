.Service-dropdown {
  position: relative;
}

/* Style for the input field */
.input-field {
  /* width: 200px; */
  padding: 5px;
}

/* Style for the dropdown content */
.Service-dropdown-content {
  display: none;
  position: absolute;
  min-width: 200px;
  max-height: 200px;
  padding-left: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #ccc;
  z-index: 2;
  width: 100%;
}
.Service-dropdown-content ul{
  padding-left: 0px;
}
.Service-dropdown-content div div{
  padding: 5px 30px 10px 15px;
  font-size: var(--web-subheading-font-size);
  position: relative;
}
.Service-dropdown-content div .dropdown-item{
  padding: 10px 15px 10px 35px;
  white-space: normal;
  font-family: var(--web-body-font-size);
}

/* Style for the dropdown items */
.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

/* Highlight the matched text */
.highlight {
  font-weight: bold;
}
@media (max-width: 767px){
  .Service-dropdown-content ul { display: none; padding: 0; margin: 0; padding-left: 10px;}
  .Service-dropdown-content .active ul { display: block; padding: 0; margin: 0; padding-left: 10px;}
  .Service-dropdown-content div { font-weight: bold;}
  .Service-dropdown-content div div:after, .Service-dropdown-content div div div:after {
    content: '\f107';
    position: absolute;
    right: 10px;
    top: 6px;
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
    font-family: 'FontAwesome';
    font-size: 16px;
}
.Service-dropdown-content div.active  div:after { display: block; -webkit-transform: rotate(270deg);
  -moz-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  -o-transform: rotate(-360deg);
  transform: rotate(-360deg);}
}