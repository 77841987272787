* {
	box-sizing: border-box;
}
.v-photo-gallery{
	.row {
		padding: 0.75vw;
		display: grid !important;
		grid-template-columns: repeat(64, 1fr);
		grid-template-rows: 55vh 30vh;
		grid-row-gap: 0.75vw;
	}
}

.box a {
	display: block;
	width: 100%;
	height: 100%;

	position: absolute;
	top: 0;
	left: 0;

	&:hover img {
		opacity: 0.75;
	}
}

.box__inner {
	background-color: #ccc;
	width: 100%;
	height: 100%;
	position: relative;
	width: 100%;
	height: 100%;
	img {
		width: 100%;
		height: 100%;

		object-position: 50% 50%;
		object-fit: cover;

		transition: opacity 0.2s ease-out;
	}
}

.box--left {
	clip-path: polygon(0 0, 98% 0, 83% 100%, 0 100%);
	grid-row: 1;
	grid-column: 1 / span 35;
	width: 100%;
	height: 100%;
}

.box--right {
	clip-path: polygon(17% 0, 100% 0, 100% 100%, 2% 100%);
	grid-row: 1;
	grid-column: span 35 / -1;
	width: 100%;
	height: 100%;
}

.box--small {
	grid-row: 2;

	&.box--left {
		clip-path: polygon(0 0, 83% 0, 98% 100%, 0 100%);
	}

	&.box--right {
		clip-path: polygon(2% 0, 100% 0, 100% 100%, 17% 100%);
	}
}

@media (min-width: 320px) {
}

/* Mobile */
@container (min-width: 320px) {
	.v-photo-gallery {.row {
		padding: 0.50vw;
		display: grid;
		grid-template-columns: repeat(64, 1fr);
		grid-template-rows: 25vh 25vh;
		
	}
	}
	.box a {
		display: block;
		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;

		&:hover img {
			opacity: 0.75;
		}
	}

	.box__inner {
		background-color: #ccc;
		width: 100%;
		height: 100%;
		position: relative;

		img {
			width: 100%;
			height: 100%;
			object-position: 50% 50%;
			object-fit: cover;
			transition: opacity 0.2s ease-out;
		}
	}

	.box--left {
		clip-path: polygon(0 0, 94% 0, 85% 100%, 0 100%);
		grid-row: 1;
		grid-column: 1 / span 35;
	}

	.box--right {
		clip-path: polygon(15% 0, 100% 0, 100% 100%, 6% 100%);
		grid-row: 1;
		grid-column: span 35 / -1;
	}

	.box--small {
		grid-row: 2;

		&.box--left {
      clip-path: polygon(
        0 0,          /* Top left */
        85% 0,        /* Top right */
        95% 100%,      /* Midway on the right edge, slightly to the right */
        100% 100%,    /* Bottom right */
        0 100%        /* Bottom left */
    );
  }

		&.box--right {
			clip-path: polygon(
        6% 0, /* Top left */
        100% 0, /* Top right */
        95% 100%, /* Midway on the right edge, slightly to the right */
        16% 100% /* Bottom left */
        );
		}
	}
}

/* Tablet */
@media (min-width: 600px) {
}

/* Tablet */
@container (min-width: 600px) {
	* {
		box-sizing: border-box;
	}
	.v-photo-gallery{
		.row {
			padding: 0.75vw;
			display: grid !important;
			grid-template-columns: repeat(64, 1fr);
			grid-template-rows: 55vh 30vh;
			grid-row-gap: 0.75vw;
		}
	}
	.box a {
		display: block;
		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;

		&:hover img {
			opacity: 0.75;
		}
	}

	.box__inner {
		background-color: #ccc;
		width: 100%;
		height: 100%;
		position: relative;

		img {
			width: 100%;
			height: 100%;

			object-position: 50% 50%;
			object-fit: cover;

			transition: opacity 0.2s ease-out;
		}
	}

	.box--left {
		clip-path: polygon(0 0, 98% 0, 83% 100%, 0 100%);
		grid-row: 1;
		grid-column: 1 / span 35;
	}

	.box--right {
		clip-path: polygon(17% 0, 100% 0, 100% 100%, 2% 100%);
		grid-row: 1;
		grid-column: span 35 / -1;
	}

	.box--small {
		grid-row: 2;

		&.box--left {
			clip-path: polygon(0 0, 83% 0, 98% 100%, 0 100%);
		}

		&.box--right {
			clip-path: polygon(2% 0, 100% 0, 100% 100%, 17% 100%);
		}
	}
}

/* Desktop */
@media (min-width: 1024px) {
}
/* Desktop */
@container (min-width: 1024px) {
	* {
		box-sizing: border-box;
	}
	.v-photo-gallery{
		.row {
			padding: 0.75vw;
			display: grid;
			grid-template-columns: repeat(64, 1fr);
			grid-template-rows: 55vh 30vh;
			grid-row-gap: 0.75vw;
		}
	}
	.box a {
		display: block;
		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;

		&:hover img {
			opacity: 0.75;
		}
	}

	.box__inner {
		background-color: #ccc;
		width: 100%;
		height: 100%;
		position: relative;

		img {
			width: 100%;
			height: 100%;

			object-position: 50% 50%;
			object-fit: cover;

			transition: opacity 0.2s ease-out;
		}
	}

	.box--left {
		clip-path: polygon(0 0, 98% 0, 83% 100%, 0 100%);
		grid-row: 1;
		grid-column: 1 / span 35;
	}

	.box--right {
		clip-path: polygon(17% 0, 100% 0, 100% 100%, 2% 100%);
		grid-row: 1;
		grid-column: span 35 / -1;
	}

	.box--small {
		grid-row: 2;

		&.box--left {
			clip-path: polygon(0 0, 83% 0, 98% 100%, 0 100%);
		}

		&.box--right {
			clip-path: polygon(2% 0, 100% 0, 100% 100%, 17% 100%);
		}
	}
}
