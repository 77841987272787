@font-face {
  font-family: 'vagaro-icons';
  src:  url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/vagaro-icons.eot');
  src:  url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/vagaro-icons.eot?#iefix') format('embedded-opentype'),
        url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/vagaro-icons.ttf') format('truetype'),
        url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/vagaro-icons.woff') format('woff'),
        url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/vagaro-icons.svg#vagaro-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'vagaro-icons-new';
  src:  url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/vagaro-icons-new.eot');
  src:  url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/vagaro-icons-new.eot?#iefix') format('embedded-opentype'),
        url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/vagaro-icons-new.ttf') format('truetype'),
        url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/vagaro-icons-new.woff') format('woff'),
        url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/vagaro-icons-new.svg#vagaro-icons-new') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face { 
  font-family: 'Fontawesome pro 300';
  src: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/Awesome5/fontawesome-pro-light-300.eot');
  src: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/Awesome5/fontawesome-pro-light-300.eot?#iefix') format('embedded-opentype'),
      url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/Awesome5/fontawesome-pro-light-300.woff2') format('woff2'),
      url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/Awesome5/fontawesome-pro-light-300.woff') format('woff'),
      url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/Awesome5/fontawesome-pro-light-300.ttf') format('truetype'),
      url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/Awesome5/fontawesome-pro-light-300.svg#fontawesome') format('svg');
  font-weight: 300;
  font-style: normal;
}
@font-face { 
  font-family: 'Fontawesome pro solid 900';
  src: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/Awesome5/fontawesome-pro-solid-900.eot');
  src: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/Awesome5/fontawesome-pro-solid-900.eot?#iefix') format('embedded-opentype'),
       url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/Awesome5/fontawesome-pro-solid-900.woff2') format('woff2'),
       url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/Awesome5/fontawesome-pro-solid-900.woff') format('woff'),
       url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/Awesome5/fontawesome-pro-solid-900.ttf') format('truetype'),
       url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/font/Awesome5/fontawesome-pro-solid-900.svg#fontawesome') format('svg');
  font-weight: 900;
  font-style: normal;
}

.form-component-wrapper {
	bottom: 0px !important;
	background: white !important;
	-webkit-overflow-scrolling: touch;
	.hero-banner {
		position: relative;
		z-index: 1;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		overflow: hidden;
		height: 400px;
		margin-top: 0px;
		padding-top: 90px;
		padding-left: 50px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		background-color: #f0f0f0;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		&.fromrent {
			height: 400px !important;
		}
		&.flex-center {
			margin-bottom: 0px;
			padding-left: 0px;
			-webkit-box-pack: center;
			-webkit-justify-content: space-around;
			-ms-flex-pack: center;
			justify-content: space-around;
      @media (max-width: 767px){
        flex-flow: column;
        align-items: center;
      }
		}
    @media (max-width: 767px){
      padding-top: 30px;
      padding-right: 30px;
      height: auto;
    }
	}
	.heading-text-div {
		&.forms-heading {
			position: relative;
			z-index: 5;
			height: auto;
			max-width: 470px;
			margin-top: 0px;
			padding-right: 52px;
			margin-left: 30px;
			h1 {
				margin-top: 0px;
				margin-bottom: 0px;
				color: #333333;
				font-size: 36px;
				line-height: 40px;
				font-weight: 600;
				&.cc-subheading {
					margin-top: 10px;
					color: #777777;
					font-size: 18px;
					line-height: 26px;
					font-weight: 300;
				}
			}
      @media (max-width: 767px){
        padding-left: 40px;
        padding-right: 40px;
        margin-left: 0;
        margin-top: 10px;
        margin-right: 0px;
        text-align: center;
      }
		}
	}
	.div-block-45 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		&.hero {
			margin-top: 20px;
			-webkit-box-pack: start;
			-webkit-justify-content: flex-start;
			-ms-flex-pack: start;
			justify-content: flex-start;
      @media (max-width: 767px){
        justify-content: center;
      }
		}
	}
	.deactivate-button {
		display: block;
		padding: 10px 0px;
		border-style: solid;
		border-width: 1px;
		border-color: rgba(0, 0, 0, 0.15);
		border-radius: 3px;
		background-color: white;
		color: #333333;
		font-size: 16px;
		letter-spacing: 0.5px;
		cursor: pointer;
		width: 220px;
		text-align: center;
		&:hover {
			border-color: #333333;
		}
	}
	.get-started-btn {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		height: 40px;
		max-width: 300px;
		margin-top: 20px;
		padding-right: 10px;
		padding-left: 10px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-radius: 3px;
		background-color: #7cc576;
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
		color: white;
		font-size: 16px;
		letter-spacing: 0.5px;
		text-decoration: none;
		cursor: pointer;
	}
	.cc-hero-image-div {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 50%;
		max-width: 600px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		&.store {
			width: 60%;
			height: 100%;
			-webkit-box-pack: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			justify-content: center;
			-webkit-box-flex: 0;
			-webkit-flex: 0 0 auto;
			-ms-flex: 0 0 auto;
			flex: 0 0 auto;
		}
	}
	.cc-hero-image {
		position: relative;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		&.store {
			position: relative;
			left: 30px;
			top: -20px;
			width: 900px;
			-webkit-transform: rotate(-25deg);
			-ms-transform: rotate(-25deg);
			transform: rotate(-25deg);
			max-width: 600px;
      @media (max-width: 991px){
        max-width: 100%;
      }
      @media (max-width: 767px){
        top: 21px;
      }
		}
	}
	.forms-play {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
    align-items: center;
		margin-left: 3px;
		color: #2a90d6;
		margin-top: 20px;
		cursor: pointer;
		&:hover {
			color: #226fa3;
		}
    @media (max-width: 767px){
      justify-content: center;
    }
	}
	.text-block-282 {
		margin-right: 10px;
		font-family: 'Fontawesome pro solid 900', sans-serif;
		font-size: 20px;
	}
	.text-block-281 {
		font-size: 16px;
		font-weight: 500;
		text-decoration: none;
	}
	.online-form-body-new {
		display: block;
		padding-top: 0px;
		padding-bottom: 30px;
		padding-left: 30px;
		padding-right: 30px;
	}
	.store-section {
		margin-bottom: 25px;
		padding-top: 20px;
		padding-bottom: 30px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
		text-align: center;
		&.flex {
			-webkit-box-pack: justify;
			-webkit-justify-content: space-between;
			-ms-flex-pack: justify;
			justify-content: space-between;
			-webkit-box-align: center;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			&.limit-flex {
				display: -webkit-box;
				display: -webkit-flex;
				display: -ms-flexbox;
				display: flex;
				max-width: 1400px;
				margin-right: auto;
				margin-left: auto;
        @media (max-width: 767px){
          flex-direction: column;
        }
			}
      &.flip{
        @media (max-width: 767px){
          flex-flow: column-reverse;
        }
      }
    
		}
		&.limit-flex {
			display: block;
			max-width: 1400px;
			margin-right: auto;
			margin-left: auto;
		}
	}
	.flex {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}
	.div-block-42 {
		text-align: center;
	}
	.heading-block {
		&.justify {
			text-align: left;
		}
		h1 {
			margin-top: 0px;
			margin-bottom: 5px;
			font-size: 26px;
			font-weight: 300;
			line-height: 36px;
		}
	}
	.content-block {
		margin-bottom: 20px;
		color: rgba(51, 51, 51, 0.7);
		font-size: 16px;
		line-height: 24px;
		font-weight: 300;
	}
	.div-block-345 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 50%;
		margin-right: 20px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		&.space-left {
			margin-right: 0px;
			margin-left: 20px;
      @media (max-width: 767px){
        margin-left: 0; 
      }
		}
    @media (max-width: 767px){
      width: 100%;
    }
	}
	.image-36 {
		width: 500px;
		max-width: 60%;
		&.shadow {
			max-width: 100%;
			margin-right: 0px;
			border-radius: 5px;
			box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.15);
		}
	}
	.div-block-40 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 29px;
		margin-bottom: 15px;
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		text-align: center;
		&.left {
			-webkit-box-pack: start;
			-webkit-justify-content: flex-start;
			-ms-flex-pack: start;
			justify-content: flex-start;
		}
	}
	.div-25 {
		width: 8%;
		margin-bottom: 15px;
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
    @media (max-width: 767px){
      width: 45%;
    }
	}
	.div-block-41 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: 5px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		&.left {
			margin-bottom: 0px;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-webkit-flex-direction: column;
			-ms-flex-direction: column;
			flex-direction: column;
			-webkit-box-pack: start;
			-webkit-justify-content: flex-start;
			-ms-flex-pack: start;
			justify-content: flex-start;
		}
	}
	.div-block-346 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 36px;
		height: 36px;
		padding: 0px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		border-radius: 3px;
		background-color: #333333;
		font-family: 'Fontawesome pro 300', sans-serif;
		color: white;
		font-size: 18px;
		font-style: normal;
		&.v-icon {
			font-family: 'Vagaro-icons', sans-serif;
			font-size: 18px;
		}
	}
	.simple-header {
		&.small {
			margin-bottom: 0px;
			font-size: 15px;
			line-height: 20px;
			padding-top: 5px;
			color: #333333;
			font-weight: 500;
		}
		&.center {
			color: #333333;
			font-size: 24px;
			text-align: center;
			line-height: 30px;
		}
	}
	.pricing-center-div {
		text-align: center;
		.form-component-wrapper {
			.simple-header {
				color: #333333;
			}
		}
		&.limit-flex {
			display: block;
			max-width: 1400px;
			margin-right: auto;
			margin-left: auto;
		}
	}
	.div-block-347 {
		width: 26px;
		height: 2px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		background-color: white;
	}
}
.rent-deactivate-button {
	&:hover {
		border-color: #333333;
		color: #333333 !important;
	}
}
.settings-get-started {
	.loading-icon {
		display: none;
	}
}
.btn-width80per {
	width: 80%;
}
.margin-top20 {
	margin-top: 20px;
}
.online-form-body-new {
	.padding-bott15 {
		margin-bottom: 15px;
	}
}
.custom-icons-list {
	display: -ms-flexbox;
	display: flex;
	margin-top: 29px;
	margin-bottom: 15px;
	padding-right: 0px;
	padding-left: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	text-align: center;
	&.left {
		justify-content: flex-start;
	}
}
.last-modified-div {
	&.last-modified-control {
		cursor: pointer;
		font-size: 15px;
		line-height: 16px;
		color: #2a90d6;
		left: auto;
		font-weight: 500;
		border-bottom: 1px solid transparent;
		right: 80px;
		position: absolute;
		&:hover {
			border-bottom: 1px solid #2a90d6;
		}
	}
}
.modified-rows {
	&.modified-row {
		position: relative;
	}
}
