.vgn-popup .vgn-modal-alert.locationpopup.multilocationselectpopup {
    max-width: 1000px;

    .modal-body {
        height: calc(100% - 200px);
        overflow-y: auto !important;

        .search-control-location {
            @media (max-width: 767px) {
                width: 100% !important
            }
        }
    }

    .modal-content {
        max-height: calc(100% - 150px);
        min-height: 650px;
        border-radius: 0px!important;
        
        @media (max-width: 767px) {
            max-height: 100%;
        }

        .modal-header {
            @media (max-width: 767px) {
                display: block;
            }

            .btn-close {
                @media (max-width: 767px) {
                    top: 17px;
                }
            }
        }
    }
}

.select-location-title {
    @media (max-width: 767px) {
        h3 {
            color: #333 !important;
            font-size: 30px !important;
            margin-bottom: 16px;
            margin-left: 15px;
        }
    }

    h3 {
        color: #333 !important;
        font-size: 30px !important;
        margin-bottom: 16px;
    }
}

.search-location-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .select-location-input {
        position: relative;

        input {
            width: 300px;
            padding: 10px 28px 10px 28px;
            font-size: 15px;
            border: 1px solid #cdcdcd;
            border-radius: 3px;
        }

        svg {
            position: absolute;
            z-index: 9;
            top: 14px;
            left: 8px;
        }

        .expanded-input-search {
            background: #FFF;
            padding: 12px;
            border: 1px solid #cdcdcd;
            border-radius: 3px;
            position: absolute;
            top: 0;
            left: 0px;
            right: 0;
            transform: translate3d(0px, 44px, 0px);
            will-change: transform;
            cursor: pointer;

            span {
                font-size: 16px;
                color: #2479C7;
                font-weight: 600;
            }

            svg {
                position: relative !important;
                top: -2px !important;
                left: 0 !important;
                margin-right: 8px;
            }
        }

        @media (max-width: 767px) {
            margin-left:16px;
        }
    }

    .search-remove-icon {
        color: #A2A0A2;
        svg {
            position: absolute;
            z-index: 9;
            top: 14px;
            right: 8px;
            left: inherit;
        }
    }

    .select-location-input-icon {
        @media (max-width: 767px) {
            display: none;
        }
    }

    .map-list-icon{
        @media (max-width: 767px) {
            display: none;
        }
    }
}

.book-multilocation-selection {
    margin-bottom: 100px;
}

.select-location-list {
    display: flex;
    gap: 12px;
    flex-flow: row;
    flex-wrap: wrap;

    .location-box {
        border: 1px solid var(--theme-color-1);
        border-radius: 3px;
        padding: 12px 16px;
        width: 49.3%;
        cursor: pointer;

        &:hover {
            border: 2px solid var(--theme-color-1);
            // background-color: rgba(var(--theme-color-1), 0.2);
            background: #13202C33;
        }

        &.active-cardbox {
            border: 2px solid var(--theme-color-1);
            background: #13202C33;
        }

        @media (max-width: 767px) {
            width: 100%;
        }

        .location-header {
            display: flex;
            justify-content: space-between;

            h6 {
                font-weight: 400;
                color: var(--theme-color-1);
            }

        }
    }

    .location-box-norecords {
        //border: 1px solid var(--theme-color-1);
        border-radius: 0px;
        //padding: 12px 16px;
        width: 100%;
        cursor: pointer;
    }

    &.map-view {
        display: block;
        width: 40%;
        height: 650px;
        overflow-y: auto;
        gap: 0px;

        .location-box {
            margin-bottom: 16px;
            width: 98%;
        }

        @media (max-width: 767px) {
            display: flex;
            flex-wrap: nowrap;
            position: absolute;
            bottom: 0;
            left: 0%;
            right: 2%;
            z-index: 9;
            height: auto;
            width: auto;
            overflow: auto;
            height: 152px;

            &::-webkit-scrollbar {
                display: none;
            }

            .location-box {
                width: 100%;
                min-width: 366px;
                max-width: 344px;
                margin-left: 7px;
                margin-bottom: 8px;
            }
        }
    }

    @media (max-width: 767px) {
        margin: 0px 16px;
    }
}

.content-with-map {
    display: flex;
    justify-content: space-between;

    .current-map-section {
        width: 60%;
        border: 1px solid #333;
        min-height: 650px;
        margin-left: 16px;

        @media (max-width: 767px) {
            width: 92%;
            margin: 0 auto;
            height: 644px;
        }
    }

    @media (max-width: 767px) {
        display: block;
    }
}

.mobile-view-btn {
    display: flex;
    justify-content: center;

    .map-view-btn,
    .list-view-btn {
        display: inline-block;
        background-color: #fff;
        color: #333;
        border-radius: 25px;
        box-shadow: 0px 2px 24px 0px #0000001F;
        padding: 15px 24px;
        border: 1px solid #cdcdcd;
        z-index: 11;
        margin-top: 10px;
    }

    .map-view-btn {
        bottom: 12px;
        top: auto;
    }

    .list-view-btn {
        position: relative;
        top: 16px;
        bottom: auto;
        z-index: 0;

        @media (max-width: 767px) {
            position: absolute;
            top: 80px;
        }
    }

    @media (min-width: 767px) {
        display: none;
    }

    @media (max-width: 766px) {
        display: flex;
    }
}


.Multilocation-panel h3 {
    cursor: pointer;
    width: 100%;
    color: #333 !important;
    text-transform: capitalize !important;
}

.Multilocation-label.Multilocation-panel {
    padding: 12px 8px;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.Multilocation-panel .Multilocation-panel-arrow {
    transform: rotate(0deg);
    padding: 10px;
}

.Multilocation-panel .Multilocation-panel-arrow.active {
    transform: rotate(90deg);
    padding: 10px;
}

.accordion-content.Multilocation-content.active {
    overflow: auto;
    max-height: none !important;
    transition: max-height 0.4s ease 0s;
}

.Multilocation-content.Multilocation-panel {
    flex-flow: row;
    justify-content: space-between;
    overflow: auto;
    max-height: 0px;
    transition: max-height 0.4s ease 0s;

    .card-box {
        width: 49%;
        display: flex;
        padding: 20px;
        cursor: pointer;
        border: 2px solid rgba(0, 0, 0, 0.05);
        background: #fff !important;
        border-radius: 3px !important;
        margin: 8px 0px;

        h4 {
            margin-bottom: 20px;
            color: #333 !important;
        }

        .vg-box-decs {
            color: #333;
        }

        &.active-cardbox {
            border: 2px solid green;
            background: #f8f8f8 !important;
        }

        &:hover {
            background: #f8f8f8 !important;
        }

        @media (max-width: 767px) {
            width: 100%;
        }
    }

}

.Multilocation-label.Multilocation-panel {
    background: #fff !important;
    border-radius: 3px !important;
    margin: 8px 0px;
    border: 1px solid rgba(0, 0, 0, 0.05);

    &:hover {
        background: #f8f8f8 !important;
    }

    &.active-label {
        // border: 2px solid green;
        background: #f8f8f8 !important;
    }

}

@media screen and (max-width: 1000px) {
    .vgn-popup .vgn-modal-alert.locationpopup {
        max-width: 100%;
    }
}

.map-list-icon {
    cursor: pointer;
}

.map-salon-img {
    position: relative;
    width: 100%;
    height: 167px;
    overflow: hidden;
}

.map-salon-img a.map-imgcenter {
    display: block;
    width: 98.50%;
    height: 167px;
    vertical-align: middle;
    text-align: center;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #e0e0e0;
    border-radius: 5px 5px 0 0;
}

.map-salon-img a {
    color: white;
    text-decoration: none;
}

.map-addres-col {
    padding: 7px 0px 10px;
}

.web-subheading-font,
.web-subheading-font-green,
.web-subheading-font-blue,
.web-subheading-font-flamingo {
    font-family: var(--web-subheading-font-family) !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    font-size: 16px !important;
    letter-spacing: 0px !important;
    text-decoration: none !important;
    text-transform: none !important;
    color: #333333 !important;
}

.map-tooltip {
    position: relative;
    min-height: 64px;
}