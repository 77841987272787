.profileImage {
	background-size: cover !important;
	background-position: center;
	background-repeat: no-repeat;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-webkit-transition: -webkit-transform .4s;
	transition: -webkit-transform .4s;
	-o-transition: transform .4s;
	transition: transform .4s;
	transition: transform .4s, -webkit-transform .4s;
	width: 33%;
	height: auto;
}

@media (min-width: 320px) {
	.profileImage {
		margin: 0 auto;
		background-size: cover !important;
		background-position: center;
		background-repeat: no-repeat;
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
		-webkit-transition: -webkit-transform .4s;
		transition: -webkit-transform .4s;
		-o-transition: transform .4s;
		transition: transform .4s;
		transition: transform .4s, -webkit-transform .4s;
		width: 320px;
		height: 520px;
		}
}

@container (min-width: 320px) {
	.profileImage {
	background-size: cover !important;
	background-position: center;
	background-repeat: no-repeat;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-webkit-transition: -webkit-transform .4s;
	transition: -webkit-transform .4s;
	-o-transition: transform .4s;
	transition: transform .4s;
	transition: transform .4s, -webkit-transform .4s;
	width: 100%;
	height: 520px;
	}
}

/* Desktop: 3 columns */
@container (min-width: 600px) {
	.profileImage{
		width:  100%;
		height: 500px;
		margin: auto;
		background-size: cover !important;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform .4s;
    transition: -webkit-transform .4s;
    -o-transition: transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
	}
}

/* Desktop: 3 columns */
@container (min-width: 1024px) {
	.profileImage{
		width:  100%;
		height: 500px;
		margin: auto;
		background-size: cover !important;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform .4s;
    transition: -webkit-transform .4s;
    -o-transition: transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
	}
}

.initialPlaceholderWrapper {
  width: 100%;
  height: 500px;
  background-color: #333;
  border-radius: "3% 3% 0 0";
  display: flex;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
}

.staffInitialPlaceholderImage {
  width: 264px;
  height: 352px;
  object-fit: cover;
  width: 100px; /* Adjust the width and height as needed */
  height: 100px; /* These values should be the same for a perfect circle */
  border: 1px solid #cdcdcd;
  color: #333;
  border-radius: "3% 3% 0 0";
  background-color: #fff;
 
  
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center; /* Vertically center content */
}

.descriptionParagraph {
    font-size: 16px; /* Set your base font size */
    line-height: 1.618; /* Use the golden ratio for line height */
  
    /* You can also calculate the font size using the golden ratio */
    /* font-size: calc(16px * 1.61803398875); */
 
}